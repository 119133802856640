import React, { Fragment } from 'react';
import './App.css';
import {MuiThemeProvider} from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import themeFile from './util/theme'
import jwtDecode from 'jwt-decode';
//REDUX
import {Provider} from 'react-redux';
import store from './redux/store';
import {SET_AUTHENTICATED} from './redux/types';
import {logoutUser, getUserData} from './redux/actions/userActions'
//Components
import NavBar2 from './components/layout/Navbar2';
import AuthRoute from './util/AuthRoute';
import MySidebar from './components/layout/MySidebar'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
//Pages
import home from './pages/home';
import login from './pages/login';
import signup from './pages/signup';
import user from './pages/user'
import topic from './pages/topic'
import about from './pages/about'
import axios from 'axios';
import { CssBaseline } from '@material-ui/core';
import ScrollToTop from './pages/ScrollToTop'
import communityGuidelines from './pages/communityGuidelines';
import landingPage from './pages/landingPage';

const theme = createMuiTheme(themeFile)

axios.defaults.baseURL = "https://us-central1-buddymagnet.cloudfunctions.net/api"

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


const token = localStorage.FBIdToken;
let authenticated = false
if(token){
  const decodedToken = jwtDecode(token);
  if(decodedToken.exp * 1000 < Date.now()){
    window.location.href = '/login'
    store.dispatch(logoutUser())
  }
  else{
    store.dispatch({type: SET_AUTHENTICATED})
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData())
    if(authenticated!=true){
      // window.location.reload()
    }
    authenticated = true

  }
}

document.body.style = 'background: #F0F1F3';
function App() {
  const mainApp = (
      <Switch>
        
          <Route key={authenticated} exact path="/feed" component={home}/>
          <Route key={authenticated} exact path="/" component={landingPage }/>
        
        
        {/* <Route exact path='/feed' component={home}/> */}
        <AuthRoute exact path="/login" component={login}/>
        <AuthRoute exact path="/signup" component={signup}/>
        <Redirect from='/tableOfContents' to={{pathname:'/feed', state: {value:2}}}/>
        <Route exact path='/about' component={about}/>
        <Route exact path='/communityGuidelines' component={communityGuidelines}/>
        <Route exact path="/users/:handle" component={user}/>
        <Route exact path="/users/:handle/post/:postId" component={user}/>
        <Route exact path="/topics/:topicName" component={topic}/>
      </Switch>
)
  return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Provider store={store}>
          <Router>
            <ScrollToTop>
            <NavBar2/>
  
            {mainApp}  
            </ScrollToTop>
          
      
          </Router>
        </Provider>        
      </MuiThemeProvider>        
  );
}

export default App;
