import React, { Component, Fragment } from 'react'
import Form from 'react-bootstrap/Form'
import Button from '@material-ui/core/Button'
import {Row, Col, Grid} from '../layout/Flexbox'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'


const styles = theme=>({
    ...theme.spreadThis,
    submitButton:{
        position: 'relative',
        float: 'right',
        margin: 6,
        marginTop: 8
    },
    label:{
        float: 'left',
        margin: 6
    },
    text:{
        position: 'relative',
        float: 'left',
        marginTop: 6,
        width: 'calc(100% - 78px)',
        marginTop: 8,
        maxWidth:'80vw'
    },
    tags:{
        position: 'relative',
        float: 'left',
        marginTop:6
    },
    tagButton:{
        margin: 4,
        marginTop: 6,
        marginBottom: 9,
        //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 30,
        border: 0,
        height: '40px',
        padding: '4 8px 4 8px',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            backgroundColor: "blue !important",
            transform: 'translate(0,-5px)'
          }
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    closeButton:{
        color:'#ffffff9f',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            transform: 'scale(1.4)'
          }
    }
})

export class AddTag extends Component {
    
    state = {
        errors:''
    }

    render() {
        const {classes, errors, errors2, topicList} = this.props;
        const tagList = this.props.tags.map((t)=>
            <Button color="primary" variant="contained" className={classes.tagButton} style={{outline:'none'}} endIcon={<CloseIcon className={classes.closeButton} onClick={()=>this.props.handleRemoveTag(t)}/>}>
                    {t}
            </Button>
        )
        const options = topicList ? (topicList.map((ct)=>
            <option>{ct}</option>
        )):(
            <option>Loading Topics</option>
        )
        return (
            <Fragment>
                <Grid>
                <Row>
                    <Form style={{width:'100%'}}>
                    <Form.Group controlId="formTag" style={{width:'100%'}}>
                            <Col size={2}>
                                <div>

                                <Form.Control name="tag" variant="outlined" as='select' id='tagEntry' type="tag" onChange={this.props.handleChange} 
                                className={classes.text} placeholder="Enter topic">
                                    <option>Enter topics</option>
                                    {options}
                                </Form.Control>

                                <Button className={classes.submitButton} style={{outline:'none'}} variant="outlined" onClick={this.props.handleSubmitTag}>
                                Add
                                </Button>
                                </div>
                                <div>
                                {errors && (<Typography style={{color:'red', marginLeft:'7px'}}><Box fontSize={12}>{errors}</Box></Typography>)}
                                </div>
                            </Col>                    
                        
                     </Form.Group> 
                     <Form.Text className="text-muted" styles={{marginTop: '12px'}}>
                        {this.state.errors}
                    </Form.Text>       
                    </Form>
                </Row>
                <Row>
                {tagList}
                </Row>
            </Grid>
            </Fragment>
            
        )
    }
}

AddTag.propTypes = {
    handleRemoveTag: PropTypes.func.isRequired,
    handleSubmitTag: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    errors2:PropTypes.object
}
export default withStyles(styles)(AddTag)
