import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'
import Post from '../components/post/Post'
import Grid from '@material-ui/core/Grid'
import StaticProfile from '../components/profile/StaticProfile'
import PostSkeleton from '../util/PostSkeleton'
import ProfileSkeleton from '../util/ProfileSkeleton'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import TopicRequest from '../components/layout/TopicRequest'
import {connect} from 'react-redux'
import {getUserData, resetPosts} from '../redux/actions/dataActions'
import {Row, Col} from '../components/layout/Flexbox'
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
    ...theme.spreadThis,
    widePaper:{
        marginTop: '300px',
        marginBottom: '35px',
        borderRadius: 1
    }
});

class user extends Component {
    state = {
        profile: null,
        postIdParam: null
    }
    componentDidMount(){
        const handle = this.props.match.params.handle
        const postId = this.props.match.params.postId

        if(postId) this.setState({postIdParam: postId})

        this.props.resetPosts();
        this.props.getUserData(handle); //gets the posts from the user

        axios.get('/user/'+handle) //this gets the posts, topic requests, etc
            .then(res=>{
                this.setState({
                    profile: res.data.user
                })
            })
            .catch(err => console.log(err))

    }

    render() {

        const { loading, posts, topicRequests} = this.props.data;
        const {classes} = this.props;
        const {postIdParam} = this.state;
        
        const postsMarkup = (
            <Row>
                <Col size={1} collapse="sd">
                {posts.map(post=> {
                    return <Post key={post.postId} post={post} openDialog={postIdParam && post.postId===postIdParam}/>
            })}
                </Col>
                <Col size={1} collapse="dd">
                {posts.map(post=> {
                    return <Post key={post.postId} post={post} openDialog={postIdParam && post.postId===postIdParam} width="narrow"/>
            })}
                </Col>
            </Row>
            
        )

        const topicRequestList = !loading && (
            <Grid container>
{topicRequests.map(topicRequest1 => {
                return <Grid item style={{margin: '6px'}}>
                    <TopicRequest topicRequest={topicRequest1}/>
                    </Grid>
            })}
            </Grid>
            
        )
        
        return (
            <Fragment>
            {this.state.profile==null?(
                    <ProfileSkeleton/>
                ):(
                    <StaticProfile profile={this.state.profile} postMarkups={postsMarkup} topicRequestMarkups={topicRequestList}/>
                )}
            {/* <ProfileSkeleton/> */}

            </Fragment>
            
        )
    }
}

user.propTypes = {
    getUserData: PropTypes.func.isRequired,
    resetPosts:PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) =>({
    data: state.data
})

export default connect(mapStateToProps, {getUserData, resetPosts})(withStyles(styles)(user))
