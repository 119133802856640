import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import axios from 'axios'

import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
//import Image from 'material-ui-image'

const styles=(theme)=>({
    ...theme.spreadThis,
    button:{
        float:'right',
    },
    progressSpinner:{
        position: 'absolute'
    },
    image:{
        width:184,
        height:184,
        margin: '8px 0 0 1px',
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
    },
    bannerImage:{
        height: '159px',
        marginBottom:'30px',
        marginTop:'30px',
        width: '100%'
    },
    borderImage:{
        width:200,
        height:200,
        borderRadius: '50%',
        backgroundColor: "white",
        left:'50%',
        bottom:'0%',
        position:'absolute',
        transform: 'translate(-50%, -4%)'
    },
    textField:{
        width:'100%'
    },
    roundSubmit:{
        float:'right',
        marginTop:'20px',
        marginBottom:'20px'
    }
})

class AddModerator extends Component {
    state={
        expanded: false,
        newAdminHandle: '',
        findProfile:{},
        loading_search:false,
        success_message:'',
        search_errors:{}
    }

    handleOpen = ()=>{
        this.props.closeFunction()
        this.setState({search_errors: {}, findProfile: {}, newAdminHandle:'', loading_search:false})
        this.setState({expanded: true})
    }
    handleClose = () =>{
        this.setState({expanded: false})
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        });
    };

    handleSubmitNewMod = (event) =>{
        event.preventDefault();
        let toSend = {topicName: this.props.topicName, newAdminHandle: this.state.newAdminHandle} //these are required

        this.setState({loading_search: true})
        axios.post('/addMods', toSend) //this gets the posts, topic requests, etc
            .then(res=>{
                this.setState({loading_search:false, success_message: 'Successfully added'})
            })
            .catch(err => {
                console.log(err)
                console.log(err.response.data)
                this.setState({search_errors: err.response.data})
                this.setState({loading_search:false, success_message: ''})
            })
        
    }

    handleFindUser = (event) =>{
        console.log(this.state.findProfile)
        event.preventDefault();
        this.setState({loading_search: true})
        axios.get('/userInfo/'+this.state.newAdminHandle) //this gets the posts, topic requests, etc
            .then(res=>{
                this.setState({loading_search:false, success_message: ''})
                console.log(res.data)
                this.setState({
                    findProfile: res.data
                })
            })
            .catch(err => {
                console.log(err)
                console.log(err.response.data)
                this.resetProfile()
                this.setState({search_errors: err.response.data})
                
            })
        
    }

    resetProfile = () =>{
        this.setState({loading_search:false})
        this.setState({newAdminHandle:'', findProfile:{}, search_errors:{}, success_message:''})
    }

    handleChange = (event) =>{
        this.setState({[event.target.name]: event.target.value})
    }

    render() {
        const {classes} = this.props;
        const {search_errors, loading_search} = this.state

        return (
            <Fragment>

            <MenuItem onClick={this.handleOpen}>
                <SupervisorAccountIcon style={{marginRight:'10px'}}/>
                <Typography>
                    <Box fontWeight={500} fontSize={14}>
                        Add Moderator
                    </Box>
                </Typography>
            </MenuItem>

            <Dialog open={this.state.expanded}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm">
                <DialogTitle>Add Moderator</DialogTitle>
                <DialogContent>
                    
                    {Object.keys(this.state.findProfile).length === 0 ?(

                        <form onSubmit={this.handleFindUser}>
                        <TextField variant='outlined' name="newAdminHandle" type="text" label="Handle" multiline rows={1} placeholder="Find person" 
                        error={search_errors && search_errors.handle} helperText={search_errors ? (search_errors.handle):null} className={classes.textField} 
                        onChange={this.handleChange} fullWidth defaultValue={this.state.header}/>

                        <Button type="submit" className={classes.roundSubmit} style={{outline:'none'}}>
                            {loading_search ? (
                                <CircularProgress size={30} className={classes.progressSpinner}/>
                            ):(

                                <Typography><Box fontSize={13} fontWeight={600}>Find Person</Box></Typography>    )}
                            
                        </Button>
                        </form>

                    ):(
                        <div style={{textAlign:'center', position:'relative'}}>

                            <span style={{color:'red'}}>{search_errors ? (search_errors.newAdminHandle):null}</span>
                            <span style={{color:'#3046f5'}}>{this.state.success_message}</span>


                            <div style={{textAlign:'center', position:'relative'}}>
                                <img src={this.state.findProfile.bannerUrl} className={classes.bannerImage}/>
                                <div className={classes.borderImage}>
                                    <img src={this.state.findProfile.imageUrl} className={classes.image}/>
                                </div>
                            </div>
                            
                            <Typography><Box fontSize={18} fontWeight={600}> {this.state.findProfile.name} </Box></Typography>
                            <div>
                                <form onSubmit={this.handleSubmitNewMod} style={{marginTop:'20px'}}>
                                    <Button onClick={this.resetProfile} style={{outline:'none'}}>Go Back</Button>
                                    <Button type="submit">
                                        {loading_search ? (
                                            <CircularProgress size={30} className={classes.progressSpinner}/>
                                                ):(

                                            <span>Make Moderator</span>    )}
                                    </Button>
                                </form>
                            </div>

                        </div>
                    )}
                    

                </DialogContent>
            </Dialog>
        </Fragment>
        )
    }
}

AddModerator.propTypes = {
    classes: PropTypes.object.isRequired,
}

// const mapStateToProps = (state)=>({
//     UI: state.UI,
//     errors: state.UI.errors
// })

export default connect(null, null)(withStyles(styles)(AddModerator))