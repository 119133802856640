import React, {Fragment} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeletePost from './DeletePost'
import Untag from './Untag'
import { Delete } from '@material-ui/icons';
import CreatePost from './CreatePost'


const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{float: 'right', marginTop: '-6px', outline:'none'}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {(props.isUser || props.isCreator) && (<DeletePost postId={props.postId} close={props.close}/>)}
        {props.isUser && (<CreatePost edit={true} post={props.post} close={props.close}/>)}
        {props.isAdmin && (
        <Untag postId={props.postId} topic={props.topic} close={props.close}/>
        )}
          
      </Menu>
    </Fragment>
  );
}