import {Button} from 'semantic-ui-react'
import React, { Component} from 'react'
import '../../App.css'

export default class WrapperButton extends Component{
    render(){
        return (
            <Button inverted color='blue' onClick={this.props.onClick}>Create Post</Button>
        )
    }
}