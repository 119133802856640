import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { Nav, NavItem} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import {Icon} from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import "./MyFooter.css"

class MyFooter extends Component {
    render(){
        return (
            <nav className="navbar fixed-bottom navbar-light bottom-tab-nav d-block d-lg-none" role="navigation" color="primary">
                <Nav className="w-100" folor="primary">
                <div className=" d-flex flex-row justify-content-around w-100">
                    <NavItem>
                    <NavLink to='/' className="nav-link bottom-nav-link" activeClassName="active">
                        <div className="row d-flex flex-column justify-content-center align-items-center">
                        <Icon name="home" size="big"/>
                        <div className="bottom-tab-label">Home</div>
                        </div>
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink to='/' className="nav-link bottom-nav-link" activeClassName="active">
                        <div className="row d-flex flex-column justify-content-center align-items-center">
                        <Icon name="fire" size="big"/>
                        <div className="bottom-tab-label">Popular</div>
                        </div>
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink to='/' className="nav-link bottom-nav-link" activeClassName="active">
                        <div className="row d-flex flex-column justify-content-center align-items-center">
                        <Icon name="shuffle" size="big"/>
                        <div>Random</div>
                        </div>
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink to='/' className="nav-link bottom-nav-link" activeClassName="active">
                        <div className="row d-flex flex-column justify-content-center align-items-center">
                        <Icon name="question circle" size="big"/>
                        <div>My Topics</div>
                        </div>
                    </NavLink>
                    </NavItem>
                        
                </div>
                </Nav>
            </nav>
          );
    }
  
}

export default MyFooter;