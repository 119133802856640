import React, { Component, Fragment, useState } from 'react'

import Chart from "chart.js";
import Paper from '@material-ui/core/Paper'
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
  } from "./charts.js";
import { CardContent, makeStyles } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
    paper:{
        padding: '30px',
        borderRadius:'19px',
        background:'linear-gradient(30deg, #172a4d 16%, #040d21 90%)',
        overflow:'hidden',
        position:'relative',
        boxShadow: '-1px 2px 20px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }
}))

const parseData = (data) =>{
    let dates = []
    let values = []
    let prevMonth = ''

    for(var i=0;i<data.length;i++)
    {
        let temp = data[i].split('_')
        let formatted = dayjs(temp[0]).format('MMM')

        if(formatted!==prevMonth){
            prevMonth = formatted
            temp[0]=formatted
        }
        else{
            temp[0] = ''
        }
        dates.push(temp[0])
        values.push(temp[1])
    }
    return [dates, values]
}

export default (props) => {
    const classes = useStyles();
    const [activeNav, setActiveNav] = useState(1);
    const [chartExample1Data, setChartExample1Data] = useState("data1");

    let retData = parseData(props.dataPoints)

    let data = {
        labels: retData[0],
      datasets: [
        {
          label: "Performance",
          data: retData[1],
        },
      ],
    }

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const toggleNavs = (e, index) => {
        e.preventDefault();
        setActiveNav(index);
        setChartExample1Data("data" + index);
      };
    
      return (

            <Paper className={classes.paper}>
                <Typography><Box fontSize={12} color='#9c9c9ca6'>POPULARITY</Box></Typography>
                    <Typography style={{marginTop:'8px', marginBottom:'25px'}}><Box fontSize={21} color='white'>Number of Questions</Box></Typography>
                <div className="chart" style={{height:'360px'}}>     
                    <Line
                    data={data}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                    />
                </div>
             </Paper>

          
        
      )

}