import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="500px"
      version="1.1"
      viewBox="0 0 136 129"
    >
      <defs>
        <linearGradient
          id="linearGradient1178"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient id="linearGradient1176">
          <stop offset="0" stopColor="#fff" stopOpacity="0.181"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(51.697 257.184)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient1194-0"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-100.378 10.232)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(125.357 256.83)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient2976"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient2978"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient2980"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient2982"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient2984"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient2986"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient2988"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
        <linearGradient
          id="linearGradient2990"
          x1="249.748"
          x2="252.184"
          y1="72.848"
          y2="0.816"
          gradientTransform="translate(-174.038 10.587)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient1176"
        ></linearGradient>
      </defs>
      <g
        fillOpacity="1"
        stroke="none"
        strokeWidth="0.265"
        fontFamily="sans-serif"
        fontSize="50.8"
        fontStyle="normal"
        fontWeight="normal"
        transform="rotate(10.788)"
      >
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="62.186"
          y="83.126"
          fill="url(#linearGradient1178)"
        >
          <tspan
            x="62.186"
            y="83.126"
            style={{}}
            fill="url(#linearGradient1178)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="31.57"
          y="68.902"
          fill="url(#linearGradient2990)"
        >
          <tspan
            x="31.57"
            y="68.902"
            style={{}}
            fill="url(#linearGradient2990)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="50.667"
          y="41.76"
          fill="url(#linearGradient2988)"
        >
          <tspan
            x="50.667"
            y="41.76"
            style={{}}
            fill="url(#linearGradient2988)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="78.748"
          y="22.855"
          fill="url(#linearGradient2986)"
        >
          <tspan
            x="78.748"
            y="22.855"
            style={{}}
            fill="url(#linearGradient2986)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="87.111"
          y="62.643"
          fill="url(#linearGradient2984)"
        >
          <tspan
            x="87.111"
            y="62.643"
            style={{}}
            fill="url(#linearGradient2984)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="116.874"
          y="72.567"
          fill="url(#linearGradient2982)"
        >
          <tspan
            x="116.874"
            y="72.567"
            style={{}}
            fill="url(#linearGradient2982)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="111.709"
          y="25.407"
          fill="url(#linearGradient2980)"
        >
          <tspan
            x="111.709"
            y="25.407"
            style={{}}
            fill="url(#linearGradient2980)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="16.713"
          y="32.383"
          fill="url(#linearGradient2978)"
        >
          <tspan
            x="16.713"
            y="32.383"
            style={{}}
            fill="url(#linearGradient2978)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="20.695"
          y="106.536"
          fill="url(#linearGradient2976)"
        >
          <tspan
            x="20.695"
            y="106.536"
            style={{}}
            fill="url(#linearGradient2976)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="94.355"
          y="106.181"
          fill="url(#linearGradient1194-0)"
        >
          <tspan
            x="94.355"
            y="106.181"
            style={{}}
            fill="url(#linearGradient1194-0)"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="Franklin Gothic Medium"
            fontSize="50.8"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            ?
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default Icon;
