import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import axios from 'axios'

import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Link} from 'react-router-dom'
import ButtonBase from '@material-ui/core/ButtonBase'
//import Image from 'material-ui-image'

const styles=(theme)=>({
    ...theme.spreadThis,
    button:{
        float:'right',
    },
    progressSpinner:{
        position: 'absolute'
    },
    image:{
        width:184,
        height:184,
        margin: '8px 0 0 1px',
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
    },
    bannerImage:{
        height: '159px',
        marginBottom:'30px',
        marginTop:'30px',
        width: '100%',
        objectFit:'cover'
    },
    borderImage:{
        width:200,
        height:200,
        borderRadius: '50%',
        backgroundColor: "white",
        left:'50%',
        bottom:'0%',
        position:'absolute',
        objectFit:'cover',
        transform: 'translate(-50%, -4%)'
    },
    textField:{
        width:'100%'
    },
    roundSubmit:{
        float:'right',
        marginTop:'20px',
        marginBottom:'20px'
    },
})

class Preview extends Component {

    render() {
        const {classes} = this.props;

        return (
            <Link style={{textDecoration:'none', color:'#313131'}} to={this.props.to} onClick={this.props.handleClose}>
                <div style={{textAlign:'center', position:'relative'}}>

                <Typography><Box fontSize={18} fontWeight={600}> {this.props.secondary} </Box></Typography>
                <div style={{textAlign:'center', position:'relative'}}>
                    <img src={this.props.bannerImage} className={classes.bannerImage}/>
                    <div className={classes.borderImage}>
                        <img src={this.props.image} className={classes.image}/>
                    </div>
                </div>

                <Typography><Box fontSize={18} fontWeight={600}> {this.props.name} </Box></Typography>

                </div>
            </Link>
            
        )
    }
}

Preview.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default connect(null, null)(withStyles(styles)(Preview))