import React from "react";

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Fade from 'react-reveal/Fade';
import { Fragment } from "react";


class Header extends React.Component {
  render() {
    let colorHeader = this.props.dark ? ('black'):('white')
    let colorBody = this.props.dark ? ('rgb(74 96 136)') :('#7a8caaff')
    let width1 = this.props.width ? (this.props.width):'450px'
    let borderSize2 = this.props.borderSize ? (this.props.borderSize):('12px')
    let topMargin = this.props.topMargin ? ('12px'):('0px')
    let textAlign2 = this.props.textAlign ? (this.props.textAlign):null
    let justifyContent = this.props.justifyContent ? (this.props.justifyContent):null
    let display = this.props.display ? (this.props.display):null

    return (
        
        <div style={{maxWidth: width1, padding:'6px', paddingLeft: this.props.borderSize ? '6px':'20px', justifyContent:justifyContent, display:display, textAlign:textAlign2}}>
          <Fade top delay={1200} distance='30px'>
          <div style={{position: !justifyContent ? 'absolute':null, height:'100%', width: borderSize2, left: !justifyContent?'-20px':'0px', backgroundColor:'#ff5555ff', textAlign: textAlign2}}/>
          </Fade>
          <Fade left cascade>
          <Typography>
              {!this.props.mobile ? (
                <Fragment>
                <Box color={colorHeader} fontSize={50} fontWeight={600}>
                {this.props.header}
                </Box>
                <Box color={colorBody} fontSize={22} fontWeight={500} style={{marginTop:topMargin}}>
                  {this.props.body}
                </Box>
                </Fragment>
              ):(
                <Fragment>
                <Box color={colorHeader} fontSize={40} fontWeight={600}>
                {this.props.header}
                </Box>
                <Box color={colorBody} fontSize={18} fontWeight={500} style={{marginTop:topMargin}}>
                {this.props.body}
                </Box>
                </Fragment>
              )}
              
          </Typography>
          </Fade>
        </div>
    )
  }
}

export default Header;
