import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150px"
      version="1.1"
      viewBox="0 0 165 104"
    >
      <g fillOpacity="1">
        <path
          fill="#fff"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.262"
          d="M64.228 9.846L6.875 99.436l154.253-33.174-41.984-23.99-9.933 4.872-2.624-12.182z"
        ></path>
        <path
          fill="#e7e4e8"
          strokeDasharray="0.354195, 0.354195"
          strokeWidth="0.071"
          d="M11.87 96.478c.35-.347 94.33-61.031 94.483-61.01.123.018 2.74 11.71 2.635 11.772-.46.271-96.702 49.21-96.948 49.297-.237.084-.295.064-.17-.059z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
