import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40px"
      version="1.1"
      viewBox="0 0 103 104"
    >
      <defs>
        <linearGradient
          id="linearGradient24576"
          x1="-22.813"
          x2="-16.426"
          y1="11.401"
          y2="-6.42"
          gradientTransform="matrix(5.096 0 0 5.096 132.052 46.91)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient24574"
        ></linearGradient>
        <linearGradient id="linearGradient24574">
          <stop offset="0" stopColor="#3049f5" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#3988f5" stopOpacity="0.988"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient24632"
          x1="-17.785"
          x2="-14.865"
          y1="7.846"
          y2="4.534"
          gradientTransform="matrix(5.096 0 0 5.096 132.052 46.91)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient24638"
        ></linearGradient>
        <linearGradient id="linearGradient24638">
          <stop offset="0" stopColor="#304cf5" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#3988f5" stopOpacity="0.988"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient24630"
          x1="-19.848"
          x2="-10.729"
          y1="5.575"
          y2="-4.113"
          gradientTransform="matrix(5.096 0 0 5.096 132.052 46.91)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient24638"
        ></linearGradient>
      </defs>
      <g>
        <path
          fill="none"
          stroke="url(#linearGradient24576)"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="7.644"
          d="M5.976 96.752l.54-53.09C15.304-.288 82.162-8.583 95.577 39.385c6.411 31.51-9.407 48.076-32.377 57.558z"
        ></path>
        <circle
          cx="49.018"
          cy="80.524"
          r="5.05"
          fill="url(#linearGradient24632)"
          fillOpacity="1"
          stroke="none"
          strokeWidth="30.472"
        ></circle>
        <path
          fill="url(#linearGradient24630)"
          fillOpacity="1"
          stroke="none"
          strokeWidth="0.081"
          d="M45.793 68.958c-.28-5.552.135-7.179 4.154-16.196 2.218-4.976 4.27-10.319 4.562-11.872.795-4.246-.258-8.21-3.017-11.353-2.813-3.204-5.752-1.799-8.584.5-1.847 2.528-1.78 3.638-1.211 8.385.287 4.006-.842 5.318-4.24 5.318-4.48 0-6.613-3.906-5.71-10.463.86-6.256 5.802-13.155 13.302-13.958 14.295-1.531 21.394 6.495 21.34 18.028-.039 6.397-.958 8.418-8.006 17.455-5.05 6.476-8.335 12.356-8.37 14.985-.01.761-.878 1.489-2.044 1.711-1.84.352-2.04.118-2.175-2.54z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
