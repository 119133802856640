import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import MyIconButton from '../../util/IconButton'
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

import {connect} from 'react-redux'
import {deletePost } from '../../redux/actions/dataActions'

const styles = {
    deleteButton:{
        left: '30px',
        position: 'absolute',
        top: '30px'
    }
}

class DeletePost extends Component {
    state = {
        open: false
    }
    handleOpen = () =>{
        this.setState({open: true})
    }
    handleClose= ()=>{
        
        this.setState({open: false})
    }
    deletePost = ()=>{
        this.props.close()
        this.props.deletePost(this.props.postId)
        this.setState({open:false})
    }
    render() {
        const {classes} = this.props;

        return (
            <Fragment>
                <MenuItem tip="Delete Post" onClick={this.handleOpen}>
                    Delete
                </MenuItem>

                <Dialog open={this.state.open} onClick={()=>{this.handleOpen();}} maxWidth="sm">
                    <DialogTitle>
                        Are you sure you want to delete this post?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} style={{outline:'none'}} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.deletePost} style={{outline:'none'}} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

DeletePost.propTypes = {
    deletePost: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    postId: PropTypes.string.isRequired
}

export default connect(null, {deletePost})(withStyles(styles)(DeletePost))
