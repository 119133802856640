import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

import {connect} from 'react-redux'
import {untagPost} from '../../redux/actions/dataActions'

const styles = {
    deleteButton:{
        left: '30px',
        position: 'absolute',
        top: '30px'
    }
}

class Untag extends Component {
    state = {
        open: false
    }
    handleOpen = () =>{
        this.setState({open: true})
    }
    handleClose= ()=>{
        this.setState({open: false})
    }
    untagPost = ()=>{
        this.props.close()
        this.props.untagPost({topic: this.props.topic, postId: this.props.postId})
        this.setState({open:false})
    }
    render() {
        const {classes} = this.props;

        return (
            <Fragment>
                <MenuItem tip="Delete Post" onClick={()=>{this.handleOpen()}}>
                    Untag
                </MenuItem>

                <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="sm">
                    <DialogTitle>
                        Are you sure you want to untag this post?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} style={{outline:'none'}} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.untagPost} style={{outline:'none'}} color="secondary">
                            Untag
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

Untag.propTypes = {
    untagPost: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    topic: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    postId: PropTypes.string.isRequired
}

export default connect(null, {untagPost})(withStyles(styles)(Untag))
