//User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER'
export const LOADING_USER = 'LOADING_USER'
export const LOADING_TOPIC = 'LOADING_TOPIC'
export const SET_TOPIC = 'SET_TOPIC'
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ'

//UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const TOGGLE_EXPANDED = "TOGGLE_EXPANDED"

//Data reducer types
export const SET_POSTS = "SET_POSTS"
export const SET_POST = "SET_POST"
export const LIKE_POST = "LIKE_POST"
export const UNLIKE_POST = "UNLIKE_POST"
export const LOADING_DATA = "LOADING_DATA"
export const DELETE_POST = "DELETE_POST"
export const CREATE_POST = "CREATE_POST"
export const SUBMIT_COMMENT = "SUBMIT_COMMENT"
export const CREATE_TOPIC_REQUEST = "CREATE_TOPIC_REQUEST"
export const SET_TOPIC_REQUESTS = "SET_TOPIC_REQUESTS"
export const SET_TABLE_OF_CONTENTS = "SET_TABLE_OF_CONTENTS"
export const LOADING_TOPICS ="LOADING_TOPICS"
export const JUDGE_REQUEST = "JUDGE_REQUEST"
export const EDIT_POST="EDIT_POST"

//reset data
export const RESET_REQUESTS = "RESET REQUESTS"
export const RESET_POSTS = "RESET POSTS"
export const RESET_DIR = "RESET DIR"
export const RESET_TOPIC = "RESET TOPIC"