import React, { Fragment, Component, createRef} from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import {Row, Col, Grid} from '../components/layout/Flexbox'
import { connect } from 'react-redux'
import Header from '../components/layout/Header'

import withStyles from '@material-ui/core/styles/withStyles'
import AboutHeader from '../components/layout/AboutHeader'
import CircleImage from '../components/layout/CircleImage';
import Blurb from '../components/layout/Blurb';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import ChatIcon from '@material-ui/icons/Chat'

import rightLine from '../images/rightLine.png'
import leftLine from '../images/leftLine.png'
import tinyPhoto from '../images/tiny_photo.png'
import Fade from 'react-reveal/Fade'
import Pulse from 'react-reveal/Pulse'

import photo1 from '../images/circleGuy1.jpg'
import photo2 from '../images/circleGuy2.jpg'
import photo3 from '../images/circleGuy3.jpg'
import photo4 from '../images/circleGuy4.jpg'
import henryPhoto from '../images/henry.jpeg'
import Text from '../components/layout/Text'

const styles = (theme) => ({
    ...theme.spreadThis
});

class about extends Component {
    state={}
    render() {
        const {classes} = this.props;

        let threeImages = (
            <Row>
                <Col size={1}>
                    <Paper className={classes.paperImage} style={{}}>
                        <img src={henryPhoto} style={{width:'90%', height:'100%', objectFit:'cover'}}/>
                    </Paper>
                    <div style={{textAlign:'center', marginTop:'10px'}}>
                        <Text fontSize={16} fontWeight={400}>
                                Henry Gunderson
                        </Text>
                    </div>
                </Col>
                <Col size={1}>
                    <Paper className={classes.paperImage} style={{}}>
                        <img src={tinyPhoto} style={{width:'90%', height:'100%', objectFit:'cover'}}/>
                    </Paper>
                    <div style={{textAlign:'center', marginTop:'10px'}}>
                        <Text fontSize={16} fontWeight={400}>
                                Thomas White
                        </Text>
                    </div>
                </Col>
            </Row>
        )


        return (
            <div style={{position:'relative', backgroundColor:'rgb(4, 13, 33)'}}>
            <div style={{marginTop:'-80px', width:'100%', height:'80px', backgroundColor: 'rgb(4, 13, 33)', boxShadow:'none', border:'none'}}/>
            <Row>
                <div style={{backgroundColor:'white', width:'100%', minHeight:'600px', justifyContent:'center', textAlign:'center'}}>
                {/* The left side  */}
                
                <Col>
                    <Fade top delay={1000} distance='0px'>
                    <img src={leftLine} style={{position:'absolute', top:'80px', right:'35px'}}/>

                    <div>
                    <FavoriteBorder style={{position:'absolute', left:'7.5%', top:'410px', color:'#ccc'}}/>
                    <FavoriteBorder fontSize="large" style={{position:'absolute', left:'9%', top:'490px', color:'#ccc'}}/>
                    </div>

                    <div>
                    <ChatIcon style={{position:'absolute', left:'5.1%', top:'320px', color:'#dcdcd1'}}/>
                    <ChatIcon fontSize="medium" style={{position:'absolute', left:'3.8%', top:'120px', color:'#7693d2'}}/>
                    <ChatIcon fontSize="medium" style={{position:'absolute', left:'14%', top:'448px', color:'#ccc'}}/>
                    <ChatIcon fontSize="large" style={{position:'absolute', left:'14%', top:'570px', color:'#7693d2'}}/>
                    </div>
                    </Fade>

                    <CircleImage image={photo1} style={{position:'absolute', left:'2%', top:'190px', zIndex:2}}/>
                    <Blurb body="How would you raise yourself differently?" like={true} style={{position:'absolute', left:'6%', top:'145px', transform:'rotate(3deg)', zIndex:2}}/>
                    <CircleImage image={photo2} style={{position:'absolute', left:'17%', top:'490px', zIndex:2}}/>

                    <Blurb body="What's the best way to express you feelings?" comment={true} style={{position:'absolute', left:'3%', top:'445px', transform:'rotate(-3deg)', zIndex:2}}/>
                </Col>

                {/* The right side */}

                <Col collapse="ld">
                    <Fade top delay={1000} distance='90px'>
                    <img src={rightLine} style={{position:'absolute', top:'80px', left:'35px'}}/>

                    <div>
                    <FavoriteBorder style={{position:'absolute', right:'7.5%', top:'410px', color:'#ccc'}}/>
                    <FavoriteBorder fontSize="large" style={{position:'absolute', right:'9%', top:'490px', color:'#ccc'}}/>
                    </div>

                    <div>
                    <ChatIcon style={{position:'absolute', right:'5.1%', top:'320px', color:'#dcdcd1'}}/>
                    <ChatIcon fontSize="medium" style={{position:'absolute', right:'3.8%', top:'120px', color:'#7693d2'}}/>
                    <ChatIcon fontSize="medium" style={{position:'absolute', right:'14%', top:'448px', color:'#ccc'}}/>
                    <ChatIcon fontSize="large" style={{position:'absolute', right:'14%', top:'570px', color:'#7693d2'}}/>
                    </div>
                    </Fade>

                    <CircleImage image={photo3} style={{position:'absolute', right:'2%', top:'190px'}}/>
                    <Blurb body="What would happen if your one life dream never came to pass?" like={true} style={{position:'absolute', right:'6%', top:'145px', transform:'rotate(-3deg)'}}/>
                    <CircleImage image={photo4} style={{position:'absolute', right:'17%', top:'460px'}}/>
                    <Blurb body='What does it mean to be "in love?"' comment={true} style={{position:'absolute', right:'3%', top:'445px', transform:'rotate(3deg)'}}/>
                </Col>

                <Col size={1} collapse="ld" style={{marginTop:'210px'}}>
                        <AboutHeader header="About WikiQ" body="Community Guidelines"  to='/communityGuidelines'/>
                </Col>
                <Col size={1} collapse="ls" style={{marginTop:'210px'}}> 
                        <AboutHeader header="About WikiQ" body="Community Guidelines" mobile={true}  to='/communityGuidelines'/>
                </Col>

                </div>
                
            </Row>

            
            <div style={{backgroundColor:'#040d21', paddingLeft:'10%', paddingRight:'10%', paddingTop:'40px', paddingBottom:'60px', position:'relative'}}>

                <div style={{marginTop:'20px'}}>
                    <Header topMargin="12px" header="What is WikiQ" width='600px' body="WikiQ is a compendium of questions. We believe that there is inherent value in asking difficult questions with no clear answer. The simple act of taking the time to think about a question can generate insight into the human experience and engender empathy along the way. We’re not looking for questions with easy answers or any answer at all. We simply want to serve as a gathering place of important questions. " mobile={true}/>
                </div>
            </div>

            <div style={{backgroundColor:'white', paddingLeft:'10%', paddingRight:'10%', paddingTop:'40px', paddingBottom:'60px', position:'relative'}}>

                <Grid style={{width:'100%'}}>
                    <Row>
                        <Col>
                            <div style={{marginTop:'20px'}}>
                                                <Header dark={true} topMargin="12px" header="Who Founded WikiQ" width='600px' body="WikiQ was created by Henry Gunderson and his Dad, Joe Gunderson. Henry’s Dad loved asking questions at the dinner table and eventually started carrying a notebook around with him to write down any new questions people could come up with. Over time he amassed thousands of questions and topics and wanted a way for other people to both see and add to his big book of questions. Henry offered to help build a website and found a fantastic software engineer at the University of Virginia (Thomas White) to help build out the website. WikiQ is designed to ask the unanswerable questions that constitute every facet of the human experience.
                            " mobile={true}/>
                                            </div>
                        </Col>
                        <Col collapse="ld" style={{paddingLeft:'6%', paddingTop:'60px'}}>
                            {threeImages}
                        </Col>
                    </Row>
                    <Row>
                        <Col collapse="ls" style={{marginTop:'40px', marginBottom:'20px'}}>
                            {threeImages}
                        </Col>
                    </Row>
                </Grid>
                
                
            </div>

            <div style={{backgroundColor:'#040d21', minHeight:'400px', paddingLeft:'10%', paddingRight:'10%', paddingTop:'40px', paddingBottom:'140px', position:'relative'}}>

                <div style={{marginTop:'20px'}}>
                    <Header topMargin="12px" header="How does WikiQ make money?" width='600px' body="Short answer, we don’t. We are a privately funded not for profit that will never sell or give away your data." mobile={true}/>
                    <Header topMargin="12px" header="Contact" width='600px' body="Please contact WikiQ.org@gmail.com for inquiries" mobile={true}/>
                    <div style={{ height:'60px', marginTop:'80px'}}>
                        <a rel="license"  href="http://creativecommons.org/licenses/by-nd/4.0/"></a><br /><Typography><Box fontWeight={300} fontSize={13} color='white'><img width="20px" src='https://mirrors.creativecommons.org/presskit/icons/cc.png'/>
                        <img width="20px" style={{marginLeft:'4px', marginRight:'4px'}} src='https://mirrors.creativecommons.org/presskit/icons/by.png'/>
                        <img width="20px" style={{marginRight:'8px'}} src='https://mirrors.creativecommons.org/presskit/icons/nd.png'/> 
                        This work is licensed under a <a rel="license" style={{color:'white'}} href="http://creativecommons.org/licenses/by-nd/4.0/">Creative Commons Attribution-NoDerivatives 4.0 International License.</a></Box></Typography>
                    </div>
                </div>
                
            </div>
            </div>
        )
    }
}

about.propTypes={
    getPosts: PropTypes.func.isRequired,
}

export default connect (null, null)(withStyles(styles)(about))
