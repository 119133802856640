import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'

import {logoutUser} from '../../redux/actions/userActions'

const styles = (theme) => ({
    ...theme.spreadThis
});

class LogoutUser extends Component{
    handleLogout = () =>{
        this.props.logoutUser();
    }
    render(){
        const {classes, user: {
            credentials: {handle, name, createdAt, imageUrl, bio, website, country, degree}, 
            loading, 
            authenticated,
            mobile}
        } = this.props

        return(
            <MenuItem onClick={this.handleLogout}>
                {mobile && (<KeyboardReturnIcon style={{marginRight: '10px'}}/>)}
                Logout User
            </MenuItem>
        )
    }
}

const mapActionsToProps = {logoutUser}
const mapStateToProps = (state) =>({
    user: state.user
})

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(LogoutUser))