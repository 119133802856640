import React, { Fragment, Component, createRef} from 'react'
import PropTypes from 'prop-types'

import Post from '../components/post/Post'
import PostSkeleton from '../util/PostSkeleton'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {Row, Col, Grid} from '../components/layout/Flexbox'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles'
import AboutHeader from '../components/layout/AboutHeader'
import CircleImage from '../components/layout/CircleImage';

import {getPosts, resetPosts} from '../redux/actions/dataActions'
import LandingBackground from './LandingBackground'
import PaperPlane from './PaperPlane'
import PaperPlane2 from './PaperPlane2'
import Text from '../components/layout/Text'
import CountUp from 'react-countup'

import './planes.css'
import axios from 'axios';

const styles = (theme) => ({
    ...theme.spreadThis,
    wideButton:{
        margin:'10px',
        borderRadius:'100px',
        padding:'15px 50px',
        textTransform:'none',
        background:'#fff',
        "&:hover": {
            boxShadow: '0px 0px 12px 0px rgb(0 0 0 / 22%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
            background:'#f1f1f1',
            color:'#293754'
          }
    },
    wideButton2:{
        margin:'10px',
        borderRadius:'100px',
        outline:'none',
        padding:'15px 24px',
        textTransform:'none',
        background:'#040d21',
        color:'#fff',
        "&:hover": {
            boxShadow: '0px 0px 12px 0px rgb(0 0 0 / 22%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
            background:'#091735',
            color:'#fff'
          }
    }
});

class landingPage extends Component {

    constructor(props)
    {
        super(props)
        
    }
    state = {
        numQuestions:0
    }

    getMorePosts = ()=>{
        this.props.resetPosts();

        let arg = "0"

        this.props.getPosts({id: arg}, true) //second argument is rising boolean
    }

    getNumQuestions = () =>{
        axios.get('/numberOfQuestions')
        .then(res=>{
            this.setState({numQuestions: res.data.numberOfQuestions})
        })
        .catch(err=>{
            console.log(err);
        })
    }

    componentDidMount(){
        this.getMorePosts();
        this.getNumQuestions();
    }

    render() {
        const {classes} = this.props;
        const {loading, posts} = this.props

        let postMarkups = loading && posts===null ? ( 
            <PostSkeleton/>
        ) : (
            <Row>
                <Col size={1} collapse="sd">
                    {posts.map((post) =>  <Post key={post.postId} post={post}/>)}
                </Col>
                <Col size={1} collapse="dd">
                    {posts.map((post) => <Post key={post.postId} post={post} width="narrow"/>)}
                </Col>
            </Row>
            );

        let countHeader = (
            <CountUp start={0} end={this.state.numQuestions} delay={0} duration={4}>
                {({ countUpRef }) => (
                    <Text fontSize={45} fontWeight={600}>
                        <span ref={countUpRef} />
                        <span style={{marginLeft:'12px'}}>
                            questions and counting
                        </span>
                        
                    </Text>
                )}

                </CountUp>
        )

        return (
            <div style={{position:'relative', backgroundColor:'rgb(4, 13, 33)', width: '100vw'}}>
            <Row style={{marginTop: '-80px', width: '100vw', overflow: 'hidden', position: 'relative'}}>
                <div style={{position:'absolute', top:'-60px', left:'60%', transform:'translate(-60%)', height:'640px', margin:'0px', padding:'0px'}}>
                    <LandingBackground/>

                    <div className='planeWrapper'>
                        <PaperPlane/>
                    </div>

                    <div className='planeWrapper2'>
                        <PaperPlane2/>
                    </div>
                </div>

                

                <div style={{width:'100%', minHeight:'536px', justifyContent:'center', textAlign:'center'}}>

                    <Col size={1} collapse="ld" style={{marginTop:'190px', marginBottom:'30px'}}>
                            <AboutHeader title={true} header="The compendium of questions" body="We are a non-profit dedicated to building a collection of the world's most asked questions. Help contribute to the pool of questions or see what other people and organizations are asking." />
                    </Col>
                    <Col size={1} collapse="ls" style={{marginTop:'100px', padding:'20px'}}> 
                            <AboutHeader title={true} header="The compendium of questions" body="We are a non-profit dedicated to building a collection of the world's most asked questions. Help contribute to the pool of questions or see what other people and organizations are asking." mobile={true} />
                    </Col>

                    <div style={{marginTop:'-10px'}}>

                        <Button variant="contained" component={Link} to='/feed' style={{outline:'none'}} disableElevation={true} className={classes.wideButton}>
                            <Typography>
                                <Box fontSize={18}>
                                    Check it out
                                </Box>
                            </Typography>
                        </Button>

                        <Button variant="contained" component={Link} to='/login' style={{outline:'none'}} disableElevation={true} className={classes.wideButton2}>
                            <Typography>
                                <Box fontSize={18}>
                                    Login or Signup
                                </Box>
                            </Typography>
                        </Button>

                    </div>

                </div>  
            </Row>

            
            <div style={{backgroundColor:'#f7f7f7', paddingLeft:'10%', paddingRight:'10%', minHeight:'700px', paddingTop:'40px', paddingBottom:'60px', position:'relative'}}>
                <Row>
                    <Col size={1} collapse="ls">
                        <AboutHeader aside={true} header={countHeader} body="Wiki Q is used by museums, corporations, regular people, and many more. We aim to collect questions from everybody around the world." />
                    </Col>
                </Row>
                <Row style={{marginTop: '40px'}}>
                    
                    <Col size={1}>
                        <div style={{float:'right'}}>
                            {postMarkups}
                        </div>
                        
                    </Col>
                    <Col size={1} collapse="ld">
                        <AboutHeader aside={true} header={countHeader} body="Wiki Q is used by museums, corporations, regular people, and many more. We aim to collect questions from everybody around the world." />
                    </Col>
                </Row>
                
            </div>
            </div>
            
            
        )
    }
}

landingPage.propTypes={
    getPosts: PropTypes.func.isRequired,
    resetPosts: PropTypes.func.isRequired,
}

const mapStateToProps = (state) =>({
    data: state.data,
    posts: state.data.posts,
    user: state.user,
    loading: state.data.loading,
})

export default connect (mapStateToProps, {getPosts, resetPosts})(withStyles(styles)(landingPage))
