import React, {Component, Fragment} from 'react';
import MaterialSearchBar from 'material-ui-search-bar'
import {Search, Checkbox} from 'semantic-ui-react'
import axios from 'axios'
import Post from '../post/Post'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Row, Col} from '../layout/Flexbox'
import { Typography, Box } from '@material-ui/core';
import Preview from './Preview'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ThemeProvider } from 'styled-components';
import Text from '../layout/Text'

const styles=(theme)=>({
  ...theme.spreadThis,
  button:{
      float:'right',
  },
  progressSpinner:{
      position: 'absolute'
  },
  image:{
      width:184,
      height:184,
      margin: '8px 0 0 1px',
      borderRadius: '50%',
      objectFit: 'cover',
      overflow: "hidden",
  },
  bannerImage:{
      height: '159px',
      marginBottom:'30px',
      marginTop:'30px',
      width: '100%'
  },
  borderImage:{
      width:200,
      height:200,
      borderRadius: '50%',
      backgroundColor: "white",
      left:'50%',
      bottom:'0%',
      position:'absolute',
      transform: 'translate(-50%, -4%)'
  },
  textField:{
      width:'100%'
  },
  roundSubmit:{
      float:'right',
      marginTop:'20px',
      marginBottom:'20px'
  }
})


class SearchBar extends Component {
  state = {
    value: "",
    loading_search: false,
    success_message: '',
    search_errors: '',
    expanded: false,
    results: [],
    selected_radio: 'Posts'
  }

  componentDidMount(){
    if (this.props.type){
      this.setState({selected_radio: this.props.type})
    }
  }

  handleSearchChange = (event, data) =>{
    this.setState({value: data.value, results:[]})
  }

  handleSubmitSearch = (type) =>{
    console.log('submitting search')
    if(this.state.value.length===0){return;}
    console.log('searching '+this.state.value + " and "+ type)

    let toSend = {search: this.state.value, type: type} //these are required

    this.setState({loading_search: true})

    axios.post('/search', toSend) //this gets the posts, topic requests, etc
        .then(res=>{
            this.setState({loading_search:false, success_message: 'Found posts', results: res.data.results})
            console.log(this.state.results)
        })
        .catch(err => {
            console.log(err)
            console.log(err.response.data)
            this.setState({search_errors: err.response.data})
            this.setState({loading_search:false, success_message: ''})
        })
  }

  checkKey = (event)=>{
    if(event.key==='Enter')
    {
      this.handleSubmitSearch(this.state.selected_radio)
      this.setState({expanded: true})
    }
  }

  handleClose = ()=>{
    this.setState({expanded: false})
  }

  handleChangeRadio = (type) =>{
    this.setState({results: []})
    if(this.state.selected_radio===type)
    {
      this.setState({selected_radio: 'Posts'})
    }
    else{
      this.setState({selected_radio: type})
    }
    this.handleSubmitSearch(type)
  }

  render(){
    // let renderedResults = this.state.results.length!==0 && (

      let resultsMarkup = this.state.results[0]==="Nothing found" ? (

          <div style={{textAlign:'center', marginTop:'100px'}}>
            <Box fontSize={20}>
            <Typography>Nothing found</Typography>
            </Box>
          
          </div>
        
      ):(
        <Fragment>
          <Row>
                <Col size={1} collapse="sd">
                  {this.state.selected_radio==='Posts' && (this.state.results.map(post => <Post key={post.postId} post={post}/>))}
                  {this.state.selected_radio==='Users' && (this.state.results.map(user => <Preview key={user.name+'_0'} to={'/users/'+user.handle} name={user.name} secondary={user.handle} bannerImage={user.bannerUrl} image={user.imageUrl} handleClose={this.handleClose}/>))}
                  {this.state.selected_radio==='Topics' && (this.state.results.map(topic => <Preview key={topic.topicName+'_0'} to={'/topics/'+topic.topicName} name={topic.topicName} secondary='' bannerImage={topic.bannerImage} image={topic.imageUrl} handleClose={this.handleClose} />))}            
                </Col>
                <Col size={1} collapse="dd">
                {this.state.selected_radio==='Posts' && (this.state.results.map(post => <Post key={post.postId} post={post} width="narrow"/>))}
                  {this.state.selected_radio==='Users' && (this.state.results.map(user => <Preview key={user.name+'_0'} to={''} to={'/users/'+user.handle} name={user.name} secondary={user.handle} bannerImage={user.bannerUrl} image={user.imageUrl} handleClose={this.handleClose}/>))}
                  {this.state.selected_radio==='Topics' && (this.state.results.map(topic => <Preview key={topic.topicName+'_0'} to={'/topics/'+topic.topicName} name={topic.topicName} secondary='' bannerImage={topic.bannerImage} image={topic.imageUrl} handleClose={this.handleClose}/>))}   
                </Col>
                
            </Row>
            <div style={{textAlign:'center', marginTop:'100px', justifyContent:'center'}}>
                  {this.state.loading_search && <CircularProgress size={30}/>}
              </div>
        </Fragment>
          
            
      ) 

    let radioButton = (type, mobile) => (
      !mobile ?(
        <Checkbox label={type} style={{marginLeft:'12px'}} toggle onChange={()=>this.handleChangeRadio(type)} checked={this.state.selected_radio===type}/>
      ) :(
        <div>
          <div>
            <Text fontSize={14} fontWeight={500} style={{marginLeft:'15px'}}>
              {type}
            </Text>
          </div>
          <div>
            <Checkbox style={{marginLeft:'12px'}} toggle onChange={()=>this.handleChangeRadio(type)} checked={this.state.selected_radio===type}/>
          </div>         
        </div>
      )
            
    )

    return (
      <Fragment>
      <Search input={{ fluid: true }} className="search" loading={this.state.loading_search} value={this.state.value} minCharacters={99999} selectFirstResult={false} showNoResults={true}
          onSearchChange={this.handleSearchChange} onKeyPress={this.checkKey} 
          style={{margin: '12px', width: '100%', marginLeft:'0px', marginRight: '30px', height: 33, borderColor: '#FF5722', borderRadius: 20, backgroundColor: 'transparent'}}  
          >
          </Search>
        

        <Dialog open={this.state.expanded} onClose={this.handleClose} fullWidth
            maxWidth="lg">
          <DialogTitle>
            <Row>
              <Col size={6}>
                <Search input={{ fluid: true }} className="searchGrey" loading={this.state.loading_search} value={this.state.value} minCharacters={99999} selectFirstResult={false} showNoResults={true}
                onSearchChange={this.handleSearchChange} onKeyPress={this.checkKey} 
                style={{margin: '12px', marginBottom:'18px', width: '100%', marginLeft:'0px', marginRight: '30px', height: 33, borderColor: 'black', borderRadius: 20, backgroundColor: 'transparent'}}  
                >
              </Search>
              </Col>
              <Col size={.4} collapse="sd"/>
              <Col size={1} collapse="sd" style={{marginTop:'20px'}}>
                {radioButton('Posts',false)}
              </Col>
              <Col size={1} collapse="sd" style={{marginTop:'20px'}}>
                {radioButton('Topics',false)}
              </Col>
              <Col size={1} collapse="sd" style={{marginTop:'20px'}}>
                {radioButton('Users',false)}
              </Col>
              <Col size={2} collapse="md"/>
              
            </Row>
            <Row>
              <Col size={1} collapse="dd" style={{marginTop:'20px'}}>
                {radioButton('Posts',true)}
              </Col>
              <Col size={1} collapse="dd" style={{marginTop:'20px'}}>
                {radioButton('Topics',true)}
              </Col>
              <Col size={1} collapse="dd" style={{marginTop:'20px'}}>
                {radioButton('Users',true)}
              </Col>
              <Col size={2} collapse="dd"/>
            </Row>
            <hr style={{marginTop:'10px'}}/>


            
            
            
            

          </DialogTitle>

          <DialogContent style={{width:'100%', height:'100%'}}>
            <div id="div-to-center" style={{width:'100%', minHeight:'800px'}}>
              <div style={{}}>
                {resultsMarkup}
              </div>
              
            </div>
          </DialogContent>
        </Dialog>

      </Fragment>
    )
      
  }
  
}
export default SearchBar