import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {Link} from 'react-router-dom';
import MyIconButton from '../../util/IconButton'
import PropTypes from 'prop-types'

//MUI Stuff
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ReactPlayer from 'react-player'
import Image from 'react-bootstrap/Image'
import ButtonM from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid';
import {Button, Icon} from 'semantic-ui-react'
import IconButton from '@material-ui/core/IconButton'
import Favorite from '@material-ui/icons/Favorite'
import ButtonBase from '@material-ui/core/ButtonBase';

//Icons
import ChatIcon from '@material-ui/icons/Chat'
import { shadows } from '@material-ui/system';
import {connect} from 'react-redux'
import Box from '@material-ui/core/Box'
import TimerIcon from '@material-ui/icons/WatchLater';
import {judgeTopicRequest} from '../../redux/actions/dataActions'

import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import noImg from '../../images/no-img.png'

const styles= (theme) => ({
    card: {
        width: '320px',
        height: '390px',
        position: 'relative',
        display: 'flex',
        boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 9%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        display: 'inline-block',
        margin: 14,
        borderRadius: 7,
        textAlign: 'left',
        "&:hover": {
            boxShadow: '0px 0px 12px 0px rgb(0 0 0 / 22%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
            
          }
    },   
    image:{
        width: 38,
        height:38,
        borderRadius: '50%',
        objectFit: 'cover'
    },
    content:{
        padding: 0,
        paddingBottom: 3,
        width: '100%',
        height: '100%',
        objectFit:'cover',
        fontFamily: 'BlinkMacSystemFont',
        display: 'inline-block',
        "&:last-child": {
            paddingBottom: 0
          }
    },
    content2:{
        padding:0,
        width: '100%',
        objectFit:'cover',
        fontFamily: 'BlinkMacSystemFont',
    },
    tagButton:{
        margin: 4,
        float: 'right',
        marginTop: 12,
        //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        height: '30px',
        padding: '4 8px 4 8px',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            backgroundColor: "blue !important",
            transform: 'translate(0,-3px)'
          }
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    bodyContainer:{
        marginTop: 12,
        textAlign: 'center'
    },
    tagContainer:{
        width: '100%',
        float: 'right',
        padding:1,
        marginTop: -57
    },
    date:{
        fontSize: 'small'
    },
    circleButton:{
        margin:24,
        width:30,
        height:30,
        marginLeft: 9,
        float: 'right',
        background: 'linear-gradient(45deg, #eb7d34 30%, #ffa81c 90%)',
        borderRadius: 400,
        border: 0,
        padding: 12,
        boxShadow: '-1px 2px 20px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            backgroundColor: "blue !important",
            transform: 'translate(0,-3px)'
          }

    },
    circleButton2:{
        margin: 4,
        width:30,
        height:30,
        marginLeft: 9,
        float: 'right',
        background: 'linear-gradient(45deg, #93dcd2ff 30%, #B7F8DB 90%)',
        borderRadius: 400,
        border: 0,
        padding: 12,
        boxShadow: '-1px 2px 20px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            backgroundColor: "blue !important",
            transform: 'translate(0,-3px)'
          }
    },
    timerIcon:{
        position:'absolute',
        float: 'right',
        top: '3px',
        right: '3px',
    },
    bottomButtonLeft:{
        position: 'absolute',
        left: '15%',
        top:'87%'
    },
    bottomButtonRight:{
        position: 'absolute',
        right: '15%',
        top:'87%'
    }
    
})


class TopicRequest extends Component {
    state={
        topicName:"",
        value: true
    }
    handleChange = (event) =>{
        this.setState({[event.target.name]: event.target.value})
    }
    handleSubmitYes = (event) =>{
        event.preventDefault();
        this.props.judgeTopicRequest(this.props.topicRequest.topicName, {'topicName': this.props.topicRequest.topicName, 'value': true})
    }
    handleSubmitNo = (event) =>{
        event.preventDefault();
        this.props.judgeTopicRequest(this.props.topicRequest.topicName, {'topicName': this.props.topicRequest.topicName, 'value': false})
    }
    getThumbnailImage = (url) =>{
        if(url.indexOf('/userImages/')===-1)
        {
            return url;
        }
        let t = url.split('/userImages/')
        return t[0] + '/userImages/'+ t[1].split('.')[0] + '-1.'+t[1].split('.')[1]
    }

    render() {
        dayjs.extend(relativeTime);
        const {classes, topicRequest : {creatorImg, path, creatorName, topicName, creator, createdAt, justification}} = this.props;

        const topHeader = (
            <Fragment>
                <Link>
                
                <Grid container wrap="nowrap" spacing={2} style={{padding: '6px 16px'}}>
                    <Grid item>
                        <img src={this.getThumbnailImage(creatorImg)} title="Profile image" className={classes.image}
                        onError={e=>{e.target.src=noImg}}
                        />
                    </Grid>
                    <Grid item xs={12} style={{width: '100%', paddingTop: '16px'}}>
                        <Typography to={'/users/'+creator}>
                            <Box fontSize="15" fontWeight="600">
                            {creatorName}
                            </Box>
                        </Typography>
                    </Grid>
                    
                </Grid>
                </Link>
                
            </Fragment>
        )

        const fullCard = (
            <Card className={classes.card} variant="outlined">
                
                <CardContent className={classes.content}>
                    <CardHeader
                        className={classes.content2}
                        title={
                            <Fragment>
                                {topHeader}
                            </Fragment>  
                        }
                    />
                    <hr style={{marginTop:'-3px'}}/>
                    <div className={classes.timerIcon}>
                    {/* <IconButton className={classes.circleButton} component="span">
                            <TimerIcon style={{color: 'white', width: 17, height: 17}} size="medium"/>
                    </IconButton> */}
                    </div>
                    
                    
                    <Container className={classes.bodyContainer}>
                        <Typography variant="h6"><span className="h5 font-weight-bold mb-0">{topicName}</span></Typography>
                        <Typography variant="body1" color="textSecondary">{path}</Typography>
                        <hr/>
                        <Typography variant="body1" color="textSecondary">{justification}</Typography>
                        
                    </Container>

                    {this.props.user.authenticated && this.props.user.credentials.role && (
                        <div style={{marginRight:'10px', float:'right'}}>
                        <form onSubmit={this.handleSubmitYes}>
                            <input type="hidden" value={this.state.topicName}/>
                            <input type="hidden" value={this.state.value}/>
                            <ButtonM type="submit" variant="contained" color="primary" disableElevation style={{borderRadius: '30px', padding: '7px 12px', outline:'none'}} className={classes.bottomButtonLeft}>
                                <DoneIcon color='white'/>
                                <Typography style={{marginLeft:'3px'}}>
                                    <Box fontWeight={500} fontSize={15}>
                                        Accept
                                    </Box>
                                </Typography>
                            </ButtonM>
                        </form>
                        
                        
                            <form onSubmit={this.handleSubmitNo}>
                            <input type="hidden" value={this.state.topicName}/>
                            <input type="hidden" value={this.state.value}/>
                            <ButtonM type="submit" variant="outlined" color="grey" disableElevation style={{borderRadius: '30px', padding: '7px 12px', outline:'none'}} className={classes.bottomButtonRight}>
                                <BlockIcon color='white'/>
                                <Typography style={{marginLeft:'3px'}}>
                                    <Box fontWeight={500} fontSize={15}>
                                        Decline
                                    </Box>
                                </Typography>
                            </ButtonM>
                        </form>
                        </div>
                        )}
                        
                        
                        
                </CardContent>
            </Card>
        )

        return (
            <Fragment>
                {fullCard}
            </Fragment>
        )
    }
}

TopicRequest.propTypes = {
    topicRequest: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    judgeTopicRequest: PropTypes.func.isRequired,
    user: PropTypes.object
}

const mapStateToProps = state =>({
    user: state.user,
})

export default connect(mapStateToProps, {judgeTopicRequest})(withStyles(styles)(TopicRequest));
