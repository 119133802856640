import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import {Button} from 'semantic-ui-react'
import AddModerator from './AddModerator';
import DeleteTopic from './DeleteTopic';

const styles = {
    deleteButton:{
        left: '30px',
        position: 'absolute',
        top: '30px'
    }
}

export default function EditTopic(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <Fragment>
            <Button color="white" basic inverted style={{borderRadius: '30px', padding: '7px 12px'}} onClick={handleClick}>
                <Typography>             
                    <Box color="white" fontWeight={370} fontSize={14}>
                        Edit Topic
                    </Box>
                </Typography>
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock={true}
            >
                {props.changeImage}
                {props.changeBanner}
                <AddModerator closeFunction={handleClose} topicName={props.topicName}/>
                <DeleteTopic close={handleClose} topicName={props.topicName}/>
            </Menu>
        </Fragment>
    )
    
}
