import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import MyIconButton from '../../util/IconButton'
import dayjs from 'dayjs'
import {Link} from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import {connect} from 'react-redux'
import { BorderTopSharp } from '@material-ui/icons'
import noImg from '../../images/no-img.png'

const styles = (theme) =>({
    ...theme.spreadThis,
    commentImage:{
        width: 38,
        height:38,
        borderRadius: '50%',
        objectFit: 'cover'
    },
    commentData:{
        marginLeft: 20,
    },
    visibleSeparator:{
        width: '100%',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        marginBottom: 20
    },
    invisibleSeparator:{
      style:{
          borderColor: 'transparent'
      }
    }
})

class Comments extends Component {
    getThumbnailImage = (url) =>{
        if(url.indexOf('/userImages/')===-1)
        {
            return url;
        }
        let t = url.split('/userImages/')
        return t[0] + '/userImages/'+ t[1].split('.')[0] + '-1.'+t[1].split('.')[1]
    }
    render() {
        const {comments, classes} = this.props;
        
        return(
            
            <Grid container style={{marginTop:'10px'}}>
                {comments.map((item, index)=>{
                    const { createdAt, body, userImage, userHandle, userName} = item;
                    return (
                        <Fragment key={createdAt}>
                                    <Grid container wrap="nowrap" spacing={2} style={{padding: '3px 10px', marginTop: '4px'}}>
                                    <Grid item>
                                        <img src={this.getThumbnailImage(userImage)} title="Profile image" className={classes.commentImage}
                                        onError={e=>{e.target.src=noImg}}
                                        />

                                    </Grid>
                                    <Grid item xs={12} style={{width: '100%'}}>
                                        <Typography to={'/users/'+userHandle}>
                                            <Box fontSize="15" fontWeight="600" style={{float: 'left'}}>
                                            {userName}
                                            </Box>
                                            <Box fontSize="15" fontWeight={400} style={{float: 'left', marginLeft: '10px'}}>
                                                {dayjs(createdAt).format('MMMM DD YYYY')}
                                            </Box>
                                            <br/>
                                        </Typography>

                                        <Typography>
                                            <Box fontSize="15" fontWeight="400">
                                                {body}
                                            </Box>
                                        </Typography>
                                    </Grid>
                            </Grid>
                        </Fragment>
                    )
                })}
            </Grid>
        )
        
    }
}

Comments.propTypes = {
    comments: PropTypes.array.isRequired
}

export default (withStyles(styles)(Comments))
