import React, { Fragment, Component, createRef} from 'react'
import PropTypes from 'prop-types'

import Post from '../components/post/Post';
import PostSkeleton from '../util/PostSkeleton'
import {getPosts, getTopicRequests, getTableOfContents, resetPosts, resetDir, resetRequests, resetTopic} from '../redux/actions/dataActions'
import CreatePost from '../components/post/CreatePost'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography'
import TopicRequest from '../components/layout/TopicRequest';
import Paper from '@material-ui/core/Paper'
import TableOfContents from './TableOfContents';
import {Row, Col, Grid} from '../components/layout/Flexbox'
import { connect } from 'react-redux'
import Header from '../components/layout/Header'

import ForumIcon from '@material-ui/icons/ChatBubble';
import WhatshotIcon from '@material-ui/icons/TrendingUp';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ScrollHandler from '../util/ScrollHandler';
import Fade from 'react-reveal/Fade'


import CircularProgress from '@material-ui/core/CircularProgress';
import CornerImage from '../images/questionPattern'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1} style={{paddingTop:'10px'}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

class home extends Component {
    state={
        value:0,
        all_seen: false,
        prev_posts: -1
    }

    componentDidMount(){
        this.props.resetPosts()
        this.props.resetTopic()
        let currentTab = this.state.value;
        if(this.props.location && this.props.location.state && this.props.location.state.value){
            this.setState({value: this.props.location.state.value})
            currentTab = this.props.location.state.value
        } 

        if(currentTab==0){ this.getMorePosts(false, this.state)} 
        if(currentTab==1){ this.getMorePosts(true, this.state)} 
        if(currentTab==2) this.props.getTableOfContents();
        if(currentTab==3) this.props.getTopicRequests();    
        
    }
    
    changeData(val){ //this is being called every time you switch tabs
        let state = {prev_posts: -1, all_seen:false}

        if(val==0) this.getMorePosts(false, state);
        if(val==1) this.getMorePosts(true, state);

        if(val==2) this.props.getTableOfContents();
        if(val==3) this.props.getTopicRequests();
    }

    getMorePosts = (type, state)=>{

        if(state.prev_posts === this.props.data.posts.length){
            this.setState({all_seen: true})
            return;
        }

        this.setState({prev_posts: this.props.data.posts.length})
        let arg = "0"
        if(this.props.data.posts && this.props.data.posts.length > 0)
        {
            arg = this.props.data.posts[this.props.data.posts.length-1].postId
        }
        this.props.getPosts({id: arg}, type) //second argument is rising boolean
    }

    setLoading = (value) =>{
        this.setState({loading_new_posts: value})
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue})
        this.handleReset(newValue)
        this.changeData(newValue);
    };

    handleReset = (val) =>{
        if(val==0){
            this.props.resetPosts()
        }
        if(val==1){
            this.props.resetPosts()
        }
        if(val==2){
            this.props.resetDir()
        }
        if(val==3){
            this.props.resetRequests()
        }
        this.setState({all_seen: false, prev_posts: -1})
    }

    handleScrollAction = (type) => {
        this.getMorePosts(type, this.state)
    }

    render() {
        const {value} = this.state
        const {posts, topicRequests, loading, loading_topics} = this.props
        const {authenticated} = this.props

        let tabSection = (isMobile)=> (
            <Fragment><Fade top delay={400} distance='30px'>
                <Tabs
                    
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    
                    <Tab style={{outline:'none'}} label={<div>   
                        <Typography><Box fontWeight={700} fontSize={16}><ForumIcon style={{marginRight: '9px'}}/>{!isMobile ? "Questions":""}</Box></Typography>
                        </div>} {...a11yProps(0)} />
                    <Tab style={{outline:'none'}} label={<div>   
                        <Typography><Box fontWeight={700} fontSize={16}><WhatshotIcon style={{marginRight: '9px'}}/>{!isMobile ? "Popular":""}</Box></Typography>
                        </div>} {...a11yProps(1)} />
                    
                    <Tab style={{outline:'none'}} label={<div>   
                        <Typography><Box fontWeight={700} fontSize={16}><BookmarkIcon style={{marginRight: '9px'}}/>{!isMobile ? "Table of Contents":""}</Box></Typography>
                        </div>} {...a11yProps(2)} />

                    {(authenticated && this.props.user && this.props.user.credentials.role && this.props.user.credentials.role==='creator') && (<Tab style={{outline:'none'}} label={<div>   
                        <Typography><Box fontWeight={700} fontSize={16}><CheckBoxIcon style={{marginRight: '9px'}}/>{!isMobile ? "Requests":""}</Box></Typography>
                        </div>} {...a11yProps(3)} />)}
                    
                    
                    
                    
                </Tabs></Fade>
            </Fragment>
            
        )

        let postMarkups = loading && posts===null ? ( 
            <PostSkeleton/>
        ) : (
            <Row>
                <Col size={1} collapse="sd">
                    {posts.map(post => <Post key={post.postId} post={post}/>)}
                </Col>
                <Col size={1} collapse="dd">
                    {posts.map(post => <Post key={post.postId} post={post} width="narrow"/>)}
                </Col>
            </Row>
            );

        let topicRequestMarkups = !loading_topics ? (
            topicRequests.map(topicR =>
                <TopicRequest key={topicR.topicName} topicRequest={topicR}/>
            )
        ) : null

        let t = <TableOfContents/>
        

        let tabContents = (

            <div style={{minHeight:'800px'}}>

                <TabPanel value={value} index={0}>
                    
                    <ScrollHandler onBottom={()=>{this.handleScrollAction(false)}}/>
                        {postMarkups} 
                    <div style={{height:'150px', textAlign: 'center', marginTop: '70px'}}>
                        {loading && (<CircularProgress size={30} color="primary"/>)}
                    </div>
                    
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <ScrollHandler onBottom={()=>{this.handleScrollAction(true)}}/>
                        {postMarkups} 
                    <div style={{height:'150px', textAlign: 'center', marginTop: '70px'}}>
                        {loading && (<CircularProgress size={30} color="primary"/>)}
                    </div>

                </TabPanel>

                <TabPanel value={value} index={2} style={{padding: '0px'}}>
                    {t}
                </TabPanel>

                <TabPanel value={value} index={3}>
                    {topicRequestMarkups}
                </TabPanel>

            </div>
        )

        


        return (
            <div style={{position:'relative'}}>

                <div style={{background:'linear-gradient(102deg, rgb(8 11 19), rgb(8 11 19), rgb(13 21 80), rgb(1 14 49))', width: '100vw', overflow: 'hidden', paddingLeft:'12%', paddingRight:'12%', paddingTop:'120px', marginTop:'-80px', paddingBottom:'60px', position:'relative'}}>
                    <div style={{float:'right', position:'absolute', top:'-40px', right:'calc(21% - 120px)'}}>
                        <CornerImage/>
                    </div>
                    <Header header="Browse Questions" body="See what people are saying and maybe ask some questions of your own" mobile={true}/>
                    
                </div>

                
                <div style={{backgroundColor:'white', height:'118px', width: '100vw', overflow: 'hidden', padding:'0px 10px', paddingTop:'30px'}}>
                    <div className="container-noup-wider">
                        
                        <Row style={{position:'relative', width:'100%'}}>

                            <Col collapse="ld">
                                {tabSection(false)}  
                                <div style={{position:'absolute', right:'3%', top:'5px'}}>
                                    <CreatePost/>
                                </div>
                            </Col>

                            <Col collapse="ls" style={{paddingLeft:'10px'}}>
                                {tabSection(true)}  
                                <div style={{position:'absolute', right:'4%', top:'5px'}}>
                                    <CreatePost mobile={true}/>
                                </div>
                            </Col>
                        </Row>

                    </div>        
                </div>

                <div className="container-noup-wider">   
                    {tabContents}                   
                </div>
            
            </div>
            
            
        )
    }
}

home.propTypes={
    getPosts: PropTypes.func.isRequired,
    resetDir: PropTypes.func.isRequired,
    resetPosts: PropTypes.func.isRequired,
    resetTopic: PropTypes.func.isRequired,
    resetRequests: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    getTopicRequests: PropTypes.func.isRequired,
    getTableOfContents: PropTypes.func.isRequired,
    authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) =>({
    data: state.data,
    posts: state.data.posts,
    user: state.user,
    loading: state.data.loading,
    topicRequests: state.data.topicRequests,
    authenticated: state.user.authenticated
})

export default connect (mapStateToProps, {getPosts, getTopicRequests, resetTopic, getTableOfContents, resetPosts, resetDir, resetRequests})(home)
