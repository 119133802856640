import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import dayjs from 'dayjs'
import {Link} from 'react-router-dom'
import LikeButton from './LikeButton'
import Comments from './Comments'
import CommentForm from './CommentForm'
import {Row, Col} from '../layout/Flexbox'

//Redux stuff
import {connect} from 'react-redux'
import { getPost, clearErrors } from '../../redux/actions/dataActions'


//MUI stuff
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Image from 'react-bootstrap/Image'
import Box from '@material-ui/core/Box'
import {Button} from 'semantic-ui-react'
import ReactPlayer from 'react-player'
import PostActions from './PostActions'

import noImg from '../../images/no-img.png'

const styles =theme=> ({
    ...theme.spreadThis,
    invisibleSeperator:{
        border: 'none',
        margin: 4
    },
    profileImage:{
        maxWidth: 200,
        height:200,
        borderRadius: '50%',
        objectFit: 'cover'
    },
    dialogContent:{
        padding:20
    },
    closeButton:{
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    expandButton:{
        position: 'absolute',
        left: '90%',
        top: '30px'
    },
    spinnerDiv:{
        textAlign: 'center',
        marginTop: 50,
        marginBottom: 50
    },
    buttonBase:{
        marginBottom:20
    },
    image:{
        width: 48,
        height:48,
        borderRadius: '50%',
        objectFit: 'cover'
    },
})


const checkIsYoutube = (url) =>{
    return url.includes("youtube.com")
}

const getYoutubeId = (url) =>{
    return url.split('?v=')[1].split('&')[0]
}

class PostDialog extends Component{
    state = {
        open: false,
        oldPath: '',
        newPath: ''
    }
    componentDidMount()
    {
        if(this.props.openDialog){
            this.handleOpen();
        }
    }
    handleOpen = () =>{
        let oldPath = window.location.pathname;

        const {userHandle, postId} = this.props;
        const newPath = '/users/'+userHandle+'/post/'+postId
        window.history.pushState(null, null, newPath);

        if(oldPath===newPath) oldPath = '/users/'+userHandle

        this.setState({open: true, oldPath, newPath})
        this.props.getPost(this.props.postId) //gets the post from the server if clicked
    }
    handleClose = () =>{
        window.history.pushState(null, null, this.state.oldPath)

        this.setState({open: false})
        this.props.clearErrors()
        this.props.closeFunction()
    }
    getThumbnailImage = (url) =>{
        if(url.indexOf('/userImages/')===-1)
        {
            return url;
        }
        let t = url.split('/userImages/')
        return t[0] + '/userImages/'+ t[1].split('.')[0] + '-1.'+t[1].split('.')[1]
    }
    

    render(){
        const { classes, handle, role, post : { header, attribution, createdAt,userName, userImage, userHandle, postId, likeCount, comments, commentCount, media, tags}, UI: {loading}} = this.props
        
        let isAdmin = false;
        let isUser = false;
        let isCreator = false;

        if(userHandle===handle){
            isUser=true;
        }
        if(this.props.topic && this.props.topic.admins && this.props.topic.admins.indexOf(handle)!==-1)
        {
            isAdmin=true;
        }
        if(role==='creator'){
            isCreator = true;
        }

        const tagList = this.props.openDialog && !loading && tags && tags.map((tag)=>
            <Button as={Link} to={"/topics/"+tag} style={{borderRadius: '200px', marginBottom: '0px', float: 'right'}}>
                {tag.length > 30 ? (tag.substring(0,28)+'...'):(tag)}
            </Button>
        )

        const topHeader = (
            <Fragment>
                <Row style={{paddingBottom:'10px'}}>
                    <Col size={.1}>
                        {userImage && (<img src={this.getThumbnailImage(userImage)} title="Profile image" className={classes.image} onError={e=>{e.target.src = noImg}}/>)}
                    </Col>
                    <Col size={1} style={{paddingLeft:'10px'}}>
                        <Typography component={Link} to={'/users/'+userHandle}>
                            <Box fontSize="15" fontWeight="600" color={'black'}>
                                {userName}
                            </Box>
                            <Box fontSize="15" fontWeight="500" color={'#636363'}>
                                {dayjs(createdAt).format('h:mm a, MMM DD YYYY')}
                            </Box>
                        </Typography>
                    </Col>
                    <Col collapse="ld" size={.1}>
                        {(isUser || isAdmin || isCreator) && (<PostActions postId={postId} post={this.props.post} close={this.handleClose} isUser={isUser} isCreator={isCreator} isAdmin={isAdmin} topic={this.props.topic ? (this.props.topic.topicName):null}/>)}
                        {tagList}
                    </Col>
                </Row>
                <Row>
                    <Col collapse="ls">
                        {tagList}
                    </Col>
                </Row>
            </Fragment>
        )

        const dialogMarkup = loading ? (
            <div className={classes.spinnerDiv}>
                <CircularProgress size={100} thickness={2}/>
            </div>
            
        ):(
            <div>
                <div>
                    {topHeader}
                    <hr/>
                </div>
                <div>
                    <Typography>
                        <Box fontSize={20} fontWeight={600} color="#242424">
                            {header}
                        </Box>
                        <Box fontSize={12} fontWeight={500} fontStyle='italic'>
                            {attribution && (<span>Attributed to: {attribution}</span>)}
                        </Box>
                        <br/>
                        {this.props.post.body ? (
                        <Box fontSize={16} fontWeight={400} color="#3b3b3b">
                        {this.props.post.body}
                        </Box>
                        ):null}
                    </Typography>
                    
                    {media ? (
                        <div style={{width: '100%', marginTop: '10px'}}>
                        {checkIsYoutube(media) ? (
                            <ReactPlayer url={media} width='100%' controls='true'/>
                        ):(
                            <Image src={media} key={media} width='100%'/>
                        )}
                        
                        </div>
                            
                    ): null}

                </div>
                <div>
                    <Grid container style={{width: '100%', marginTop: '8px'}}>
                        <div style={{marginTop: '8px', width: '100%'}}>
                        {/* <Button.Group>
                            <Button icon="heart"/>
                            <Button icon="share"/>
                            
                        </Button.Group> */}
                        <LikeButton postId={postId}/>
                        <CommentForm postId={postId}/> 
                        </div>
                        {/* <LikeButton postId={postId}/>
                            <CommentForm postId={postId}/> */}
                    </Grid>
                    <Comments comments={comments}/> 
                </div>
                
            </div>
        )
        return (
            <Fragment>
                <Dialog open={this.state.open} disableScrollLock={true} onClose ={this.handleClose} fullWidth maxWidth="sm">
                    <DialogContent className ={classes.dialogContent}>
                        {dialogMarkup}
                    </DialogContent>

                </Dialog>
            </Fragment>
        )
    }
}

PostDialog.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    getPost: PropTypes.func.isRequired,
    postId: PropTypes.string.isRequired,
    userHandle: PropTypes.string.isRequired,
    topic: PropTypes.object,
    handle: PropTypes.string,
    post: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    openDialog: PropTypes.bool,
    closeDialog: PropTypes.func
}

const mapStateToProps = state =>({
    post: state.data.post,
    handle: state.user.credentials.handle,
    role: state.user.credentials.role,
    media: state.data.post.media,
    header: state.data.post.header,
    topic: state.data.topic,
    UI: state.UI,
    body: state.data.post.body,
})

const mapActionsToProps = {
    getPost,
    clearErrors
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(PostDialog))