import React, { Component, Fragment } from 'react'
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

//MUI stuff
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {MenuItem, Menu} from '@material-ui/core'
import Notifications from './Notifications'
//Icons
import { withRouter, Redirect } from "react-router-dom"
import {toggleSidebar, logoutUser} from '../../redux/actions/userActions'
import SearchBar from './SearchBar'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import LoginIcon from '@material-ui/icons/VpnKey';

import withStyles from '@material-ui/core/styles/withStyles'
import {Grid, Row, Col} from './Flexbox'
import {Button, Icon} from 'semantic-ui-react'

import Logo from '../../images/icons/logoWhite.png'
import compendium from '../../images/icons/compendiumWhite.png'
import ProfileCorner from '../profile/ProfileCorner'
import WordButton from './WordButton'
import './Navbar.css'
import AboutButton from './AboutButton'
import LogoWord from '../../images/logoWord'

const styles ={
    icon:{
        fill:'white',
        margin:'-5px 0px 0px 0px',
        svg: {
            color: 'white'
        }
    },
    searchBackground:{
        backgroundColor: '#f0f1f3',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: '0 4px 4px rgba(0,0,0,0.2), 0 4px 5px rgba(0,0,0,0.34)',
         },
    },
    leftpane: {
        width: '15%',
        height: '100%',
        float: 'left',
        position: 'relative',
    },
    middlepane: {
        width: '80%',
        height: '100%',
        float: 'left',
        position: 'absolute',
        top: '20%',
        left: '15%'
    },
    rightpane: {
      width: '100%',
      height: '100%',
      float: 'right',
    },
    button:{
        backgroundColor: 'transparent',
        color: 'white'
    },
    menuContainer:{
        position: 'absolute',
        float: 'right',
        top: '92%',
        right: '1%',
        textAlign:'right'
    },
    collapsedMenuButton:{
        margin: '-6px 0px'
    }
}


class Navbar2 extends Component {
    state = {
        isMobile: false,
        expanded: false,
        home: false,
        anchorEl: null,
      }
    toggleExpanded = (event) => {
        console.log("toggled")
        this.setState({expanded: !this.state.expanded})
        console.log(event.currentTarget)
        this.setState({anchorEl: event.currentTarget})
    }
    handleLogout = () =>{
        this.props.logoutUser();
    }

    changeHomeScreen = (val)=>{
        this.setState({home: val})
    }

    componentDidMount(){
        //listens for any route changes
        this.props.history.listen(() => {
            console.log(window.location.pathname)
            if(window.location.pathname === '/'){
                this.changeHomeScreen(true)
            }
            else{
                this.changeHomeScreen(false)
            }
        })
    }

    render() {
        const {isMobile} = this.state
        const { classes, authenticated, user: {credentials: {handle}}} = this.props

        let profileRedirect="/home"
        if (this.props.user && this.props.user.credentials) {
            profileRedirect = "/users/"+handle
        }

        let desktopNotAuthenticated = (
            <div style={{float:'right', marginTop:'4px'}}>
                <AboutButton mainStyle={styles.button}/>
                <WordButton as='link' to='/tableOfContents' style={styles.button}>Table of Contents</WordButton>
                <WordButton as='link' to='/login'>Login</WordButton>    
                <WordButton as='link' to='/signup'>Signup</WordButton> 
            </div>  
        )

        let desktopAuthenticated = (
            <div style={{float:'right', marginTop:'4px'}}>
                <AboutButton mainStyle={styles.button}/>
                <WordButton as='link' to='/tableOfContents' style={styles.button}>Table of Contents</WordButton>
                <Notifications expanded={false}/>   
                <ProfileCorner/>  
            </div>  
        )

        let mobileAuthenticated = (
            <Fragment>
                <MenuItem onClick={this.toggleExpanded} component={Link} to="/about" className={classes.collapsedMenuButton}>
                    <QuestionAnswerIcon style={{marginRight: '10px'}}/> 
                    About
                </MenuItem>
                <MenuItem onClick={this.toggleExpanded} component={Link} to="/tableOfContents" className={classes.collapsedMenuButton}>
                    <BookmarkIcon style={{marginRight: '10px'}}/> 
                    Table of Contents
                </MenuItem>
                <MenuItem onClick={this.toggleExpanded} component={Link} to={"/users/"+handle} className={classes.collapsedMenuButton}>
                    <AccountCircleIcon style={{marginRight: '10px'}}/> 
                    Profile
                </MenuItem>
                <MenuItem onClick={this.toggleExpanded} onClick={this.handleLogout} component={Link} className={classes.collapsedMenuButton}>
                    <KeyboardReturnIcon style={{marginRight: '10px'}}/> 
                    Logout
                </MenuItem>
            </Fragment>
        )

        let mobileNotAuthenticated = (
            <Fragment>
                <MenuItem onClick={this.toggleExpanded} component={Link} to="/about" className={classes.collapsedMenuButton}>
                    <QuestionAnswerIcon style={{marginRight: '10px'}}/> 
                    About
                </MenuItem>
                <MenuItem onClick={this.toggleExpanded} component={Link} to="/tableOfContents" className={classes.collapsedMenuButton}>
                    <BookmarkIcon style={{marginRight: '10px'}}/> 
                    Table of Contents
                </MenuItem>
                <MenuItem onClick={this.toggleExpanded} component={Link} to="/login" className={classes.collapsedMenuButton}>
                    <LoginIcon style={{marginRight: '10px'}}/> 
                    Login
                </MenuItem>
                <MenuItem onClick={this.toggleExpanded} component={Link} to="/signup" className={classes.collapsedMenuButton}>
                    <EmojiPeopleIcon style={{marginRight: '10px'}}/> 
                    Signup
                </MenuItem>
            </Fragment>

        )

        return (
            <AppBar position="relative" elevation={0} style={{backgroundColor:"transparent", marginTop: '10px', width: '100vw'}}>
                <Toolbar style={{width: '100%', marginTop: '10px', padding: '0px', paddingLeft: '10px'}} className="container-noup-wider">
                    <Row>
                        <Col size={1.5}>
                            <Row>   
                                <Col size={.4}>
                                    <Link to='/' style={{float: 'left', margin: '10px 50px 10px 0px'}}>
                                        <LogoWord/>
                                    </Link>
                                </Col>
                                <Col size={1} collapse="md" style={{maxWidth:'80px'}}>
                                    <img src={compendium} width="68px" style={{'marginTop':'32px', 'marginLeft':'-40px'}}/>
                                </Col>
                            </Row> 
                        </Col>
                        
                        <Col size={1.6}>
                            <SearchBar/>
                        </Col>

                        {/* logic for switching desktop/mobile menus */}
                        <Col size={2.4} collapse="d" style={{marginLef: '30px'}}>
                            {authenticated ? (desktopAuthenticated):(desktopNotAuthenticated)}
                        </Col>
                        <Col size={1.6} collapse="s" style={{marginLeft:'30px'}}>
                            <Button icon="bars" aria-controls="basic-menu"
                                size="huge" onClick={this.toggleExpanded} style={{backgroundColor: 'transparent', marginTop: '2px', float: 'right', marginLeft: '-20px', color:'white'}}/>
                            <Menu
                                id="basic-menu"
                                anchorEl={this.state.anchorEl}
                                open={this.state.expanded}
                                onClose={this.toggleExpanded}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                {authenticated ? (mobileAuthenticated):(mobileNotAuthenticated)}
                            </Menu>
                        </Col>
                    </Row>    
                </Toolbar>
            </AppBar>
        )
    }
}

Navbar2.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = state =>({
    authenticated: state.user.authenticated,
    handle: state.user.credentials.handle,
    user: state.user,
})

export default withRouter(connect(mapStateToProps, {toggleSidebar, logoutUser})(withStyles(styles)(Navbar2)));
