import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'

//MUI Stuff
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TinyLogo from '../images/jsx/tinyLogo'
//REDUX stuff
import {connect} from 'react-redux';
import {loginUser, loginGoogleUser} from '../redux/actions/userActions'
import GoogleLogin from 'react-google-login';
import GoogleG from '../images/googleG2.png'
import Box from '@material-ui/core/Box'
import axios from 'axios'
import Text from '../components/layout/Text'

const styles = (theme) =>({
    ...theme.spreadThis,
    card: {
        width: '360px',
        height: '640px',
        padding: '15px 60px',
        textAlign: 'center',
        borderRadius: '16px'
    },
    cover: {
        width: 351,
        height: '100%'
      },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '400px'
    },
    forgotPasswordButton:{
        color:'rgb(48 70 245)',
        float:'right',
        fontSize:'10px',
        fontWeight: '700'
    }
})

export class login extends Component {
    constructor(){
        super();
        this.state = {
            email: '',
            password:'',
            errors: {},
            reset:false,
            response:""
        }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({errors: nextProps.UI.errors});
        }
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        }
        this.props.loginUser(userData, this.props.history);
    };

    handleForgotSubmit = (event)=>{
        event.preventDefault();
        const data = {
            email: this.state.email
        }

        axios.post('/forgotPassword', data) //this gets the posts, topic requests, etc
        .then(res=>{
            this.setState({response:"Email sent if this account exists"})
        })
        .catch(err => {
            console.log(err)
        })
    }

    handleGoogleSubmit = (res)=>{
        console.log('login success: currentUser:',res.profileObj)
        console.log(res)
        const resData = {
            token: res.tokenId,
            profileObj: res.profileObj
        }
        this.props.loginGoogleUser(resData, this.props.history)
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        });
    };

    toggleStage = ()=>{
        this.setState({reset:!this.state.reset, response:''})
    }
    render() {
        const {classes, UI: {loading}} = this.props;
        const {errors} = this.state;
        return (
            <div style={{position: 'relative', width: '100vw', height: '100vh'}}>
            <div class="background-image-full"></div>

            <div className="log-in-container">

            <Card className={classes.card}>
                <CardContent className={classes.content}>

                    {!this.state.reset ? (
                        //   THIS IS FOR LOGGING IN
                        
                        <form noValidate onSubmit={this.handleSubmit} style={{'width':'100%', padding: '20px 0px'}}>
                        
                        <TinyLogo/>

                        <Text color='#333333' fontSize={27} fontWeight={500} style={{marginTop:'50px', marginBottom:'8px'}}>
                            Welcome back!
                        </Text>

                        <TextField id="email" name="email" label="Email" className={classes.textField}
                            value={this.state.email} helperText={errors.email} error={errors.email ? true: false} onChange={this.handleChange} fullWidth/>

                        <TextField id="password" name="password" type="password" label="Password" className={classes.textField}
                                                value={this.state.password} helperText={errors.password} error={errors.password ? true: false} onChange={this.handleChange} fullWidth/>

                        <Button className={classes.forgotPasswordButton} color="primary" onClick={this.toggleStage}>
                            Forgot Password?
                        </Button>

                        {errors.general && (
                            <Typography variant="body2" className={classes.customError} style={{marginTop:'30px'}}>
                                {errors.general}
                            </Typography>
                        )}

                        {errors.error && (
                            <Typography variant="body2" className={classes.customError} style={{marginTop:'30px'}}>
                                {errors.error == "auth/user-not-found"?("Email not registered to an account"):(errors.error)}
                            </Typography>
                        )}

                        <Button type="submit" variant="contained" color="primary" className={classes.wideButton} style={{outline:'none', marginTop:'40px'}} disabled={loading}>
                            Login
                            {loading && (
                                <CircularProgress size={30} className={classes.progress}/>
                            )}
                        </Button>

                        <GoogleLogin
                            clientId="764314147194-qlj9nt9rnmktj5sleh2sjt6gu2n5jmqt.apps.googleusercontent.com"
                            render={renderProps => (
                                <Button variant="contained" startIcon={<img src={GoogleG} width="30px"/>}  onClick={renderProps.onClick} style={{'width':'100%', marginTop:'20px', 'padding':'12px 0 12px 0', outline:'none', borderRadius: '40px'}}>Login with Google</Button>
                            )}                      
                            buttonText="  Login with Google"
                            onSuccess={this.handleGoogleSubmit}
                            onFailure={this.handleSubmit}
                            cookiePolicy={'single_host_origin'}
                        >

                        </GoogleLogin>
                        
                        <Text fontSize={10} style={{marginTop:'20px'}}>
                            Don't have an account? Sign up <Link to="/signup">here</Link>
                        </Text>         
                        
                    </form>
                    ):(
                        //   THIS IS FOR RESETTING PASSWORD

                        <form noValidate onSubmit={this.handleForgotSubmit} style={{'width':'100%', marginTop:'60%'}}>
                            {this.state.response==='' ? (
                                <TextField name="email" type="email" label="Email" className={classes.textField}
                                value={this.state.email} helperText={errors.email} error={errors.email ? true: false} onChange={this.handleChange} fullWidth/>
                            ):(
                                <Typography>
                                    <Box>
                                        {this.state.response}
                                    </Box>
                                </Typography>
                            )}

                            {this.state.response==='' && (
                            <Button type="submit" variant="contained" color="primary" className={classes.wideButton} style={{outline:'none'}} disabled={loading}>
                                Send Email
                                {loading && (
                                    <CircularProgress size={30} className={classes.progress}/>
                                )}
                            </Button>)}

                            <br/>
                            <Button className={classes.signup} onClick={this.toggleStage}>Go Back</Button>
                        </form>
                    )}
                    
            </CardContent>
        </Card>

        </div>
        </div>
        )
    }
}

login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
}

const mapStateToProps = (state)=>({
    user: state.user,
    UI: state.UI,

})

const mapActionsToProps = {
    loginUser,
    loginGoogleUser
}
export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(login));
