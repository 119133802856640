import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import {Link} from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MuiLink from '@material-ui/core/Link'
import { connect } from 'react-redux'
import {Button} from 'semantic-ui-react'
import WordButton from '../layout/WordButton'
import {Menu, MenuItem} from '@material-ui/core'

import LogoutUser from './LogoutUser'

const styles = (theme) => ({
    ...theme.spreadThis,
    image:{
        width:30,
        height:30,
        marginLeft: '4px',
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",

    },
    loadingImage:{
        width:30,
        height:30,
        marginLeft: '4px',
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
        backgroundColor: 'white'

    },
    paper: {
        margin:'-4px',
        background: 'linear-gradient(-15deg, #3046f5ff 40%, #3076f5ff 100%)',
        borderRadius: '400px',
        display: 'blox',
        color: 'white',
        padding: '4px 6px',
    },
    grid:{
        margin:'5px',
        float: 'left'
    }
});

class ProfileCorner extends Component{
    state={
        anchorEl:null
    }
    redirectToTarget = (handle) => {
        this.props.history.push('/users/'+handle)
      }
      getThumbnailImage = (url) =>{
        if(url.indexOf('/userImages/')===-1)
        {
            return url;
        }
        let t = url.split('/userImages/')
        return t[0] + '/userImages/'+ t[1].split('.')[0] + '-1.'+t[1].split('.')[1]
    }
    handleOpen = (event) =>{
        this.setState({anchorEl: event.target})
    }

    handleClose = () =>{
        this.setState({anchorEl: null})
    }

    render(){
        const { classes, user: {
            credentials: {handle, name,imageUrl}}} = this.props
        

        return(
            <span style={{minWidth:'80px'}}>
            <WordButton onClick={this.handleOpen}>
                {imageUrl ? (<img src={this.getThumbnailImage(imageUrl)} width="40" height="40" alt="profile" className={classes.image}/>  ):(
                    null
                )}
                    {/* <Paper className={classes.paper} className={classes.paper}> */}
                        <div style={{margin: '4px',marginLeft: '9px', float: 'right'}}>

                            {name ? ( name):( handle)}

                        </div>
                        {/* </Paper> */}
            </WordButton>

            <Menu 
                disableScrollLock={true}
                anchorEl = {this.state.anchorEl}
                open = {Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
                onEntered={this.onMenuOpened}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                PaperProps={{
                style: {
                    marginTop:'50px',
                    width:'200px'
                }
                }}
                >
                    <MenuItem as={Link} component={Link} to={"/users/"+handle}>
                        View Profile
                    </MenuItem>
                    <LogoutUser/>
                </Menu>
            </span>
        )
    }
    
}

const mapStateToProps = (state) =>({
    user: state.user
})
ProfileCorner.propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(ProfileCorner))