import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {Link} from 'react-router-dom';

import {connect} from 'react-redux'
import { createTopicRequest, clearErrors} from '../../redux/actions/dataActions'

import { Form, FormGroup, FormFeedback, Label, Input, FormText } from 'reactstrap';
import {Button} from 'semantic-ui-react'
import ButtonM from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress'
import FolderIcon from '@material-ui/icons/Folder';
import {List, ListItem, ListItemText, ListItemIcon} from '@material-ui/core'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { CreateNewFolder} from '@material-ui/icons';
import axios from 'axios'
import IconButton from '@material-ui/core/IconButton'
import Text from '../layout/Text'
import AddCircleOutlineIcon from '@material-ui/icons/Add';
//import Image from 'material-ui-image'

const styles=(theme)=>({
    ...theme.spreadThis,
    button:{
        float:'right',
    },
    root:{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    uploadButton:{
        width:'1.5em',
        height:'1.5em',
        marginLeft:'10px',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            transform: 'scale(1.2)',
            backgroundColor:'transparent'
          }
    },
    textField:{
        height:'50px',
        width:'100%',
        marginTop:'4px'
    },
    buttonFull:{
        backgroundColor:'transparent',
        minWidth: 140,
        fontSize:14,
        fontWeight:500,
        textTransform: 'none',
        marginTop:'4px',
        boxShadow:'none',
        color:'#ffffff73',
        textDecoration: 'none',
        borderRadius:'40px',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            transform: 'scale(1.03)',
            color:'white',
          }
    },
    submitButton:{
        backgroundColor:'#3046f5',
        fontSize:14,
        fontWeight:400,
        padding: '4px 14px',
        textTransform: 'none',
        boxShadow:'none',
        color:'white',
        textDecoration: 'none',
        borderRadius:'40px',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            transform: 'scale(1.03)',
            backgroundColor:'#3046f522',
          }
    },
    cancelButton:{
        backgroundColor:'#bbbbbba1',
        fontSize:14,
        fontWeight:400,
        padding: '4px 14px',
        textTransform: 'none',
        boxShadow:'none',
        color:'#040d21',
        textDecoration: 'none',
        borderRadius:'40px',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            transform: 'scale(1.03)',
            backgroundColor:'#3046f522',
          }
    }
})

class CreateTopic extends Component {
    state={
        expanded: false,
        topicName: '',
        justification: '',
        waiting: false,
        loading_table: false,
        selected:'',
        writing:false,
        newFolder:'',
        directory: []
    }
    handleOpen = ()=>{
        this.props.clearErrors();
        this.setState({expanded: true})
        this.getTableOfContents()
    }
    handleClose = () =>{
        this.setState({expanded: false})
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        });
    };

    onSubmitForm = e =>{
        console.log("sending the form")
        e.preventDefault()
        const formData = new FormData(e.target)
        const topicRequest = {}
        formData.forEach((value, property) => topicRequest[property] = value)
        topicRequest.path = this.state.path

        this.props.createTopicRequest(topicRequest)
        this.state.waiting = true;
    }

    getTableOfContents = () =>{
        this.setState({loading_table: true})
        axios.get('/tableOfContents') //this gets the table of contents
            .then(res=>{

                let dir = []
                for (var i = 0; i < res.data.directory.length; i++) {
                    if(!res.data.directory[i].includes('/')) continue;
                    let numSpaces = this.countTabs(res.data.directory[i])
                    dir.push([res.data.directory[i], numSpaces])
                  }
                  
                this.setState({directory: dir, loading_table: false})
            })
            .catch(err => {
                console.log(err)
                this.setState({loading_table:false, success_message: ''})
            })
        
    }

    countTabs = (word) =>{
        for (var i = 0; i < word.length; i++) {
            if (word.charAt(i)!=='\t')
                return i;
          }
        return 0
    }

    addFolderInput = (entry) =>{
        this.setState({writing:true, newFolder:''})
    }

    handleSelected = (entry) =>{
        if(entry!==this.state.selected){
            this.setState({writing:false, newFolder:''})
        }
        this.setState({selected: entry})
        this.getPath(entry, this.state.topicName);
    }

    handleChange = (event) =>{
        this.setState({[event.target.name]: event.target.value})
        if(event.target.name==='topicName')
        {
            this.getPath(this.state.selected, event.target.value)
        }
    }

    getIndex = (search) =>{
        for(var i=0;i<this.state.directory.length;i++)
        {
            if(this.state.directory[i][0]===search){
                return i;
            }
        }
        return -1;
    }

    createFolder = () =>{
        let index = this.getIndex(this.state.selected)
        let t = this.state.directory[index][1]

        let newFolder = ''
        for (var i = 0; i < t+1; i++) {
            newFolder+='\t'
          }
        let word = newFolder + '/'+ this.state.newFolder

        this.state.directory.splice(index+1, 0, [word, t+1])
        this.setState({selected: word, writing:false})
        this.getPath(word, this.state.topicName)
    }

    checkKey = (event)=>{
        if(event.key==='Enter')
        {
          this.createFolder();
        }
      }

    getPath = (entry, name) =>{
        let index = this.getIndex(entry)
        let prevSpaces = this.countTabs(entry)

        let path = entry.trim().replace('/','') + '/' + name.trim()
        for (var i = index; i >= 0; i--) {
            let numSpaces = this.state.directory[i][1]
            if(numSpaces < prevSpaces){
                prevSpaces = numSpaces;
                path = this.state.directory[i][0].trim().replace('/','') + '/'+ path
            }
          }
        console.log('setting the path')
        this.setState({path: path})
    }

    render() {
        const {classes, UI: {loading, errors}} = this.props;
        
        if(this.state.waiting && !loading && (errors===null || errors==={})){
            this.state.waiting = false;
            this.handleClose()
        }

        let tableOfContentsMarkups = !this.state.loading_table ? (
            
            this.state.directory.map((entry, index)=>
 
                        <Fragment>

                        
                        <ListItem button onClick={()=>{this.handleSelected(entry[0])}} style={{paddingLeft:this.state.directory[index][1]*3+'%', backgroundColor: entry[0]===this.state.selected ? '#b1d5f7': 'transparent'}}>
                        <ListItemIcon style={{minWidth:'26px', marginLeft:'16px', color: entry[0]===this.state.selected ? 'rgb(101 171 220)': 'rgb(121 121 121 / 41%)'}}>
                            <FolderIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            {entry[0].replace('/', '')}
                        </ListItemText>
                        {entry[0]===this.state.selected && (
                            <IconButton className={classes.uploadButton} style={{outline:'none'}} onClick={()=>{this.addFolderInput(entry[0])}}>
                            <AddCircleRoundedIcon/>
                            </IconButton>
                        )}
                        
                        </ListItem>
                        {(this.state.writing && this.state.selected===entry[0]) && (
                            <ListItem style={{paddingLeft:this.state.directory[index][1]*6+'%', backgroundColor: 'transparent'}}>
                                <TextField className={classes.textField} name="newFolder" onKeyPress={this.checkKey} onChange={this.handleChange} id="outlined-basic" label="New Folder" variant="outlined" />
                            </ListItem>
                        )}
                        </Fragment>

            )
                        
        ) : null

        return (
            <Fragment>
            
            {this.props.authenticated ? (
                <ButtonM className={classes.buttonFull} onClick={this.handleOpen}>
                    <CreateNewFolder style={{marginRight:'8px',marginTop:'-4px'}}/>
                    Request Topic
                </ButtonM>
            ):(
                <ButtonM className={classes.buttonFull} component={Link} to="/login">
                    <CreateNewFolder style={{marginRight:'8px',marginTop:'-4px'}}/>
                    Request Topic
                </ButtonM>
            )}
            

            <Dialog open={this.state.expanded}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm">
                <DialogTitle>Request Topic</DialogTitle>
                <DialogContent>
                    <Form style={{paddingBottom:'10px'}} onSubmit={e => this.onSubmitForm(e)}>
                        <FormGroup style={{width:'100%'}}>
                            <Label for="topicName">Topic Name</Label>
                            <Input invalid={errors && errors.topicName} name="topicName" type="text" id="topicName" placeholder="..." className={classes.TextField} value={this.state.topicName} onChange={this.handleChange} fullWidth/>

                            {errors && errors.topicName &&
                            (
                                <FormFeedback>{errors.topicName}</FormFeedback>
                            )}
                        
                        </FormGroup>
                        <FormGroup>
                            <FormText color="muted">Select/Add the directory in which this topic should reside</FormText>

                            {errors && errors.path &&
                            (
                                <FormFeedback>{errors.path}</FormFeedback>
                            )}
                        </FormGroup>

                        <div style={{background: '#efefef'}}>
                        <List className={classes.root}>
                            {tableOfContentsMarkups}
                        </List>
                        
                        </div>
                        <span>Path: {this.state.path}</span>
                        


                        <FormGroup style={{marginTop:'20px'}}>
                            <Label for="justification">Justification</Label>
                            <Input name="justification" type="textarea" rows={3} id="justification" placeholder="Why you should be responsible for this topic" className={classes.TextField} value={this.state.justification} onChange={this.handleChange} fullWidth/>
                            {errors && errors.error &&
                            (
                                <FormFeedback>{errors.error}</FormFeedback>
                            )}
                        </FormGroup>

                        <ButtonM onClick={this.handleClose} className={classes.cancelButton} style={{outline:'none'}} color="primary">Cancel</ButtonM>
                        <ButtonM type="submit" className={classes.submitButton} style={{outline:'none', marginLeft:'5px'}}>Submit
                        {loading && (
                            <CircularProgress size={30} className={classes.progress}/>
                        )}
                        </ButtonM>
                    </Form>

                </DialogContent>
            </Dialog>
        </Fragment>
        )
    }
}

CreateTopic.propTypes = {
    createTopicRequest: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state)=>({
    UI: state.UI,
    errors: state.UI.errors,
    authenticated: state.user.authenticated,
})

export default connect(mapStateToProps, {createTopicRequest, clearErrors})(withStyles(styles)(CreateTopic))