import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import MyIconButton from '../../util/IconButton'
import PropTypes from 'prop-types'

import MenuItem from '@material-ui/core/MenuItem'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

import {connect} from 'react-redux'
import {deleteTopic } from '../../redux/actions/dataActions'
import DeleteIcon from '@material-ui/icons/Delete';

const styles = {
    deleteButton:{
        left: '30px',
        position: 'absolute',
        top: '30px',
        fontWeight: '500'
    },
    menu:{
        fontWeight:'500'
    }
}

class DeleteTopic extends Component {
    state = {
        open: false
    }
    handleOpen = () =>{
        this.setState({open: true})
    }
    handleClose= ()=>{
        
        this.setState({open: false})
    }
    deleteTopic = ()=>{
        this.props.close()
        this.props.deleteTopic({topicName: this.props.topicName})
        console.log('now lets close it')
        this.setState({open:false})
        this.props.history.push('/feed')
    }
    render() {
        const {classes} = this.props;

        return (
            <Fragment>
                <MenuItem tip="Delete Topic" onClick={this.handleOpen} className={classes.menu}>
                    <DeleteIcon style={{marginRight:'10px'}}/>
                    Delete
                </MenuItem>

                <Dialog open={this.state.open} maxWidth="sm">
                    <DialogTitle>
                        Are you sure you want to delete this topic?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} style={{outline:'none'}} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.deleteTopic} style={{outline:'none'}} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

DeleteTopic.propTypes = {
    deleteTopic: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    postId: PropTypes.string.isRequired
}

export default connect(null, {deleteTopic})(withStyles(styles)(DeleteTopic))
