import {SET_POSTS, CREATE_TOPIC_REQUEST, LOADING_DATA, LIKE_POST, UNLIKE_POST, DELETE_POST, RESET_TOPIC, EDIT_POST, LOADING_TOPICS, SET_ERRORS, CLEAR_ERRORS, CREATE_POST, LOADING_UI, SET_POST, STOP_LOADING_UI, SUBMIT_COMMENT, SET_TOPIC, SET_TOPIC_REQUESTS, SET_TABLE_OF_CONTENTS, JUDGE_REQUEST, RESET_POSTS, RESET_DIR, RESET_REQUESTS} from '../types'
import axios from 'axios'
import { ConsoleView } from 'react-device-detect'

export const getPosts = (id, rising) => (dispatch) => {
    dispatch({type: LOADING_DATA})
    let string = '/posts'
    if(rising)
    {
        string = '/rising'
    }
    axios.post(string, id)
        .then(res=>{
            dispatch({
                type: SET_POSTS,
                payload: res.data
            })
        })
        .catch(err=>{
            dispatch({
                type: SET_POSTS,
                payload: []
            })
        })
}

export const getMoreTopicPosts = (id, topicName, rising) =>(dispatch)=>{
    dispatch({type: LOADING_DATA})
    let string = '/topicMore/'+topicName
    if(rising)
    {
        string = '/topic/'+topicName+'/rising'
    }
    axios.post(string, id)
        .then(res=>{
            dispatch({
                type: SET_POSTS,
                payload: res.data
            })
        })
        .catch(err=>{
            dispatch({
                type: SET_POSTS,
                payload: []
            })
        })
}

export const getMoreUserPosts = (id, user) =>(dispatch)=>{
    dispatch({type: LOADING_DATA})
    let string = '/userPosts/'+user

    axios.post(string, id)
        .then(res=>{
            console.log(res)
            dispatch({
                type: SET_POSTS,
                payload: res.data
            })
        })
        .catch(err=>{
            console.log(err)
            dispatch({
                type: SET_POSTS,
                payload: []
            })
        })
}

export const likePost = (postId) => (dispatch) =>{
    axios.get('/post/'+postId+'/like')
        .then(res=>{
            dispatch({
                type: LIKE_POST,
                payload: res.data
            })
        })
        .catch(err=>{
            console.log(err);
        })
}

export const unlikePost = (postId) => (dispatch) =>{
    axios.get('/post/'+postId+'/unlike')
        .then(res=>{
            dispatch({
                type: UNLIKE_POST,
                payload: res.data
            })
        })
        .catch(err=>{
            console.log(err);
        })
}

export const deletePost = (postId) => (dispatch) =>{
    console.log('STARTING TO DELETE')
    axios.delete('/post/'+postId)
        .then(res=>{
            dispatch({
                type: DELETE_POST, payload: postId
            })
            console.log('just deleted')
        })
        .catch(err=> console.log(err))
}

export const deleteTopic = (post) => (dispatch) =>{
    axios.post('/deleteTopic', post)
        .then(res=>{
        })
        .catch(err=> console.log(err))
}

export const untagPost = (post) => (dispatch) =>{
    axios.post('/untag', post)
        .then(res=>{
            dispatch({
                type: DELETE_POST, payload: post.postId
            })
        })
        .catch(err=> console.log(err))
}

export const editPost = (postId, newPost) => (dispatch) =>{
    dispatch({type: LOADING_UI})
    axios.post('/post/'+postId, newPost)
        .then(res=>{
            console.log('dispatching to edit')
            dispatch({type: STOP_LOADING_UI})
            dispatch({
                type: EDIT_POST, payload: res.data
            })
        })
        .catch(err=>{
            console.log('there is an error here ')
            console.log(err)
        })
}

export const createPost = (newPost) => (dispatch) => {
    dispatch({type: LOADING_UI});
    console.log('now creating the post')
    axios.post('/post ', newPost)
        .then(res=>{
            dispatch({
                type: CREATE_POST,
                payload: res.data
            })
            dispatch(clearErrors())
        })
        .catch(err=>{
            console.log("errors with posting")
            console.log(err)
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

export const createTopicRequest = (newTopic) => (dispatch) => {
    dispatch({type: LOADING_UI});
    axios.post('/createTopic ', newTopic)
        .then(res=>{
            dispatch({
                type: CREATE_TOPIC_REQUEST,
                payload: res.data
            })
            dispatch(clearErrors())
        })
        .catch(err=>{
            console.log("errors with requesting topic")
            console.log(err)
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

export const uploadPostWithImage = (formData, newPost, edit, postId)=>(dispatch)=>{
    dispatch({type:LOADING_UI})
    console.log("starting it up")
    axios.post('/post/image', formData)
        .then(res=>{
            console.log("uploaded image as ")
            console.log(res.data.value)
            newPost.media = res.data.value
            if(edit)
            {
                dispatch(editPost(postId, newPost))
            }
            else
            {
                dispatch(createPost(newPost))
            }
            
        })
        .catch(err=> console.log(err))
}

export const getPost = (postId) => (dispatch) => {
    dispatch({type: LOADING_UI});
    axios.get('/post/'+postId)
        .then(res=>{
            dispatch({
                type: SET_POST,
                payload: res.data
            })
            dispatch({type: STOP_LOADING_UI})
        })
        .catch(err=> console.log(err))
}

export const submitComment = (postId, comment) => (dispatch) =>{
    axios.post('/post/'+postId+'/comment', comment)
        .then(res=>{
            dispatch({type: 
                SUBMIT_COMMENT, 
                payload: res.data
            })
            dispatch(clearErrors())
        })
        .catch(err=>{
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

// this gets the posts from the user
export const getUserData = (userHandle) => (dispatch)=>{
    dispatch({type: LOADING_DATA})
    axios.get('/user/'+userHandle)
        .then(res=>{
            dispatch({
                type: SET_POSTS,
                payload: res.data.posts
            })
            dispatch({
                type: SET_TOPIC_REQUESTS,
                payload: res.data.topicRequests
            })
        })
        .catch(err=>{
            dispatch({
                type: SET_POSTS,
                payload:null
            })
            dispatch({
                type: SET_TOPIC_REQUESTS,
                payload:null
            })
        })
}

//has topicName and value ==true || false
export const judgeTopicRequest = (topicName, post) => (dispatch) =>{
    console.log('sending the judge topicRequest')
    axios.post('/topicRequest', post)
        .then(res=>{
            console.log('got a response')
            dispatch({
                type: JUDGE_REQUEST,
                payload: topicName
            })
        })
}

export const getTableOfContents = () =>(dispatch)=>{
    dispatch({type: LOADING_DATA})
    axios.get('/tableOfContents')
        .then(res=>{
            dispatch({
                type: SET_TABLE_OF_CONTENTS,
                payload: res.data.directory
            })
        })
        .catch(err=>{
            dispatch({
                type: SET_TABLE_OF_CONTENTS,
                payload:null
            })
        })
}

export const getTopicRequests = () => (dispatch)=>{
    dispatch({type: LOADING_TOPICS})
    axios.get('/topicRequests')
        .then(res=>{
            dispatch({
                type: SET_TOPIC_REQUESTS,
                payload: res.data.topicRequests
            })
        })
        .catch(err=>{
            dispatch({
                type: SET_TOPIC_REQUESTS,
                payload:null
            })
        })
}

export const getTopicData = (topicName) => (dispatch)=>{
    dispatch({type: LOADING_DATA})
    axios.get('/topic/'+topicName)
        .then(res=>{
            console.log(res)
            dispatch({
                type: SET_POSTS,
                payload: res.data.posts
            })
            dispatch({
                type: SET_TOPIC,
                payload: res.data.topic
            })
        })
        .catch(err=>{
            dispatch({
                type: SET_POSTS,
                payload:null
            })
        })
}

export const clearErrors= () =>(dispatch)=>{
    dispatch({type: CLEAR_ERRORS})
}

export const resetPosts = () =>(dispatch)=>{
    dispatch({type: RESET_POSTS})
}
export const resetDir = () =>(dispatch)=>{
    dispatch({type: RESET_DIR})
}
export const resetRequests = () =>(dispatch)=>{
    dispatch({type: RESET_REQUESTS})
}

export const resetTopic = () =>(dispatch)=>{
    dispatch({type: RESET_TOPIC})
}