import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import {connect} from 'react-redux'
import { createPost, clearErrors, uploadPostWithImage, editPost} from '../../redux/actions/dataActions'

import ButtonM from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close'
import Image from 'react-bootstrap/Image'

import axios from 'axios'
import ReactPlayer from 'react-player'
import AddTag from './AddTag'
import {Button, Icon} from 'semantic-ui-react'
import WordButton from '../layout/WordButton'
import { MenuItem, Typography, Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import YouTubeIcon from '@material-ui/icons/YouTube';
import ImageIcon from '@material-ui/icons/Image';
import WrapperButton from './WrapperButton'
import CreateIcon from '@material-ui/icons/Create';
import Text from '../layout/Text'
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom';
//import Image from 'material-ui-image'
//import { sizing } from '@material-ui/system';

const styles = theme => ({
    ...theme.spreadThis,
    submitButton:{
        position: 'absolute',
        bottom:'10px',
        padding:'2px 25px',
        right:'25px',
        float: 'right'
    },
    progressSpinner:{
        position: 'absolute'
    },
    tags:{
        position: 'relative',
        float: 'left',
        marginTop:30
    },
    closeButton:{
        position: 'relative',
        float: 'right',
        marginTop:-8
    },
    buttonFull:{
        backgroundColor:'#2185d0',
        fontSize:15,
        fontWeight:600,
        letterSpacing:'.6px',
        boxShadow:'none',
        color:'white',
        borderRadius:'40px',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            transform: 'scale(1.03)',
            backgroundColor:'#3046f5',
          }
    },
    uploadButton:{
        width:'1.5em',
        height:'1.5em',
        marginTop: 6,
        marginRight: 4,
        float:'right',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            transform: 'scale(1.3)',
            backgroundColor:'white'
          }
    },
    dialogTitle:{
        padding: '8px 30px',
        paddingTop: '20px'
    },
    popupBox:{
        minWidth:'300px',
        "& MuiDialog-paperWidthSm":{
            minWidth:'300px',
        }
    }
})

class CreatePost extends Component{

    state = {
        open: false,
        topicList: [],
        body: '',
        header: '',
        youtubeOpen: false,
        image: null,
        imageFile: null,
        video: "",
        tags: [],
        suggestions: [],
        tags: [],
        attribution:'',
        attributionOpen: false,
        tag: ""
    }

    componentDidMount(){
        //get all the possible topics to pass through
        console.log('getting the topics')
        axios.get('/topics')
            .then(res=>{
                console.log('got the topics')
                this.setState({
                    topicList: res.data.topics
                })
            })
            .catch(err => console.log(err))
    
        if(this.props.edit)
        {
            this.setState({header: this.props.post.header})
            this.setState({tags: this.props.post.tags})
            if(this.props.post.body) this.setState({body: this.props.post.body})
            if(this.props.post.media){
                if(this.props.post.media.includes('youtube.com'))
                {
                    this.setState({video: this.props.post.media})
                }else{
                    this.setState({image: this.props.post.media})
                }
            } 
            if(this.props.post.attribution){
                this.setState({attribution: this.props.post.attribution})
            }
            
        }
        else{
            this.setState({
                body: '',
                header: '',
                youtubeOpen: false,
                image: null,
                imageFile: null,
                video: "",
                attribution:'',
                attributionOpen:false,
                suggestions: [],
                tags: [],
                tag: ""})
            
            if(this.props.topic)
            {
                this.setState({tags: [this.props.topic]})
            }
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({
                errors: ''
            })
        }
        console.log("props received")
        if(this.state.video!=="")
        {
            console.log("now we are calling it")
            console.log("")
        }
        if(!nextProps.UI.errors && !nextProps.UI.loading)
        {
            if(this.state.youtubeOpen){

            }
            else{
                this.setState({
                    header:'',
                    imageFile: null,
                    image: null,
                    video:'',
                    body: '',
                    open: false,
                    errors: '',
                    tag: "",
                    tags: []
                })
            }
            
        }
    }
    handleOpen = () =>{
        this.setState({open:true})
        
    }
    handleOpenTags = (tag) =>{
        this.setState({open:true, tags:[tag]})
    }
    handleClose = () =>{
        if(this.props.edit)
        {
            this.props.close()
        }
        this.props.clearErrors();
        this.setState({open:false, errors:{}, image:null, video: "", attribution:''})
    }
    handleChange = (event) =>{
        this.setState({[event.target.name]: event.target.value})
    }
    handleSubmit = (event) =>{
        event.preventDefault();
        let toSend = {header: this.state.header, tags: this.state.tags} //these are required

        if(this.state.body!==''){toSend.body=this.state.body}
        if(this.state.video!==''){toSend.media=this.state.video}
        toSend.attribution=this.state.attribution

        //handle uploading the image only if it has changed
        if(this.state.image!==null && this.state.imageFile!=null){
            const formData = new FormData();
            formData.append('image', this.state.imageFile, this.state.imageFile.name);
            this.props.uploadPostWithImage(formData, toSend, this.props.edit, this.props.post ? (this.props.post.postId): null)
        }
        else{
            if(this.props.edit)
            {
                if(this.state.video==='' && this.state.image===null && this.state.imageFile===null)
                {
                    toSend.media = ''
                }
                console.log(toSend)
                this.props.editPost(this.props.post.postId, toSend)
            }
            else{
                this.props.createPost(toSend)
            }
            
        }
    }

    handleSubmitTag = () =>{
        console.log("starting")
        if(this.state.tags.length>=3){
            this.setState({errors: 'Cannot have more than three tags'})
            return;
        }
        if(this.state.tag==='' || this.state.tag===' '|| this.state.tag==='Enter topics'){
            this.setState({errors: 'Can\'t be empty'})
            return;
        }
        if(this.state.tags.indexOf(this.state.tag)!==-1){
            this.setState({errors: 'Already tagged'})
            return;
        }
        this.setState({tags: [...this.state.tags, this.state.tag]})     
        this.setState({tag: "", errors: ''})
        console.log(this.state.tags)
        document.getElementById('tagEntry').value = ''
    }

    handleRemoveTag = (tagName) =>{
        const index = this.state.tags.indexOf(tagName)
        const tagList = this.state.tags
        tagList.splice(index, 1)
        this.setState({tags: tagList, errors: ""})
    }

    uploadImage = () =>{
        console.log("here we go")
        const fileInput = document.getElementById('imageInput3')
        fileInput.click();
        this.setState({video:''})
        
    }
    handleImageChange = (event) =>{
        const image = event.target.files[0];
        this.setState({image: URL.createObjectURL(image), imageFile: image})
    }

    openAttribution = () =>{
        this.setState({attributionOpen: true})
    }

    closeAttribution = ()=>{
        this.setState({attributionOpen: false})
    }

    handleYoutubeOpen = () =>{
        this.setState({youtubeOpen: true})
    }

    handleYoutubeClose = () =>{
        this.setState({youtubeOpen:false, image:null})
    }


    render(){
        const {image, video, attribution} = this.state;
        const {classes, expanded, UI: {loading, errors}} = this.props;

        let tagErrors = this.state.errors
        if(errors && errors.tags!=='')
        {
            tagErrors = errors.tags
        }

        return(
            <Fragment>
                    
                    {this.props.edit ? (
                        <MenuItem onClick={()=>{this.handleOpen();}}>
                            Edit
                        </MenuItem>
                    ):(
                        this.props.topic ?(
                            <ButtonM className={classes.buttonFull} onClick={()=>{this.handleOpenTags(this.props.topic)}} variant="contained" style={{outline:'none', padding: '8px 15px', textTransform:'none'}} labelStyle={{fontSize:'16px', fontWeight: 300}}>
                                <CreateIcon color='white' style={{marginRight:'12px'}}/>
                                {this.props.mobile ? ('Ask'):('Ask Question')}
                            </ButtonM>
                            
                        ):(
                            expanded ? (
                                <Button basic onClick={this.handleOpen} size="medium" >
                                    <Icon name="edit"/>
                                    <span>Create Post</span>
                                </Button>
                            ):(
                                <ButtonM className={classes.buttonFull} onClick={this.handleOpen} variant="contained" style={{outline:'none', padding: '8px 15px', textTransform:'none'}} labelStyle={{fontSize:'16px', fontWeight: 300}}>
                                    <CreateIcon color='white' style={{marginRight:'12px'}}/>
                                    {this.props.mobile ? ('Ask'):('Ask Question')}
                                </ButtonM>
                            )
                        )
                        
                    )}
                    

                
                <Dialog open={this.state.open} disableScrollLock={true} onClose ={this.handleClose} fullWidth maxWidth="sm">
                    
                    <DialogTitle className={classes.dialogTitle}>
                            <span>
                        Ask a Question
                        </span>
                            <div style={{position:'absolute', right: '20px', top:'15px'}}>
                                <Tooltip title='Add Attribution' TransitionComponent={Zoom} >
                                    <IconButton tip="Attribute" onClick={this.openAttribution} className={classes.uploadButton} style={{outline:'none'}}>
                                        <ImportContactsIcon/>
                                    </IconButton>
                                </Tooltip>
                            
                                <Tooltip title='Upload Image' TransitionComponent={Zoom} >
                                    <IconButton tip="Upload Image" onClick={this.uploadImage} className={classes.uploadButton} style={{outline:'none'}}>
                                        <ImageIcon/>
                                    </IconButton>
                                </Tooltip>
                            
                                <Tooltip title='Embed Video' TransitionComponent={Zoom} >
                                    <IconButton tip="Embed Video" onClick={this.handleYoutubeOpen} className={classes.uploadButton} style={{outline:'none'}}>
                                        <YouTubeIcon/>
                                    </IconButton>
                                </Tooltip>
                            
                            {(this.state.video || this.state.image) && (
                                <Tooltip title='Delete Media' TransitionComponent={Zoom} >
                                <IconButton className={classes.uploadButton} style={{outline:'none'}} onClick={()=>{this.setState({image:null, imageFile: null, video: ''})}}>
                                    <CloseIcon/>
                                </IconButton>
                                </Tooltip>
                            )}
                            </div>
                        

                    </DialogTitle>
                    <DialogContent>
                        
                        <input type="file" id="imageInput3" hidden="hidden" onChange={this.handleImageChange}/>
                        <form onSubmit={this.handleSubmit}>

                            <AddTag errors={tagErrors} topicList={this.state.topicList} handleRemoveTag={this.handleRemoveTag} handleChange={this.handleChange} handleSubmitTag={this.handleSubmitTag} tags={this.state.tags}/>

                            <TextField variant='outlined' name="header" type="text" label="Question" multiline rows={4} placeholder="The header" 
                            error={errors && errors.header} helperText={errors ? (errors.header):null} className={classes.textField} 
                            onChange={this.handleChange} fullWidth defaultValue={this.state.header}/>

                            <Text fontSize={12} fontWeight={500} fontStyle='italic'>
                                {attribution && (<span>Attributed to: {attribution}</span>)}
                            </Text>

                            <TextField variant='outlined' name="body" type="text" label="Body" multiline rows={4} placeholder="Say anything" 
                            error={errors && errors.body} helperText={errors ? (errors.body):null} className={classes.textField} 
                            onChange={this.handleChange} fullWidth defaultValue={this.state.body}/>


                            {this.state.image ? (
                                <div style={{marginBottom:'8px'}}>
                                    
                                    <Image src={this.state.image} width="100%">
                                    </Image>
                                </div>
                                    
                                
                            ): null}
                            {this.state.video!=="" ? (     
                                <ReactPlayer url={this.state.video} style={{marginBottom:'8px'}} width="100%"/>
                            ) : null}

                        

                            <ButtonM type="submit" variant="contained" color="primary" style={{marginBottom:'7px', marginTop:'5px', outline:'none'}} className={classes.wideButton} disabled={loading}>
                                Submit
                                {loading && (
                                    <CircularProgress size={30} className={classes.progressSpinner}/>
                                )}
                                
                            </ButtonM>
                        </form>
                        
                        
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.youtubeOpen} onClose={this.handleYoutubeClose} className={classes.popupBox}>
                    <DialogTitle style={{minWidth:'300px'}}>Youtube Link</DialogTitle>
                    <DialogContent>
                            <TextField name="video" type="text" label="URL" placeholder="https://youtube.com/video" className={classes.textField} style={{minWidth:'10%'}}
                            onChange={this.handleChange} value={video} fullWidth/>
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.attributionOpen} onClose={this.closeAttribution} className={classes.popupBox}>
                    <DialogTitle style={{minWidth:'300px'}}>Add Attribution     </DialogTitle>
                    <DialogContent>
                            <TextField name="attribution" type="text" label="Attribution" placeholder="ex: Catcher in the Rye, Ch 2 p.34" className={classes.textField} 
                            onChange={this.handleChange} value={attribution} fullWidth/>
                    </DialogContent>
                </Dialog>

            </Fragment>
        )
    }
}

CreatePost.propTypes = {
    createPost: PropTypes.func.isRequired,
    uploadPostWithImage: PropTypes.func.isRequired,
    editPost: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
    btnClassName: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    topic:PropTypes.string
}

const mapStateToProps = (state)=>({
    UI: state.UI,
    errors: state.UI.errors
})

export default connect(mapStateToProps, {createPost, uploadPostWithImage, editPost, clearErrors})(withStyles(styles)(CreatePost))