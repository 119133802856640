import React, { Fragment, Component, createRef} from 'react'
import {Row, Col, Grid} from '../components/layout/Flexbox'
import { connect } from 'react-redux'
import Header from '../components/layout/Header'

import withStyles from '@material-ui/core/styles/withStyles'
import AboutHeader from '../components/layout/AboutHeader'
import CircleImage from '../components/layout/CircleImage';
import Blurb from '../components/layout/Blurb';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import ChatIcon from '@material-ui/icons/Chat'

import rightLine from '../images/rightLine.png'
import leftLine from '../images/leftLine.png'
import Fade from 'react-reveal/Fade'

import photo1 from '../images/circleGuy1.jpg'
import photo2 from '../images/circleGuy2.jpg'
import photo3 from '../images/circleGuy3.jpg'
import photo4 from '../images/circleGuy4.jpg'

const styles = (theme) => ({
    ...theme.spreadThis
});

class communityGuidelines extends Component {
    state={}
 
    render() {
        const {classes} = this.props;

        return (
            <div style={{position:'relative', backgroundColor:'rgb(4, 13, 33)'}}>
            <div style={{marginTop:'-80px', width:'100%', height:'80px', backgroundColor: 'rgb(4, 13, 33)', boxShadow:'none', border:'none'}}/>
            <Row>
                <div style={{backgroundColor:'white', width:'100%', minHeight:'600px', justifyContent:'center', textAlign:'center'}}>
                {/* The left side  */}
                
                <Col>
                    <Fade top delay={1000} distance='0px'>
                    <img src={leftLine} style={{position:'absolute', top:'80px', right:'35px'}}/>

                    <div>
                    <FavoriteBorder style={{position:'absolute', left:'7.5%', top:'410px', color:'#ccc'}}/>
                    <FavoriteBorder fontSize="large" style={{position:'absolute', left:'9%', top:'490px', color:'#ccc'}}/>
                    </div>

                    <div>
                    <ChatIcon style={{position:'absolute', left:'5.1%', top:'320px', color:'#dcdcd1'}}/>
                    <ChatIcon fontSize="medium" style={{position:'absolute', left:'3.8%', top:'120px', color:'#7693d2'}}/>
                    <ChatIcon fontSize="medium" style={{position:'absolute', left:'14%', top:'448px', color:'#ccc'}}/>
                    <ChatIcon fontSize="large" style={{position:'absolute', left:'14%', top:'570px', color:'#7693d2'}}/>
                    </div>
                    </Fade>

                    <CircleImage image={photo1} style={{position:'absolute', left:'2%', top:'190px', zIndex:2}}/>
                    <Blurb body="How would you raise yourself differently?" like={true} style={{position:'absolute', left:'6%', top:'145px', transform:'rotate(3deg)', zIndex:2}}/>
                    <CircleImage image={photo2} style={{position:'absolute', left:'17%', top:'490px', zIndex:2}}/>

                    <Blurb body="What's the best way to express you feelings?" comment={true} style={{position:'absolute', left:'3%', top:'445px', transform:'rotate(-3deg)', zIndex:2}}/>
                </Col>

                {/* The right side */}

                <Col collapse="ld">
                    <Fade top delay={1000} distance='90px'>
                    <img src={rightLine} style={{position:'absolute', top:'80px', left:'35px'}}/>

                    <div>
                    <FavoriteBorder style={{position:'absolute', right:'7.5%', top:'410px', color:'#ccc'}}/>
                    <FavoriteBorder fontSize="large" style={{position:'absolute', right:'9%', top:'490px', color:'#ccc'}}/>
                    </div>

                    <div>
                    <ChatIcon style={{position:'absolute', right:'5.1%', top:'320px', color:'#dcdcd1'}}/>
                    <ChatIcon fontSize="medium" style={{position:'absolute', right:'3.8%', top:'120px', color:'#7693d2'}}/>
                    <ChatIcon fontSize="medium" style={{position:'absolute', right:'14%', top:'448px', color:'#ccc'}}/>
                    <ChatIcon fontSize="large" style={{position:'absolute', right:'14%', top:'570px', color:'#7693d2'}}/>
                    </div>
                    </Fade>

                    <CircleImage image={photo3} style={{position:'absolute', right:'2%', top:'190px'}}/>
                    <Blurb body="What would happen if your one life dream never came to pass?" like={true} style={{position:'absolute', right:'6%', top:'145px', transform:'rotate(-3deg)'}}/>
                    <CircleImage image={photo4} style={{position:'absolute', right:'17%', top:'460px'}}/>
                    <Blurb body='What does it mean to be "in love?"' comment={true} style={{position:'absolute', right:'3%', top:'445px', transform:'rotate(3deg)'}}/>
                </Col>

                <Col size={1} collapse="ld" style={{marginTop:'210px'}}>
                        <AboutHeader header="About WikiQ" body="Community Guidelines"  to='/communityGuidelines'/>
                </Col>
                <Col size={1} collapse="ls" style={{marginTop:'210px'}}> 
                        <AboutHeader header="About WikiQ" body="Community Guidelines" mobile={true}  to='/communityGuidelines'/>
                </Col>

                </div>
                
            </Row>
            
            <div style={{backgroundColor:'#040d21', paddingLeft:'10%', paddingRight:'10%', paddingTop:'40px', paddingBottom:'60px', position:'relative'}}>
                <Row>
                    <Col>
                        <div style={{marginTop:'20px'}}>
                        <Header topMargin="12px" header="Inspiration" width='500px' body="These Community Guidelines are based off of Jack Romp’s recommendations and compilation of other community guidelines. For the purposes of plagiarism it should be noted that these guidelines are based upon the community guidelines that govern Reddit, Wikipedia, Quora, Yahoo Answers, Stack Overflow, and Answers.com."
                        mobile={true}/>
                        </div>

                        <div style={{marginTop:'20px'}}>
                            <Header topMargin="12px" header="Wiki-Q Mission" width='500px' body="The goal of the platform is to generate insight into the human experience by asking interesting and sincere questions."
                            mobile={true}/>
                        </div>
                    </Col>
                    <Col collapse="ld"> 
                        <div style={{marginTop:'20px'}}>
                            <Header topMargin="12px" header="Introduction to Community Guidelines" width='1000px' body="Community Guidelines are the ways that we want and expect the community to behave on the site. These are a compilation of rules and guidelines to help users get the best experience possible. We want everyone to feel comfortable using our website while being able to think critically about a variety of subjects that may challenge beliefs, ideas, or identities. In a letter from the President of the University of Chicago to incoming students he notes that “Universities cannot be viewed as a sanctuary for comfort but rather as a crucible for confronting ideas and thereby learning to make informed judgments in complex environments...Having one’s assumptions challenged and experiencing the discomfort that sometimes accompanies this process are intrinsic parts of an excellent education.” In much the same way this website wants to use questions as a crucible to encourage and allow you to question your own beliefs and ideas both alone and with others. The rules that we have set out below should serve as a guide for how a member of the community should ask questions."
                            mobile={true}/>
                        </div>
                    </Col>
                    
                </Row>
                <Row>
                    <Col collapse="ls">
                        <div style={{marginTop:'20px'}}>
                            <Header topMargin="12px" header="Introduction to Community Guidelines" width='600px' body="Community Guidelines are the ways that we want and expect the community to behave on the site. These are a compilation of rules and guidelines to help users get the best experience possible. We want everyone to feel comfortable using our website while being able to think critically about a variety of subjects that may challenge beliefs, ideas, or identities. In a letter from the President of the University of Chicago to incoming students he notes that “Universities cannot be viewed as a sanctuary for comfort but rather as a crucible for confronting ideas and thereby learning to make informed judgments in complex environments...Having one’s assumptions challenged and experiencing the discomfort that sometimes accompanies this process are intrinsic parts of an excellent education.” In much the same way this website wants to use questions as a crucible to encourage and allow you to question your own beliefs and ideas both alone and with others. The rules that we have set out below should serve as a guide for how a member of the community should ask questions."
                            mobile={true}/>
                        </div>
                    </Col>
                </Row>

            </div>
                {/* HERE GO THE RULES */}
            <div style={{backgroundColor:'white', paddingLeft:'10%', paddingRight:'10%', paddingTop:'40px', paddingBottom:'60px', position:'relative'}}>
                    
                <div style={{marginTop:'20px', justifyContent:'center', display:'flex'}}>  
                    <Header mobile={true} borderSize="0px" dark={true} topMargin="12px" justifyContent="center" display="flex" textAlign="center" header="Rule #1 - Civility" width='600px' 
                    body="All users are expected to behave with courtesy and politeness at all times. We will not tolerate racism, sexism, or any other forms of bigotry. Nor will we accept personal insults of any kind."/>
                    </div>
                    <div style={{marginTop:'20px', justifyContent:'center', display:'flex'}}>   
                    <Header mobile={true} borderSize="0px" dark={true} topMargin="12px" justifyContent="center" display="flex" textAlign="center" header="Rule #2 - Intent matters" width='600px' 
                    body="Asking loaded questions or soapboxing will not be allowed unless a loaded question is tagged as such. Loaded questions when placed in the correct context can present the opportunity to try and think about why a person may believe what they believe. We expect you to ask questions in good faith. As such, questions should represent a sincere effort to encourage or engage in thoughtful discussion. "/>
                    </div>
                    <div style={{marginTop:'20px', justifyContent:'center', display:'flex'}}>  
                    <Header mobile={true} borderSize="0px" dark={true} topMargin="12px" justifyContent="center" display="flex" textAlign="center" header="Rule #3 - Ask better questions" width='600px' 
                    body="That is to say that not all questions are created equal, and putting in a small amount of time and effort to improve your question can go a long way in encouraging engagement."/>
                    </div>
                    <div style={{marginTop:'20px', justifyContent:'center', display:'flex'}}>   
                    <Header mobile={true} borderSize="0px" dark={true} topMargin="12px" justifyContent="center" display="flex" textAlign="center" header="Rule #4 - Allow diverse opinions" width='600px' 
                    body="Wiki-Q is a place for diverse people with diverse views and experiences. Wiki-Q will allow questions that might express a negative or unfavorable viewpoint if the content appears to be helpful in fostering debate or serving the public interest. When asking a difficult or controversial question users should ensure their question is thoughtfully constructed in a way that allows for engagement."/>
                    </div>
                    <div style={{marginTop:'20px', justifyContent:'center', display:'flex'}}>  
                    <Header mobile={true} borderSize="0px" dark={true} topMargin="12px" justifyContent="center" display="flex" textAlign="center" header="Rule #5 - No hate speech" width='600px' 
                    body="No hate speech or bigotry, no encouragement of violence, no harassment. We think that these should be obvious but this has been an issue on forums much like our own. As such, the moderators will be monitoring posted questions and do our best to quickly and efficiently remove questions that violate this rule. Users who violate this rule will be immediately banned from the website. 
                    "/>
                    </div>
                
                
            </div>

            <div style={{backgroundColor:'#040d21', paddingLeft:'10%', paddingRight:'10%', paddingTop:'40px', paddingBottom:'140px', position:'relative'}}>

                        <div style={{marginTop:'20px'}}>
                            <Header topMargin="12px" header="Our Commitment to Users" width='600px' body="1. We will be transparent. Any significant changes made by the mods will be explained to the community on the “updates page.” The community will be able to reach out to moderators through the platform to provide feedback."
                            mobile={true}/>
                            
                            <Header topMargin="12px" header="" width='600px' body="2. You are entitled to an explanation. A user whose account is banned is entitled to an explanation for why their account has been banned if they seemingly posed a question or commented in good faith. They are also entitled to appeal the decision unless the appeal would give credence to a question or comment that the moderators deem beyond reason."
                            mobile={true}/>

                            <Header topMargin="12px" header="" width='600px' body="3. We will never ever sell your data or give up your email. Your privacy is important. We aren’t in it for the money and as such will never sell your data."
                            mobile={true}/>

                            <Header topMargin="12px" header="" width='600px' body="4. We will listen to the community. Feedback, if constructively given warrants a constructive response. We believe that questions are important and if you are wondering why something was changed or happens we will do our best to answer that question."
                            mobile={true}/>
                        </div>
                
            </div>      
            </div>
            
            
        )
    }
}

export default connect (null, null)(withStyles(styles)(communityGuidelines))
