import React from "react";

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Flip from 'react-reveal/Flip';
import { Fragment } from "react";
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import {Link} from 'react-router-dom'
import WordButton2 from './WordButton2'

class AboutHeader extends React.Component {
  render() {
    let colorHeader = this.props.dark ? ('black'):('black')
    let colorBody = this.props.dark ? ('rgb(74 96 136)') :('#7a8caaff')
    let width1 = this.props.width ? (this.props.width):'450px'
    let borderSize2 = this.props.borderSize ? (this.props.borderSize):('12px')
    let topMargin = this.props.topMargin ? ('12px'):('0px')

    let aboutstyle= (
      <Flip top delay={0}>
        <div>
                <Typography >    
                    <Box color={colorHeader} fontSize={this.props.mobile ? (50):(65)} fontWeight={700}>
                    {this.props.header}
                    </Box>
                </Typography>

                  <div style={{marginTop:'-20px', marginLeft:'45px'}}>
                  <WordButton2 as='link' to={this.props.to}>
                  <SubdirectoryArrowRightIcon fontSize='large' color='#7a8caaff' style={{marginRight: '6px', marginTop:'-12px'}}/>
                    {this.props.body}
                  </WordButton2>
                  </div>

        </div>
        </Flip>
    )

    let titlestyle = (
      <Flip top delay={0}>
        <div style={{justifyContent:'center', textAlign:'center', display:'flex'}}>
          <div style={{maxWidth:'700px'}}>
            <Typography >    
                    <Box color='white' fontSize={this.props.mobile ? (38):(45)} fontWeight={700}>
                    {this.props.header}
                    </Box>
                </Typography>

                  
          </div>
        </div>
        <div style={{justifyContent:'center', textAlign:'center', display:'flex'}}>
          <div style={{marginTop:'10px', maxWidth:'800px'}}>
                    <Typography>
                      <Box color='white' fontSize={this.props.mobile ? (17):(18)} fontWeight={400}>
                        {this.props.body}
                      </Box>
                    </Typography>
                    
          </div>
        </div>

        </Flip>
    )

    let asidestyle = (
      <Flip top delay={0}>
        <div style={{float: 'left', maxWidth:'400px'}}>
            <Typography >    
                    <Box color='rgb(27 27 27)' fontSize={this.props.mobile ? (30):(45)} fontWeight={700}>
                    {this.props.header}
                    </Box>
            </Typography>

            <Typography style={{marginTop:'20px'}}>
              <Box color='rgb(53 53 53)' fontSize={this.props.mobile ? (13):(18)} fontWeight={400}>
                {this.props.body}
              </Box>
            </Typography>
        </div>
        </Flip>
    )

    return (
      this.props.title ? (titlestyle):( this.props.aside ? (asidestyle) :(aboutstyle))
        
    )
  }
}

export default AboutHeader;
