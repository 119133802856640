import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types'
//MUI Stuff
import Typography from '@material-ui/core/Typography'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import FavoriteIcon from '@material-ui/icons/Favorite'
import ChatIcon from '@material-ui/icons/Chat'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import {connect} from 'react-redux'
import {markNotificationsRead} from '../../redux/actions/userActions'

import WordButton from '../layout/WordButton'

class Notifications extends Component{
    state = {
        anchorEl: null
    }

    handleOpen = (event) =>{
        this.setState({anchorEl: event.target})
    }

    handleClose = () =>{
        this.setState({anchorEl: null})
    }

    onMenuOpened = () =>{
        let unreadNotificationsIds = this.props.notifications.filter(not=>!not.read)
            .map(not=>not.notificationsId);

        this.props.markNotificationsRead(unreadNotificationsIds)
    }
    render(){
        const notifications = this.props.notifications
        const anchorEl = this.state.anchorEl

        dayjs.extend(relativeTime)

        let notificationIcon;
        if(notifications && notifications.length > 0)
        {
            notifications.filter(not=> not.read===false).length > 0 ? (
                
                notificationIcon=(
                    <div style={{background: '#eb2d56', width: 7, height: 7, position: 'absolute', float: 'right',top:'60%', right:'-9%', borderRadius:'7px'}}/>
                )
            ) : (
                notificationIcon = null
            )
        } else{
            notificationIcon = null
        }

        if (notifications.filter(not=> not.read===false).length > 0) {
            console.log("new notification")
        }

        let notificationMarkup = (notifications && notifications.length > 0)?(
            notifications.map(not=>{
                let message = ''
                let time = dayjs(not.createdAt).fromNow();
                const iconColor = not.read ? 'primary':'secondary';
                let to=''

                let icon = <FavoriteIcon color={iconColor} style={{ marginRight: 10}}/>
                switch(not.type){

                    case 'comment':
                        icon = <ChatIcon color = {iconColor} style={{ marginRight: 10}}/>
                        message = `${not.sender} commented on your post`
                        to = '/users/'+not.recipient+'/post/'+not.postId
                        break;
                    case 'approved':
                        icon = <ThumbUpIcon color={iconColor} style={{ marginRight: 10}}/>
                        message = `Topic: ${not.topic} was approved`
                        to = '/topics/'+not.topic
                        break;

                    case 'declined':
                        icon = <ThumbDownIcon color={iconColor} style={{ marginRight: 10}}/>
                        message = `Topic: ${not.topic} was declined`
                        break;

                    default: //like
                        icon = <FavoriteIcon color={iconColor} style={{ marginRight: 10}}/>
                        message = `${not.sender} liked your post`
                        to = '/users/'+not.recipient+'/post/'+not.postId
                }

                return (
                    <MenuItem key={not.createdAt} onClick={this.handleClose}>
                        {icon}
                        <Typography color="default" variant="body1" to={to}>
                            {message} {time}
                        </Typography>
                    </MenuItem>
                )
            })
        ):(
            <MenuItem onClick={this.handleClose}>
                You have no notifications yet
            </MenuItem>
        )
        return (
            <Fragment>
                    <WordButton onClick={this.handleOpen}>Notifications{notificationIcon}</WordButton>
                <Menu 
                disableScrollLock={true}
                anchorEl = {anchorEl}
                open = {Boolean(anchorEl)}
                onClose={this.handleClose}
                onEntered={this.onMenuOpened}
                PaperProps={{
                    style: {
                        marginTop:'50px'
                    }
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    {notificationMarkup}
                </Menu>
            </Fragment>
        )
    }
}

Notifications.propTypes = {
    markNotificationsRead: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
}

const mapStateToProps = state=>({
    notifications: state.user.notifications
})
export default connect(mapStateToProps, {markNotificationsRead})(Notifications)