import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {Button, ButtonContent, Menu} from 'semantic-ui-react'
//import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'
import './wordbutton2.css'

export default ({children, as, onClick, to, btnClassName, style}) =>(

        <Button onClick={onClick} style={{backgroundColor: 'transparent'}} color="diff" className="tertiary">

                <Link to={to}>
                <Typography style={{textTransform:'none'}}>
                    <Box fontWeight={400} fontSize={23} style={{ textDecoration: 'none'}}>
                        {children}
                    </Box>
                </Typography>
                </Link>
               
        </Button>

)
