import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import {connect} from 'react-redux'
import {editUserDetails} from '../../redux/actions/userActions'

import { Row, Col } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {Button} from 'semantic-ui-react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const styles=(theme)=>({
    ...theme.spreadThis,
    button:{
        float:'right',
    }
})

export class EditDetails extends Component {
    state = {
        bio: '',
        website: '',
        country: '',
        degree: '',
        name: '',
        open: false
    }

    mapUserDetailsToState = (credentials) =>{
        if(credentials ==null){return;}
        this.setState({
            bio: credentials.bio ? credentials.bio : '',
            website: credentials.website ? credentials.website : '',
            country: credentials.country ? credentials.country : '',
            degree: credentials.degree ? credentials.degree : '',
            name: credentials.name ? credentials.name : '',
        })
    }
    handleOpen = ()=>{
        this.setState({open: true})
        this.mapUserDetailsToState(this.props.credentials)
    }
    handleClose=()=>{
        this.setState({open: false})
    }
    componentDidMount(){
        this.mapUserDetailsToState(this.props.credentials)
    }
    
    handleChange = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        });
    };
    onSubmitForm = e =>{
        console.log("sending the form")
        e.preventDefault()
        const formData = new FormData(e.target)
        const userDetails = {}
        formData.forEach((value, property) => userDetails[property] = value)
        this.props.editUserDetails(userDetails)
        this.handleClose()
    }

    render() {
        const {classes} = this.props;
        return (
            <Fragment>
                <Button color="white" basic inverted style={{borderRadius: '30px', padding: '7px 12px'}} onClick={this.handleOpen}>
                    <Typography>
                        <Box color="white" fontWeight={370} fontSize={14}>
                            Edit Profile
                        </Box>
                    </Typography>
                </Button>
                <Dialog open={this.state.open}
                onClose={this.handleClose}
                fullWidth
                maxWidth="sm">
                    <DialogTitle>Edit your details</DialogTitle>
                    <DialogContent>
                        <Form onSubmit={e => this.onSubmitForm(e)}>
                            <Row>
                                <Col>
                                <FormGroup style={{width:'100%'}}>
                                <Label for="name">Name</Label>
                                <Input name="name" type="text" id="Name" placeholder="..." className={classes.TextField} value={this.state.name} onChange={this.handleChange} fullWidth/>
                            </FormGroup> 
                                </Col>
                                <Col>
                                <FormGroup>
                                <Label for="degree">Level of Education</Label>
                                <Input name="degree" type="select" id="Degree" placeholder="..." className={classes.TextField} value={this.state.degree} onChange={this.handleChange} fullWidth>
                                        <option>Degree</option>
                                        <option>None</option><option>High School</option><option>Associates</option><option>Bachelors</option><option>Masters</option><option>Doctorate</option>
                                </Input>
                            </FormGroup>
                                </Col>
                            </Row>
                            
                            
                            <FormGroup>
                                <Label for="bio">Bio</Label>
                                <Input name="bio" type="textarea" id="Bio" placeholder="A short bio about yourself" className={classes.TextField} value={this.state.bio} onChange={this.handleChange} fullWidth/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="website">Website</Label>
                                <Input name="website" type="text" id="Website" placeholder="..." className={classes.TextField} value={this.state.website} onChange={this.handleChange} fullWidth/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="country">Country</Label>
                                <Input name="country" type="text" id="Country" placeholder="..." className={classes.TextField} value={this.state.country} onChange={this.handleChange} fullWidth/>
                            </FormGroup>
                            <Button onClick={this.handleClose} color="primary">Cancel</Button>
                            <Button type="submit" color="primary">Submit</Button>
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) =>({
    user: state.user
})
EditDetails.propTypes = {
    editUserDetails: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, {editUserDetails})(withStyles(styles)(EditDetails))
