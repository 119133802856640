import {LIKE_POST, RESET_DIR, RESET_TOPIC, RESET_POSTS, EDIT_POST, RESET_REQUESTS, CREATE_TOPIC_REQUEST, SET_TOPIC_REQUESTS, JUDGE_REQUEST, UNLIKE_POST, SET_POSTS,LOADING_TOPICS, LOADING_DATA, DELETE_POST, CREATE_POST, SET_POST, SUBMIT_COMMENT, SET_TOPIC, SET_TABLE_OF_CONTENTS } from '../types';

const initialState = {
    posts: [],
    topicRequests:[],
    uploadedImage: "",
    post: {},
    topic: {},
    loading: false,
    loading_topics: false,
    directory: []
};

export default function(state = initialState, action){
    switch(action.type){
        case LIKE_POST:
            let comments = state.post.comments

            let index = state.posts.findIndex((post)=> post.postId === action.payload.postId)
            state.posts[index] = action.payload;
            
            return {
                ...state,
            }
        case UNLIKE_POST:
            let index2 = state.posts.findIndex((post)=> post.postId === action.payload.postId)
            state.posts[index2] = action.payload;

            return {
                ...state,
            }
        case LOADING_DATA:
            return {
                ...state,
                loading: true
            }
        case LOADING_TOPICS:
            return {
                ...state,
                loading_topics: true
            }
        case SET_POSTS:
            for (var i = 0; i < action.payload.length; i++) {
                let found = false;
                for(var j = 0; j < state.posts.length; j++) 
                {
                    if(state.posts[j].postId === action.payload[i].postId){
                        found = true;
                    }
                }
                if(!found) state.posts.push(action.payload[i])   
              }
            return {
                ...state,
                loading: false
            }
        case DELETE_POST:
            let index4 = state.posts.findIndex((post)=> post.postId === action.payload)
            state.posts.splice(index4, 1);
            return {
                ...state
            }
        case EDIT_POST:
            console.log('successfully edited the post')
            console.log(action.payload)
            let index5 = state.posts.findIndex((post)=> post.postId === action.payload.postId)
            state.posts[index5] = action.payload
            
            state.post.body = action.payload.body
            state.post.tags = action.payload.tags
            state.post.header = action.payload.header
            state.post.media = action.payload.media
            state.post.attribution = action.payload.attribution
            
            return {
                ...state,
                loading: false
            }
        case SET_TOPIC_REQUESTS:
            return {
                ...state,
                topicRequests: action.payload,
                loading_topics: false
            }
        case SET_TABLE_OF_CONTENTS:
            return {
                ...state, 
                directory: action.payload,
                loading: false
            }
        case JUDGE_REQUEST:
            let index3 = state.topicRequests.findIndex((topic)=> topic.topicName === action.payload)
            console.log("found a request at "+index3)
            state.topicRequests.splice(index3, 1);
            return{
                ...state
            }
        case RESET_POSTS:
            state.posts = []
            return{
                ...state
            }
        case RESET_REQUESTS:{
            state.topicRequests = []
            return{
                ...state
            }
        }
        case RESET_DIR:{
            state.directory = []
            return{
                ...state
            }
        }
        case RESET_TOPIC:{
            state.topic = {}
            return{
                ...state
            }
        }
        // case SET_POST_IMAGE:
        //     return {
        //         ...state, 
        //         uploadedImage: action.payload
        //     }
        case CREATE_POST:
            return {
                ...state,
                posts: [
                    action.payload,
                    ...state.posts
                ]
            }
        case CREATE_TOPIC_REQUEST:
            return {
                ...state, 
                topicRequests: [
                    action.payload,
                    ...state.topicRequests
                ],
                loading: false
            }
        case SET_POST:
            return {
                ...state,
                post: action.payload
            }
        case SET_TOPIC:
            return {
                ...state,
                topic: action.payload,
                loading: false
            }
        case SUBMIT_COMMENT:
            return{
                ...state,
                post:{
                    ...state.post,
                    comments: [action.payload, ...state.post.comments]
                }
            }
        default:
            return state
    }
}