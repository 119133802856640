import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem';
import WordButton from './WordButton'
import {Link} from 'react-router-dom'
import { propTypes } from 'react-bootstrap/esm/Image';

export default function AboutButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <WordButton aria-controls="simple-menu" aria-haspopup="true" style={props.mainStyle} onClick={handleClick}>
        About
      </WordButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted
        open={Boolean(anchorEl)}
        // container={anchorEl ? anchorEl.parentNode : anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
              marginTop:'50px',
              width:'200px'
          }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
      >
        <MenuItem linkButton onClick={handleClose} component={Link} to='/about'>About Page</MenuItem>
        <MenuItem linkButton onClick={handleClose} component={Link} to='/communityGuidelines'>Community Guidelines</MenuItem>
      </Menu>
      </Fragment>
  );
}