import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
//Redux stuff
import {connect} from 'react-redux'
import { submitComment } from '../../redux/actions/dataActions'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'

//MUI stuff
import { CardContent, Typography, Box, Paper, IconButton } from '@material-ui/core'
import Favorite from '@material-ui/icons/Favorite'
import ChatIcon from '@material-ui/icons/Chat'


const styles = theme =>({
    ...theme.spreadThis,
    card:{
        width:'260px',
        borderRadius:'4px',
        boxShadow:'3px 3px 15px 0px rgb(0 0 0 / 21%)',
        textAlign:'left',
        padding:'15px'
    },
    image:{
        width:'100px',
        height:'100px',
    },
    circleButton:{
        width:30,
        height:30,
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 400,
        border: 0,
        padding: 12,
        boxShadow: '-1px 2px 20px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',

    },
    circleButton2:{
        margin: 4,
        width:30,
        height:30,
        marginLeft: 9,
        marginTop:'-12px',
        float: 'right',
        background: 'linear-gradient(45deg, #93dcd2ff 30%, #B7F8DB 90%)',
        borderRadius: 400,
        border: 0,
        padding: 12,
        boxShadow: '-1px 2px 20px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }
})

class Blurb extends Component {
    render() {
        const {classes} = this.props;
        return(
            <Paper className={classes.card} style={this.props.style}>
                    <Typography>
                        <Box fontSize={16} fontWeight={600}>
                            {this.props.body}
                        </Box>
                    </Typography>
                    
                    {this.props.like && (
                        <IconButton className={classes.circleButton} style={{outline:'none', position:'absolute', right:'49px', bottom:'-12px'}} component="span">
                        <Favorite style={{color: 'white', width: 17, height: 17}} size="medium"/>
                        </IconButton>
                    )}
                   
                    {this.props.comment && (
                        <IconButton className={classes.circleButton2} style={{outline:'none', position:'absolute', right:'49px', bottom:'-12px'}} component="span">
                        <ChatIcon style={{color: 'white', width: 17, height: 17}} size="medium"/>
                        </IconButton>
                    )}
                    
            </Paper>
        )
    }
}


export default connect(null, null)(withStyles(styles)(Blurb))