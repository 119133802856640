import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'


//MUI Stuff
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//REDUX
import {connect} from 'react-redux';
import {signupUser, signupUserGoogle} from '../redux/actions/userActions'
import GoogleLogin from 'react-google-login';
import GoogleG from '../images/googleG2.png'
import TinyLogo from '../images/jsx/tinyLogo'
import Text from '../components/layout/Text'

const styles = (theme) =>({
    ...theme.spreadThis,
    card: {
        width: '360px',
        minHeight: '600px',
        padding: '15px 60px',
        textAlign: 'center',
        borderRadius: '16px'
    },
    cover: {
        width: 351,
        height: '100%'
      },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '400px'
    },
    textField:{
        margin: '4px auto 4px auto',
        padding: '4px auto 4px auto'
    },
})



export class signup extends Component {
    constructor(){
        super();
        this.state = {
            email: '',
            password:'',
            confirmPassword:'',
            handle:'',
            name:'',
            errors: {}
        }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({errors: nextProps.UI.errors});
        }
    }

    handleSubmit = (event) =>{
        event.preventDefault();
        this.setState({
            loading:true
        });
        const userData = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            handle: this.state.handle
        }
        this.props.signupUser(userData, this.props.history)
    };

    handleGoogleSubmit = (res)=>{
        const resData = {
            token: res.tokenId,
            profileObj: res.profileObj
        }
        this.props.signupUserGoogle(resData, this.props.history)
    }
    
    handleChange = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        });
    };
    render() {
        const {classes, UI:{loading}} = this.props;
        const {errors} = this.state;
        return (
            <Fragment>
            <div class="background-image-full"></div>

            <div className="log-in-container">

            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <TinyLogo/>

                    <Text color='#333333' fontSize={27} fontWeight={500} style={{marginTop:'20px'}}>
                        Nice to meet you!
                    </Text>

                    <form noValidate onSubmit={this.handleSubmit} style={{'width':'100%'}}>

                        <TextField name="name" type="name" label="Name" className={classes.textField}
                                                value={this.state.name} helperText={errors.name} error={errors.name ? true: false} onChange={this.handleChange} fullWidth/>
                        <TextField name="handle" type="handle" label="Username" className={classes.textField}
                                                value={this.state.handle} helperText={errors.handle} error={errors.handle ? true: false} onChange={this.handleChange} fullWidth/>
                        <TextField name="email" type="email" label="Email" className={classes.textField}
                            value={this.state.email} helperText={errors.email} error={errors.email ? true: false} onChange={this.handleChange} fullWidth/>
                        <TextField name="password" type="password" label="Password" className={classes.textField}
                                                value={this.state.password} helperText={errors.password} error={errors.password ? true: false} onChange={this.handleChange} fullWidth/>
                        <TextField name="confirmPassword" type="password" label="Confirm Password" className={classes.textField}
                                                value={this.state.confirmPassword} helperText={errors.confirmPassword} error={errors.confirmPassword ? true: false} onChange={this.handleChange} fullWidth/>
                        {errors.general && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.general}
                            </Typography>
                        )}
                        <Button type="submit" variant="contained" color="primary" className={classes.wideButton} style={{outline:'none', marginBottom:'20px', marginTop:'20px'}} disabled={loading}>
                            Signup
                            {loading && (
                                <CircularProgress size={30} className={classes.progress}/>
                            )}
                        </Button>

                        <GoogleLogin
                            clientId="764314147194-qlj9nt9rnmktj5sleh2sjt6gu2n5jmqt.apps.googleusercontent.com"
                            render={renderProps => (
                                <Button variant="contained" startIcon={<img src={GoogleG} width="30px"/>}  onClick={renderProps.onClick} style={{'width':'100%', marginTop:'20px', borderRadius:'40px', 'padding':'12px 0 12px 0', 'margin':'0 0 20px 0', outline:'none'}}>Signup with Google</Button>
                            )} 
                            buttonText="Signup with Google"
                            onSuccess={this.handleGoogleSubmit}
                            onFailure={this.handleSubmit}
                            cookiePolicy={'single_host_origin'}
                        />

                        <Text fontSize={10}>Already have an account? Login <Link to="/login">here</Link></Text>
                    </form>

                </CardContent>
            </Card>
        </div>
        </Fragment>
        )
    }
}

signup.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) =>({
    user: state.user,
    UI: state.UI
})

export default connect(mapStateToProps, {signupUser, signupUserGoogle})(withStyles(styles)(signup))
