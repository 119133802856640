import { Fragment } from "react";
import { Component } from "react";

import PropTypes from 'prop-types'

export default class ScrollHandler extends Component{
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
            message: 'not at bottom'
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            console.log('you reached the bottom')
            this.props.onBottom()
        } else {
            
        }
    }
    
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render(){
        return(null
        )
    }
}

ScrollHandler.propTypes = {
    onBottom: PropTypes.func.isRequired
}