import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
//Redux stuff
import {connect} from 'react-redux'
import { submitComment } from '../../redux/actions/dataActions'


//MUI stuff
import {Button} from 'semantic-ui-react'

const styles = theme =>({
    ...theme.spreadThis,
    root: {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        height: '36px',
        borderRadius: 4,
        backgroundColor: '#fcfcfb',
        paddingLeft: '7px',
        paddingTop: '3px',
        width: 'calc(100% - 43px)',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
          backgroundColor: '#fff',
        },
        '&$focused': {
          backgroundColor: '#fff',
          borderColor: theme.palette.primary.main,
        },
      },
      focused: {},
    button:{
        marginLeft: '8px',
        display: 'flex-end',
        marginTop: '11px'
    }
})

class CommentForm extends Component {
    state = {
        body:'',
        errors:{ comment: 'aaaaaa'}
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors)
        {
            this.setState({
                errors: nextProps.UI.errors
            })
        }
        if(!nextProps.UI.errors && !nextProps.UI.loading){
            this.setState({
                body:''
            })
        }
    }
    handleChange = (event) =>{
        this.setState({[event.target.name]: event.target.value})
    }
    handleSubmit = (event) =>{
        event.preventDefault();
        this.props.submitComment(this.props.postId, {body: this.state.body})
    }
    render() {
        const {authenticated, classes} = this.props;
        const { errors} = this.state.errors;
        const commentFormMarkup = authenticated ?(
            <Fragment>
                <form onSubmit={this.handleSubmit} style={{float: 'right', width:'calc(100% - 48px)'}}>
                    <TextField name="body" type="text" placeholder="Comment" InputProps={{disableUnderline: true}}  value={this.state.body} onChange={this.handleChange} className={classes.root}/>
                    <Button type="submit" icon="reply">
                        
                    </Button>
                </form>
            </Fragment>
        ):(null)
        return commentFormMarkup;
    }
}

CommentForm.propTypes = {
    submitComment: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    postId: PropTypes.string.isRequired,
    authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = state=>({
    UI: state.UI,
    authenticated: state.user.authenticated
})

export default connect(mapStateToProps, {submitComment})(withStyles(styles)(CommentForm))