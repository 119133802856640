import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
//Redux stuff
import {connect} from 'react-redux'
import { submitComment } from '../../redux/actions/dataActions'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'

//MUI stuff
import {Button} from 'semantic-ui-react'
// import testImage from '../../images/tiny_photo.jpg'
import {Fade} from 'react-reveal'

const styles = theme =>({
    ...theme.spreadThis,
    card:{
        width:'100px',
        height:'100px',
        borderRadius:'90px',
        boxShadow:'3px 3px 10px 0px rgb(0 0 0 / 31%)'
    },
    image:{
        width:'100%',
        height:'100%',
    }
})

class CircleImage extends Component {
    render() {
        const {classes} = this.props;
        return(
            
            <Card className={classes.card} style={this.props.style}>
                <CardMedia image={this.props.image} className={classes.image}/>
            </Card>
        )
    }
}


export default connect(null, null)(withStyles(styles)(CircleImage))