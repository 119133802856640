import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {Button, ButtonContent, Menu} from 'semantic-ui-react'
//import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'
import './wordbutton.css'

export default ({children, as, onClick, to, btnClassName, style, color}) =>(

        <Button onClick={onClick} style={{backgroundColor: 'transparent'}} color={color ? color : "white"} className="tertiary">
            {as==='link' ? (
                <Link to={to}>
                <Typography to={to} style={{textTransform:'none'}}>
                    <Box fontWeight={300} fontSize={16} style={{ textDecoration: 'none'}}>
                        {children}
                    </Box>
                </Typography>
                </Link>
            ):
            (
                <Link>
                <Typography style={{textTransform:'none'}}>
                    <Box fontWeight={300} fontSize={16} style={{textDecoration: 'none'}}>
                        {children}
                    </Box>
                </Typography>
                </Link>
            )}
            
            
        </Button>
)
