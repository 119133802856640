export default {
    palette: {
      primary: {
        main:'#3046f5',
        light:'#3046f5',
        dark:'#ffffff',
        mainGradient: "linear-gradient(to right, tomato, cyan)"
      },
      secondary: {
        main:'#e53935',
      }
    },
    typography:{
        useNextVariants: true,
        fontFamily: 'Roboto',
      },
    spreadThis:{
        backgroundSize:'cover',
      typography:{
        useNextVariants: true,
        fontFamily: 'Roboto',
      },
      form:{
        textAlign: 'center'
      },
      image:{
          margin: '20px auto 20px auto'
      },
      pageTitle:{
          margin: '30px auto 20px auto'
      },
      textField:{
          margin: '8px auto 8px auto',
          padding: '8px auto 8px auto'
      },
    //   overrides: {
    //     MuiButton: {
    //       root: {
    //         borderRadius: 8,
    //         borderColor: 'white'     
    //       }, 
    //       '& .outlined':{
    //         border: '1px solid rgb(255 255 255)',
    //         padding: '5px 15px'
    //         }
    //     }, 
    //   }, 
      button:{
          marginTop: 20,
          position: 'relative'
      },
      roundButton:{
        borderRadius: '30px'
      },
      wideButton:{
        marginTop: 20,
        position: 'relative',
        width: '100%',
        padding: '15px 0 15px 0',
        borderRadius:'60px'
      },
      customError:{
          color: 'red',
          fontSize: '0.8rem',
          marginTop:'10px'
      },
      signup:{
          marginTop: 20
      },
      progress:{
          position: 'absolute'
      },
      visibleSeparator:{
          width: '100%',
          borderBottom: '1px solid rgba(0,0,0,.1)',
          marginBottom: 20
      },
      invisibleSeparator:{
        width: '100%',
        borderBottom: '1px solid rgba(0,40,0,0)',
        marginBottom: 20
      },
      paper: {
        padding: 20,
        margin: "0 20px 0 20px"
      },
      profile: {
          '& .image-wrapper':{
              top:'-30%',
              position: 'absolute',
              '& button':{
                  position: 'absolute',
                  top: '80%',
                  left: '70%'
              }
          },
          '& .profile-image':{
              width: 200,
              height: 200,     
              borderRadius: '50%',
              objectFit: 'cover'       
          },
          '& .profile-details':{
              textAlign: 'center'            
          },
          '& a':{
              color: '#3046f5',
          },
          '& hr':{
              border: 'none',
              margin: '0 0 10px 0'
          },
          '& svg.button':{
              '&:hover':{
                  cursor: 'pointer'
              }
          }
      },
      buttons:{
          textAlign: 'center',
          '& a': {
              margin: '20px 10px'
          }
      },
      icons:{
          flexGrow: 1,
          justifyContent:'center',
          alignItems: 'center'     
      },
      newColor: {
              color:'#544336'
      }
      
    }
  
  }