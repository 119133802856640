import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import dayjs from 'dayjs'

import Typography from '@material-ui/core/Typography'


import LocationOn from '@material-ui/icons/LocationOn'
import Work from '@material-ui/icons/Work'
import CameraIcon from '@material-ui/icons/CameraAlt'
import {Button} from 'semantic-ui-react'
import EditDetails from './EditDetails'
import {uploadUserImage, uploadBannerImage} from '../../redux/actions/userActions'
import {getMoreUserPosts, resetPosts} from '../../redux/actions/dataActions'
import { connect } from 'react-redux'
import GridM from '@material-ui/core/Grid';
import EventNote from '@material-ui/icons/EventNote'
import IconButton from '@material-ui/core/IconButton'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box'
import {Grid, Row, Col} from '../../components/layout/Flexbox'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress'
import ScrollHandler from '../../util/ScrollHandler';
import noImg from '../../images/no-img.png'

// import defaultBackground from '../../images/'

const styles = (theme) => ({
    ...theme.spreadThis,
    widePaper:{
        width: '100%'
    },
    bannerImage:{
        height: '350px',
        width: '100%',
        marginTop: '-80px'
    },
    card:{
        width: 340,
        marginTop:-200,
        marginLeft:'10px',
        float: 'right',
        boxShadow: '1px 1px 19px 6px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius:'1px'
    },
    card2:{
        width:'100%',
        margin:'20px',
        marginTop:'20px',
        padding:'10px',
        boxShadow: '1px 1px 19px 6px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius:'1px',
        marginBottom: '5px'
    },
    card3:{
        width:'100%',
        boxShadow: '0px 20px 20px 0px rgba(0,0,0,0.2)'
    },
    cardContent:{
        padding:'0px',
        "&:last-child": {
            paddingBottom: 0
          }
    },
    image:{
        width:220,
        height:220,
        margin: '5px 0 0 5px',
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
    },
    centerImage:{
        width:230,
        height:230,
        marginTop:-170,
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
        backgroundColor: "rgb(255, 255, 255)"
    },
    content:{
        padding: 19,
        objectFit:'cover',
        fontFamily: 'BlinkMacSystemFont',
        display: 'inline-block'
    },
    circle:{
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 5.46,

        elevation: 9,
    },
    rightGrid:{
        width:'100%',
    },
    tophalf:{
        height: '300px',
    },
    bottomHalf:{
        height:'100%',
        position:'relative',
        bottom: '-3px',
        left: '-40px'
    },
    editDetailsButton:{
        float: 'right',
        position: 'absolute',
        top:'316px',
        left: '84%'
    },
    editDetailsButtonMobile:{
        float: 'right',
        position: 'absolute',
        top:'100px',
        right: '1%'
    },
    header:{
        marginTop:'20px'
    },
    handle:{
        marginTop:'-10px'
    },
    cameraButton:{
        float: 'right',
        position: 'absolute',
        top:'404px',
        left:'30%'
    },
    cardImage:{
        height: '390px',
        objectFit: 'cover'
    },
    editContainer:{
        position:'absolute',
        float: 'right',
        right: '5%',
        top: '20%'
    },
    roundButton:{
        borderRadius: '30px',
        '&:outline': {
            'borderColor': 'green'
          }
    },
    gridContainer:{
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

class StaticProfile extends Component {
    state= {
        value: 0,
        profileError:false,
        bannerError:false,
        loadingProfileImage:false,
        all_seen: false,
        prev_posts: -1
    }

    getMorePosts = () => {
        console.log('lets get more posts')
        if(this.state.prev_posts === this.props.data.posts.length){
            this.setState({all_seen: true})
            return;
        }

        this.setState({prev_posts: this.props.data.posts.length})
        if(this.props.data.posts && this.props.data.posts.length > 0)
        {
            console.log('making the call')
            let arg = this.props.data.posts[this.props.data.posts.length-1].postId
            console.log(this.props)
            this.props.getMoreUserPosts({id: arg}, this.props.profile.handle)
        }
    }

    handleImageChange = (event) =>{
        this.setState({profileError:false, loadingProfileImage:true})
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);
        const config = {
            headers:{
                'content-type': 'multipart/form-data; boundary=<calculated when request is sent>'
            }
        }
        this.props.uploadUserImage(formData)

    }
    handleBannerImageChange = (event) =>{
        this.setState({bannerError:false})
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);
        const config = {
            headers:{
                'content-type': 'multipart/form-data; boundary=<calculated when request is sent>'
            }
        }
        this.props.uploadBannerImage(formData)
    }
    handleEditPicture = () =>{
        const fileInput = document.getElementById('imageInput')
        fileInput.click();
    }
    handleEditBannerPicture = () =>{
        const fileInput = document.getElementById('imageInput2')
        fileInput.click();
    }
    handleChange = (event, newValue) => {
        this.setState({value:newValue})
    };

    render(){
        
        const {value, profileError, bannerError, loadingProfileImage} = this.state
        const { classes, loading, profile: {handle,country, degree, website,  createdAt, imageUrl, bio, location, name, bannerUrl}, postMarkups, topicRequestMarkups, user: {authenticated, credentials:{handle: myHandle}}} = this.props

        let tabSection = (
            <Fragment>
                <Tabs
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab style={{outline:'none'}} label={<Typography><Box fontWeight={700}>Questions</Box></Typography>} {...a11yProps(0)} />
                    <Tab style={{outline:'none'}} label={<Typography><Box fontWeight={700}>Topic Requests</Box></Typography>} {...a11yProps(1)} />
                    <Tab style={{outline:'none'}} label={<Typography><Box fontWeight={700}>More</Box></Typography>} {...a11yProps(2)} />
                </Tabs>
            </Fragment>
            
        )

        let tabContents = (
            <div>
                <TabPanel value={value} index={0} style={{padding:'0px'}}>
                    <ScrollHandler onBottom={this.getMorePosts}/>
                    {postMarkups} 
                    <div style={{height:'150px', textAlign: 'center', marginTop: '70px'}}>
                        {loading && (<CircularProgress size={30} color="primary"/>)}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {topicRequestMarkups}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div style={{marginTop:'20px'}}>
                        <Typography fontSize={30}>
                            <Box fontWeight={500}>
                                Handle: {handle}
                            </Box>
                        </Typography>
                        {website && (
                            <Typography fontSize={30} style={{marginTop:'3px'}}>
                            <Box fontWeight={500}>
                                Website: {website}
                            </Box>
                            </Typography>
                        )}
                        
                    </div>
                    
                </TabPanel>
            </div>
        )

        let notMobile = (
            <GridM container>
                <Card style={{marginTop:'-80px', width: '100%', zIndex:'-9'}}>
                <CardMedia component="img" src={bannerUrl} title="Banner image" className="background-image"
                            onError={e=>{
                                e.target.src = 'https://firebasestorage.googleapis.com/v0/b/buddymagnet.appspot.com/o/topicBannerImage.jpg?alt=media'
                                this.setState({bannerError:true})
                                console.log('banner error')
                            }}/>
                
                </Card>
                
                <input
                type="file"
                id="imageInput"
                hidden="hidden"
                onChange={this.handleImageChange}
              />
              <input
                type="file"
                id="imageInput2"
                hidden="hidden"
                onChange={this.handleBannerImageChange}
              />
                
                <Grid>
                    <Row>
                        <Col size={3} style={{position:'relative'}}>
                            <Card className={classes.card}>
                            <CardMedia src={imageUrl} component="img" className={classes.cardImage} 
                            onError={e=>{
                                console.log('error here ')
                                e.target.src = noImg
                                this.setState({profileError:true})
                                console.log('set error to ture')
                                //e.target.src='fallback'
                            }}
                            />
                            
                            <CardContent>
                                <Typography component="div" style={{marginBottom: '10px'}}>
                                    {bio && (
                                        <Box fontWeight={600} fontSize={20}>
                                        About
                                        <br/>
                                    </Box>
                                    )}   
                                </Typography>
                                
                                <Typography component="div" variant="body1" color="textSecondary">
                                    {bio && (
                                    <Box fontWeight={400} fontSize={15}>
                                        {bio}
                                        <hr/>
                                        </Box>
                                    )}
                                        {/* {bio} */}
                                        
                                        <Box fontWeight={400} fontSize={15} color="black">
                                            <EventNote color="black" style={{marginBottom:'7px', marginRight:'5px'}}/> Member since {dayjs(createdAt).format('MMMM YYYY')}
                                        </Box>
                                </Typography>   
                            </CardContent>
                            </Card>
                            {handle===myHandle && (
                                <Fragment>
                            <div style={{position:'absolute', top:'140px', right:'16px', color: 'red', zIndex:'10'}}>
                                    {!profileError ? (
                                        <Fragment>
                                        {!loadingProfileImage ?(
                                            <Button color="white" basic inverted style={{borderRadius: '30px', padding: '7px 12px'}} onClick={this.handleEditPicture}>
                                            <Typography>
                                                <Box color="white" fontWeight={370} fontSize={14}>
                                                Change Image
                                                </Box>
                                            </Typography>
                                            </Button>
                                        ): (<CircularProgress size={20} style={{color:'white', marginTop:'5px', marginRight:'20px'}} color='white'/>)}
                                        
                                        </Fragment>
                                    ):(
                                        <Button color="red" basic inverted style={{borderRadius: '30px', padding: '7px 12px', zIndex:'11'}} onClick={this.handleEditPicture}>
                                        <Typography>
                                            <Box color="red" fontWeight={370} fontSize={14}>
                                            Error, Please Reupload Image
                                            </Box>
                                        </Typography>
                                        </Button>
                                    )}      
                            </div>
                            <div style={{postion:'absolute', top:'140px', left:'10px'}}>
                                <logoutUser/>
                            </div>
                            </Fragment>
                            )}
                        </Col>
                        <Col size={6}>
                        { handle===myHandle && (
                                <div style={{marginTop: '-90px', marginRight:'50px', float:'right', color: 'white'}}>
                                    {bannerError && (<span style={{color:'red'}}>Error, Please Reupload Image</span>)}
                                    
                                    <IconButton color="inherit" onClick={this.handleEditBannerPicture} style={{outline:'none'}}>
                                        <CameraIcon color="white" fontSize="Huge"></CameraIcon>
                                    </IconButton>
                                    <EditDetails/>
                                </div>
                        )}

                            <GridM container justify="space-between">
                                
                                <Typography component="div" style={{marginTop: '-100px', marginLeft:'40px', float:'left'}}>
                                    <Box fontWeight={520} fontSize={31} color="white">
                                        {name}
                                        <br/>
                                    </Box>
                                </Typography>
                                <Typography component="div" variant="body1" color="textSecondary" style={{ width:'100%', marginTop: '-53px', marginLeft:'40px', float:'left'}}>
                                    <Box color="white" fontWeight={370} fontSize={16}>
                                        {country && (<LocationOn color="white" style={{marginBottom:'7px', marginRight:'5px'}}/>)}
                                        {country}

                                        {degree && (<Work color="white" style={{marginBottom:'7px', marginRight:'5px', marginLeft: '10px'}}/>)}
                                        {degree}
                                    </Box>
                                </Typography>

                        

                                <Card className={classes.card2}>
                                    <CardContent className={classes.cardContent}>
                                        {tabSection}
                                    </CardContent>
                                </Card>
                                <div style={{paddingLeft:'20px', marginTop:'-10px'}}>
                                    {tabContents}
                                </div>

                                </GridM>
                        </Col>
                        <Col size={1} collapse="md"/>
                    </Row>
                </Grid>
                    
                

            </GridM>
        )

        let mobile=(
            <Fragment>
                
                <GridM container>
                        <Card style={{marginTop:'-80px', width: '100%', zIndex:'-9'}}>
                        <CardMedia component="img" src={bannerUrl} title="Banner image" className="background-image"
                            onError={e=>{
                                e.target.src = 'https://firebasestorage.googleapis.com/v0/b/buddymagnet.appspot.com/o/topicBannerImage.jpg?alt=media'
                                this.setState({bannerError:true})
                                console.log('banner error mobile')
                            }}/>
                        </Card>
                        
                        <GridM item xs={12}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor:'white',
                            position:'relative'
                        }}>
                            <div className={classes.centerImage}>
                                <img src={imageUrl} title="Profile image" className={classes.image} 
                                onError={e=>{
                                e.target.src = noImg
                                this.setState({profileError:true})
                                console.log('profile error')
                            }}
                            />     
                            </div> 
                        </div>
                        </GridM>
                        <Card className={classes.card3}>
                            <CardContent style={{position:'relative', width:'100%'}}>
                                <div style={{textAlign:'center'}}>
                                <Typography style={{marginTop:'-10px'}}>
                                    <Box fontWeight={600} fontSize={24}>
                                        {name ? (name):(handle)}
                                    </Box>
                                </Typography>   
                                <div style={{width:'100%', paddingLeft:'30px', paddingRight:'30px', marginTop:'10px'}}>
                                    <Typography color="textSecondary" variant="body2">
                                        <Box fontWeight={500} fontSize={14}>
                                        {bio ? (bio):''}
                                        </Box>
                                    </Typography>
                                </div> 
                                <hr/>
                                <div style={{width:'100%'}}>
                                <Typography>
                                        <Box fontWeight={400} fontSize={13}>
                                            {country && (<LocationOn fontSize="small" style={{marginBottom:'7px', marginRight:'7px', marginLeft:'7px'}}/>)}
                                                {country}

                                                

                                                {degree && (<Work fontSize="small" style={{marginBottom:'7px', marginRight:'14px', marginLeft:'14px'}}/>)}
                                                {degree}

                                                <EventNote fontSize="small" style={{marginBottom:'7px', marginRight:'7px', marginLeft:'14px'}}/>
                                                Joined {dayjs(createdAt).format('MMM YYYY')}
                                        </Box>
                                    </Typography>
                                </div>
                                </div>
                                <hr/>
                                <div style={{display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    }}>
                                    {tabSection}
                                </div>
                            </CardContent>
                        </Card>
                        
                        
                        <GridM item xs={12} align="center" style={{marginTop:'10px'}}>

                            <div style={{display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            }}>
                                {tabContents}   
                            </div>
        
                        </GridM>
                        
                        <span className={classes.editDetailsButtonMobile}>
                            { authenticated && handle===myHandle && (
                                    <div style={{color: 'white', width:'120px'}}>
                                        {bannerError && (<span style={{color:'red'}}>Error, Please Reupload Image</span>)}
                                        {!profileError ? (
                                            <Fragment>
                                                {!loadingProfileImage ? (
                                                    <Button color="white" basic inverted style={{borderRadius: '30px', marginBottom:'7px', padding: '7px 12px'}} onClick={this.handleEditPicture}>
                                                        <Typography>
                                                            
                                                            <Box color="white" fontWeight={370} fontSize={14}>
                                                            Change Image
                                                            </Box>
                                                        </Typography>   
                                                    </Button>
                                                ):(
                                                    <CircularProgress size={10} color='white'/>
                                                )}
                                            </Fragment>
                                        
                                        ):(
                                            <Button color="red" basic inverted style={{borderRadius: '30px',marginBottom:'7px', padding: '7px 12px', zIndex:'11'}} onClick={this.handleEditPicture}>
                                            <Typography>
                                                <Box color="red" fontWeight={370} fontSize={14}>
                                                Error, Please Reupload Image
                                                </Box>
                                            </Typography>
                                            </Button>
                                        )}   
                                        <div style={{float:'right'}}>
                                        <EditDetails/>
                                        </div>
                                        <IconButton color="inherit" onClick={this.handleEditBannerPicture} style={{float:'right', outline:'none'}}>
                                            <CameraIcon color="white" fontSize="Huge"></CameraIcon>
                                        </IconButton>
                                        
                                    </div>
                            )}
                        </span>   
                </GridM>

            </Fragment>
        )

        return(

            

            <Fragment>
                <div className="d-block d-lg-none" color="primary">
                    <div className="d-flex flex-row justify-content-around w-100">
                        {mobile}
                    </div>
                </div>
                    <div className="d-none d-lg-block">
                        {notMobile}
                    </div>
            </Fragment>
            

            )
    }
    
}
const mapStateToProps = (state) =>({
    user: state.user,
    data: state.data,
    loading: state.data.loading,
})


StaticProfile.propTypes = {
    data: PropTypes.object.isRequired,
    getMoreUserPosts: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    uploadUserImage: PropTypes.func.isRequired,
    uploadBannerImage: PropTypes.func.isRequired,
    postMarkups: PropTypes.func.isRequired,
    topicRequestMarkups: PropTypes.func.isRequired
}

export default connect(mapStateToProps,{uploadUserImage, uploadBannerImage, getMoreUserPosts})(withStyles(styles)(StaticProfile))