import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import {connect} from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {Link} from 'react-router-dom';
import CreateTopic from '../components/topic/CreateTopic'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Row, Col } from '../components/layout/Flexbox'
import SearchBar from '../components/layout/SearchBar'

const styles = (theme) => ({
    ...theme.spreadThis,
    widePaper:{
        marginTop: '300px',
        marginBottom: '35px',
        borderRadius: 1
    },
    paper:{
        paddingTop: '30px',
        paddingBottom:'100px',
        paddingLeft: '9%',
        paddingRight: '7%',
        marginTop:'-15px',
        minHeight:'800px',
        borderRadius: 24,
        backgroundColor:'#040d21',
        overflow:'hidden',
        position:'relative',
        boxShadow: '-1px 2px 20px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }
});

class TableOfContents extends Component {
    countTabs = (word) =>{
        for (var i = 0; i < word.length; i++) {
            if (word.charAt(i)!=='\t')
                return i;
          }
        return 0
    }

    getFontSize = (word) =>{
        let t = this.countTabs(word)
        switch(t){
            case 0:
                return 32;
                break;
            case 1:
                return 20
                break;
            case 2:
                return 18
                break;
            case 3:
                return 16
                break;
            case 4:
                return 15
                break;
            default:
                return 14
                break;
        }
        return 14
        
    }

    getFontWeight = (word) =>{
        let t = this.countTabs(word)
        switch(t){
            case 0:
                return 600;
                break;
            case 1:
                return 500
                break;
            default:
                return 400
                break;
        }
        return 14
        
    }

    render() {

        const {directory, loading_topics} = this.props.data;
        const {classes} = this.props;
        let tableOfContentsMarkups = !loading_topics ? (
            
            directory.map(entry=>
                <div>
                {
                    entry.includes('/') ? (
                        <div style={{marginLeft: this.countTabs(entry)*2+'%', marginBottom: '12px', marginTop: '12px', paddingLeft:'8px'}}>
                    <Typography>
                        <Box color="white" fontSize={this.getFontSize(entry)} fontWeight={this.getFontWeight(entry)}>
                            {entry.replace('/', '')}
                        </Box>
                    </Typography> 
                    </div>
                ):(
                    <div style={{marginLeft: this.countTabs(entry)*2+'%', paddingLeft:'8px', borderLeft:'2px solid rgb(171 171 171 / 24%)'}}>
                    <Typography  component={Link} to={'/topics/'+entry.trim()} >
                        <Box color="#7ac6ff" fontSize={this.getFontSize(entry)} fontWeight={this.getFontWeight(entry)}>
                            {entry}
                        </Box>
                    </Typography> 
                    </div>
                ) }</div>
                
            )
                        
        ) : (
            <CircularProgress size={30} color="primary"/>
        )
        
        return (
            <div style={{padding: '0px'}}>
                <Paper className={classes.paper}>
                    {/* <h3 color="body" style={{textAlign: 'left', marginBottom: '30px'}}>WIKI Q CONTENTS</h3> */}
                    <div style={{marginTop: '0px', marginRight:'10px', float:'right', color: 'black'}}>
                    <Row>
                        <Col collapse="ld" style={{width:'42px', height:'100%', background:'linear-gradient(rgb(100 158 230 / 48%), rgb(100 158 230 / 9%), rgb(124 189 255 / 0%))', position:'absolute', left:'0%', top:'-3%', transform:'skewX(0deg)'}}/>
                    </Row>
                    
                    </div>

                    <Row style={{maxWidth:'400px', padding:'5px', paddingLeft:'0px'}}>
                        <Col size={1}>
                            <SearchBar type="Topics"/>
                        </Col>
                        <Col size={.4} style={{marginTop:'12px', marginLeft: '20px'}}>
                            <CreateTopic/>
                        </Col>
                    
                    </Row>

                    {tableOfContentsMarkups}

                    <div style={{width:'100%', height:'60px', marginBottom:'7px', position:'absolute', bottom:'0%'}}>
                    <a rel="license"  href="http://creativecommons.org/licenses/by-nd/4.0/"></a><br /><Typography><Box fontWeight={300} fontSize={13} color='white'><img width="20px" src='https://mirrors.creativecommons.org/presskit/icons/cc.png'/>
                    <img width="20px" style={{marginLeft:'4px', marginRight:'4px'}} src='https://mirrors.creativecommons.org/presskit/icons/by.png'/>
                    <img width="20px" style={{marginRight:'8px'}} src='https://mirrors.creativecommons.org/presskit/icons/nd.png'/> 
                    This work is licensed under a <a rel="license" style={{color:'white'}} href="http://creativecommons.org/licenses/by-nd/4.0/">Creative Commons Attribution-NoDerivatives 4.0 International License.</a></Box></Typography>
                    
                    </div>
                </Paper>
                <div style={{height:'40px'}}/>
                
            </div>
            
            
        )
    }
}

TableOfContents.propTypes = {
    data: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) =>({
    data: state.data
})

export default connect(mapStateToProps, null)(withStyles(styles)(TableOfContents))
