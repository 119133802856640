import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Sticky,
  Accordion,
  AccordionPanel
} from 'semantic-ui-react'
import {toggleSidebar} from '../../redux/actions/userActions'
import MyFooter from './MyFooter'
import { Typography } from '@material-ui/core'

//The background color is in here
class MySidebar extends Component {
  state={activeIndex: true}

  handleClick = () => {
    let newVal = !this.state.activeIndex
    this.setState({ activeIndex: newVal })
  }

  render(){
  const {expanded} = this.props.UI;
  const {activeIndex} = this.state

  return (

    <Fragment>
      <div className="d-none d-lg-block">
        <Grid columns={1} style={{background:"transparent"}}>
        <Grid.Column >
            <Sidebar
              as={Menu}
              animation='overlay'
              icon='labeled'
              inverted
              vertical
              borderless
              fixed
              visible={true}
            >
              { expanded ? (
                <Fragment>
                  <Menu.Item as='a'>
                    <Icon name='home' color="white" />
                    Home
                  </Menu.Item>
                  <Menu.Item as='a' to='/ty'>
                    <Icon name='home' color="white" />
                    Home
                  </Menu.Item>
                  <Menu.Item as='a'>
                    <Icon name='fire' />
                    Popular
                  </Menu.Item>
                  <Menu.Item as='a'>
                    <Icon name='random' />
                    Random
                  </Menu.Item>
                  <Menu.Item as='a' onClick={this.props.toggleSidebar}>
                    <Icon name="question circle"/>
                    My Topics
                  </Menu.Item>
                </Fragment>
                ):(
                  <Fragment>
                    <Menu.Item as='a'>
                    <Icon name='home' color="white" > Home</Icon>
                    Home
                  </Menu.Item>
                  <Menu.Item as='a' to='/ty'>
                    <Icon name='home' color="white" />
                    Home
                  </Menu.Item>
                  <Menu.Item as='a'>
                    <Icon name='fire' />
                    Popular
                  </Menu.Item>
                  <Menu.Item as='a'>
                    <Icon name='random' />
                    Random
                  </Menu.Item>
                  <Menu.Item>
                    <Accordion inverted>
                      <Accordion.Title
                      active={activeIndex}
                      index={0}
                      onClick={this.handleClick}>
                        <Icon name='question' />
                      <Icon name='dropdown' />
                          My Topics _______        
                      </Accordion.Title>
                      
                      <Accordion.Content active={activeIndex} style={{width:'100%',
  margin: '0 auto', 
  transform: 'skewY(0deg)',
  position: 'absolute'}}>
                        <div>Smithsonian</div>
                        <div>Smithsonian</div>
                        <div>Smithsonian</div>
                        <div>Smithsonian</div>
                      </Accordion.Content>
                    </Accordion>
                    
                  </Menu.Item>
                  </Fragment>
                )}
              
            </Sidebar>
        </Grid.Column>
      </Grid>
    </div>
      <MyFooter/>
      </Fragment>)
  }
}

MySidebar.propTypes = {
  UI: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired
}

const mapStateToProps = state =>({
  UI: state.UI,
})
export default connect(mapStateToProps, {toggleSidebar})(MySidebar)