import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import MyIconButton from '../../util/IconButton'
import {Link} from 'react-router-dom'

//Redux stuff
import {connect} from 'react-redux'
import {likePost, unlikePost} from '../../redux/actions/dataActions'

import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import IconButton from '@material-ui/core/IconButton'
import ReplyIcon from '@material-ui/icons/MobileScreenShareOutlined';

export class LikeButton extends Component {
    likedPost = () =>{
        if(this.props.user.likes!=null && this.props.user.likes.find(like => like.postId === this.props.postId))
        {
            return true;   
        }
        return false;
    };
    
    state ={
        liked: this.likedPost()
    }

    componentDidMount(){
        this.setState({liked: this.likedPost()})
    }
    

    likePost = () =>{
        this.setState({liked: true})
        this.props.likePost(this.props.postId)
    }
    unlikePost = () =>{
        this.setState({liked: false})
        this.props.unlikePost(this.props.postId)
    }
    render() {
        const{authenticated} = this.props.user;
        const likeButton = !authenticated ? (
            // <Link to="/login">
            //     <IconButton tip="Like" style={{marginTop: '-4px', outline:'none'}}>    
            //         <FavoriteBorder color="#a3a3a3"/>
            //     </IconButton>
            // </Link>
            null
            
        ) : (
            this.state.liked ? (
                <IconButton onClick={this.unlikePost} style={{marginTop: '-4px', outline:'none'}}>
                    <FavoriteIcon color="primary"/>
                </IconButton>
            ):(
                <IconButton onClick={this.likePost} style={{marginTop: '-4px', outline:'none'}}>
                    <FavoriteBorder color="#a3a3a3"/>
                </IconButton>
            )
        )

        return(
            <Fragment>
                {likeButton}
            </Fragment>
            

        )
        
    }
}

LikeButton.propTypes = {
    user: PropTypes.object.isRequired,
    postId: PropTypes.string.isRequired,
    likePost: PropTypes.func.isRequired,
    unlikePost: PropTypes.func.isRequired
}

const mapStateToProps = (state) =>({
    user: state.user
})
const mapActionsToProps = {
    likePost,
    unlikePost
}
export default connect(mapStateToProps, mapActionsToProps)(LikeButton)
