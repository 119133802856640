import React, { Component, Fragment, Dimensions } from 'react'
import PropTypes from 'prop-types'
import GridM from '@material-ui/core/Grid';
import Post from '../components/post/Post';
import PostSkeleton from '../util/PostSkeleton'
import {connect } from 'react-redux';

import {getTopicData, resetPosts, getMoreTopicPosts} from '../redux/actions/dataActions'
import {uploadTopicBannerImage, uploadTopicImage} from '../redux/actions/userActions'

import Box from '@material-ui/core/Box'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import withStyles from '@material-ui/core/styles/withStyles';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Typography from '@material-ui/core/Typography'
import {Grid, Row, Col} from '../components/layout/Flexbox'
import {Button, Icon} from 'semantic-ui-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import ScrollHandler from '../util/ScrollHandler'

import MenuItem from '@material-ui/core/MenuItem'
import EditTopic from '../components/topic/EditTopic'

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ImageIcon from '@material-ui/icons/Image';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CreatePost from '../components/post/CreatePost'
import WrapperButton from '../components/post/WrapperButton';
import QuestionsGraph from '../components/topic/QuestionsGraph'
import '../util/shimmer.css'

const styles={
    card: {
        position: 'relative',
        display: 'flex',
        marginBottom: 20,
        backgroundColor:'#f7f7f7',
        boxShadow: '0px 2px 20px 3px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    contentLeft:{
        padding:25,
        objectFit:'cover'
    },
    contentRight:{
        padding: 20,
        objectFit: 'cover',
        alignSelf: 'right',
        display: 'flex',
        justifyContent: 'right'
    },
    image:{
        width:120,
        height:120,
        margin: '5px 0 0 5px',
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
    },
    image2:{
        width:70,
        height:70,
        margin: '5px 0 0 5px',
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
    },
    bannerImage:{
        height: '230px',
        width: '100%'
    },
    imageWrap:{
        marginLeft:'20%',
    },
    imageWrapper:{
        width:130,
        height:130,
        marginTop:-50,
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
        position:'relative',
        backgroundColor: "white",
        float: 'left',
        marginLeft: '3vw'
    },
    imageWrapper2:{
        width:80,
        height:80,
        marginTop:-60,
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
        position:'relative',
        backgroundColor: "white",
        float: 'left',
        marginLeft: '1vw'
    },
    centerImage:{
        width:300,
        height:300,
        marginTop:-150,
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
        backgroundColor: "white"
    },
    rightGrid:{
        width:'100%',
    },
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

class topic extends Component {
    state={
        value:0,
        all_seen: false,
        prev_posts: -1
    }

    componentWillUnmount =()=>{
        this.props.resetPosts();
    }

    handleImageChange = (event) =>{
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);
        const config = {
            headers:{
                'content-type': 'multipart/form-data; boundary=<calculated when request is sent>'
            }
        }
        this.props.uploadTopicImage(formData, this.props.match.params.topicName)
    }
    handleBannerImageChange = (event) =>{
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);
        const config = {
            headers:{
                'content-type': 'multipart/form-data; boundary=<calculated when request is sent>'
            }
        }
        this.props.uploadTopicBannerImage(formData, this.props.match.params.topicName)
    }
    handleEditPicture = () =>{
        const fileInput = document.getElementById('imageInput')
        fileInput.click();
    }
    handleEditBannerPicture = () =>{
        const fileInput = document.getElementById('imageInput2')
        fileInput.click();
    }

    handleReset = (val) =>{
        this.props.resetPosts()
        this.setState({all_seen: false, prev_posts: -1})
    }

    changeData(val){ //this is being called every time you switch tabs
        let state = {prev_posts: -1, all_seen:false}

        if(val==0) this.handleScrollAction(state, false);
        if(val==1) this.handleScrollAction(state, true);
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue})
        this.handleReset(newValue)
        this.changeData(newValue);
    };

    handleScrollAction = (state, type) => {
        console.log('executing with ')
        console.log(state)
        if(state.prev_posts === this.props.data.posts.length){
            this.setState({all_seen: true})
            return;
        }

        this.setState({prev_posts: this.props.data.posts.length})
        let arg = "0"
        if(this.props.data.posts && this.props.data.posts.length > 0)
        {
            arg = this.props.data.posts[this.props.data.posts.length-1].postId
        }
        console.log('arg '+arg)
        console.log(this.props.match.params.topicName)
        this.props.getMoreTopicPosts({id: arg}, this.props.match.params.topicName, type) //second argument is rising boolean
    }

    handleCreate = ()=>{
        document.getElementById('please').click();
    }

    componentDidMount(){
        const topicName = this.props.match.params.topicName
        console.log(topicName)
        this.props.resetPosts();
        this.props.getTopicData(topicName);
        console.log('component mounted!!!!')
    }

    render() {
        const {posts, loading, topic:{createdAt, creator, topicName, imageUrl, bannerImage, admins, dataPoints}} = this.props.data;
        const {user: { authenticated, credentials:{handle, role}}} = this.props;
        const {value} = this.state

        const classes = styles;

        let isAdmin = false;
        if(authenticated && admins!==undefined && admins!==null)
        {
            if(admins.includes(handle)) isAdmin=true;
        }

        let recentScreamsMarkup = loading && posts===null ? (
            <PostSkeleton/>
        ) : (
            posts===[] ? 
                <span>No posts yet</span>
            :
            
            <Row>
                <Col size={1} collapse="sd">
                    {posts.map(post => <Post key={post.postId} post={post}/>)}
                </Col>
                <Col size={1} collapse="dd">
                    {posts.map(post => <Post key={post.postId} post={post} width="narrow"/>)}
                </Col>
            </Row>    
            );

        let tabSection = (
            <Fragment>
                <Tabs
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab style={{minWidth:'90px', outline:'none'}} label={<Typography><Box fontSize={12} fontWeight={700}>All Questions</Box></Typography>} {...a11yProps(0)} />
                    <Tab style={{minWidth:'70px', outline: 'none'}} label={<Typography><Box fontSize={12} fontWeight={700}>Popular</Box></Typography>} {...a11yProps(1)} />
                    <Tab style={{minWidth:'70px', outline:'none'}} label={<Typography><Box fontSize={12} fontWeight={700}>About</Box></Typography>} {...a11yProps(2)} />
                </Tabs>
            </Fragment>
            
        )

        

        let tabContents = (
            <Fragment>
                <TabPanel value={value} index={0}>
                    <ScrollHandler onBottom={()=>{this.handleScrollAction(this.state, false)}}/>
                    {recentScreamsMarkup}
                    <div style={{height:'200px', textAlign: 'center', marginTop: '70px'}}>
                        {loading && (<CircularProgress size={30} color="primary"/>)}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ScrollHandler onBottom={()=>{this.handleScrollAction(this.state, true)}}/>
                    {recentScreamsMarkup}
                    <div style={{height:'200px', textAlign: 'center', marginTop: '70px'}}>
                        {loading && (<CircularProgress size={30} color="primary"/>)}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div>
                    <QuestionsGraph dataPoints={dataPoints}/>
                    </div>
                    
                    

                </TabPanel>
            </Fragment>
        )

        let changeBanner = (
            <MenuItem onClick={this.handleEditBannerPicture}>
                <ImageIcon style={{marginRight:'10px'}}/>
                <Typography>
                    <Box fontWeight={500} fontSize={14}>
                        Change Banner
                    </Box>
                </Typography>
            </MenuItem>
        )

        let changeImage = (
            <MenuItem onClick={this.handleEditPicture}>
                <CameraAltIcon style={{marginRight:'10px'}}/>
                <Typography>
                    <Box fontWeight={500} fontSize={14}>
                        Change Image
                    </Box>
                </Typography>
            </MenuItem>
        )

        return (
            <GridM container style={{marginTop: '-84px'}}>

                

                <GridM item xs={12} style={{marginBottom:'16px', position:'relative'}}>
                    <Card className={classes.card} style={{boxShadow:'0px 2px 20px 3px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'}}>
                        {!loading ? (<CardMedia component="img" image={bannerImage} title="Banner image" style={classes.bannerImage}/>):(
                            <CardMedia component="div" style={classes.bannerImage}>
                                <div class="wrapper">
                                    <div class="animate" style={{width: '100%', zIndex:'-9', backgroundColor:'grey', height: '230px'}}/>
                                </div>
                            </CardMedia>
                        )}
                    

                    <input
                        type="file"
                        id="imageInput"
                        hidden="hidden"
                        onChange={this.handleImageChange}
                    />
                    <input
                        type="file"
                        id="imageInput2"
                        hidden="hidden"
                        onChange={this.handleBannerImageChange}
                    />

                    {(isAdmin || role==='creator') && (
                        
                        <div style={{position:'absolute', top: '140px', right: '5%', float:'right', color: 'white'}}>
                            <EditTopic changeImage={changeImage} changeBanner={changeBanner} topicName={topicName}/>
                        </div>
                    )}

                    
                    <CardContent className={styles.contentLeft} justify="space-between">
                        <Grid>
                            <div className="container-noup">
                            <Row>
                                <Col size={.1} collapse="ld">
                                    <div style={{position: 'relative'}}>
                                        <div style={classes.imageWrapper}>
                                            <img src={imageUrl} style={styles.image}></img>
                                        </div>
                                    </div>        
                                </Col>
                                <Col size={4} style={{marginLeft: '20px'}}>
                                    <Typography variant="body" style={{marginTop:'10px'}}>
                                        <span className="h1 font-weight-bold mb-0" color="primary" style={{marginTop:'5px'}}>
                                        {topicName}
                                        </span>   
                                    </Typography>                      
                                </Col>
                                <Col size={.1} collapse="ls">
                                    <div style={{position: 'relative'}}>
                                        <div style={classes.imageWrapper2}>
                                            <img src={imageUrl} style={styles.image2}></img>
                                        </div>
                                    </div>        
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {tabSection}
                                </Col>
                                <Col style={{marginLeft:'30px'}} collapse="ld">
                                    <CreatePost topic={topicName} key={topicName}></CreatePost>
                                </Col>
                                <Col style={{marginLeft:'30px'}} collapse="ls">
                                    <CreatePost topic={topicName} key={topicName} mobile={true}></CreatePost>
                                </Col>
                            </Row>  
                            </div>
                            
                        </Grid>
                        
                        
                    </CardContent>

                    </Card>
                </GridM>

                <Grid container spacing={2} justify="center">
                <Grid item sm={8} xs={12}>
                <div className="container-noup">
                    <Row>
                        <Col size={2}>
                            {tabContents}   
                               
                        </Col>
                    </Row>
                    </div> 
                    
                </Grid>
                </Grid>
            </GridM>
        )
    }
}

topic.propTypes={
    getTopicData: PropTypes.func.isRequired,
    getMoreTopicPosts: PropTypes.func.isRequired,
    uploadTopicImage: PropTypes.func.isRequired,
    uploadTopicBannerImage: PropTypes.func.isRequired,
    resetPosts: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) =>({
    data: state.data,
    user: state.user
})

export default connect (mapStateToProps, {getTopicData, resetPosts, getMoreTopicPosts, uploadTopicBannerImage, uploadTopicImage})(withStyles(styles)(topic))
