import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types'
import DeletePost from './DeletePost'
import PostDialog from './PostDialog'

//MUI Stuff
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Image from 'react-bootstrap/Image'
import Grid from '@material-ui/core/Grid';
import {Button} from 'semantic-ui-react'
import IconButton from '@material-ui/core/IconButton'
import Favorite from '@material-ui/icons/Favorite'
import Box from '@material-ui/core/Box'
//Icons
import ChatIcon from '@material-ui/icons/Chat'
import {connect} from 'react-redux'
import noImg from '../../images/no-img.png'
import Text from '../layout/Text'
const styles={
    card: {
        width: '320px',
        height: '390px',
        position: 'relative',
        display: 'flex',
        boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 9%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        display: 'inline-block',
        margin: 14,
        borderRadius: 7,
        textAlign: 'left',
        "&:hover": {
            boxShadow: '0px 0px 12px 0px rgb(0 0 0 / 22%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
            
          }
          
    },   
    wideCard:{
        width: '100%',
        height: '390px',
        position: 'relative',
        display: 'flex',
        boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 9%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        display: 'inline-block',
        marginBottom:8,
        borderRadius: 7,
        textAlign: 'left',
        "&:hover": {
            boxShadow: '0px 0px 12px 0px rgb(0 0 0 / 22%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
            
          }
    },
    image:{
        width: 38,
        height:38,
        borderRadius: '50%',
        objectFit: 'cover'
    },
    content:{
        padding: 0,
        paddingBottom: 3,
        width: '100%',
        height: '100%',
        objectFit:'cover',
        fontFamily: 'BlinkMacSystemFont',
        display: 'inline-block',
        "&:last-child": {
            paddingBottom: 0
          }
    },
    content2:{
        padding:0,
        width: '100%',
        objectFit:'cover',
        fontFamily: 'BlinkMacSystemFont',
    },
    tagButton:{
        margin: 4,
        float: 'right',
        marginTop: 12,
        //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        height: '30px',
        padding: '4 8px 4 8px',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            backgroundColor: "blue !important",
            transform: 'translate(0,-3px)'
          }
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    bodyContainer:{
        marginTop: '0px',
        height: '86%'
    },
    tagContainer:{
        width: '100%',
        float: 'right',
        padding:1,
        marginTop: -57
    },
    date:{
        fontSize: 'small'
    },
    circleButton:{
        margin: 4,
        width:30,
        height:30,
        marginLeft: 9,
        float: 'right',
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 400,
        border: 0,
        padding: 12,
        boxShadow: '-1px 2px 20px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            backgroundColor: "blue !important",
            transform: 'translate(0,-3px)'
          }

    },
    circleButton2:{
        margin: 4,
        width:30,
        height:30,
        marginLeft: 9,
        float: 'right',
        background: 'linear-gradient(45deg, #93dcd2ff 30%, #B7F8DB 90%)',
        borderRadius: 400,
        border: 0,
        padding: 12,
        boxShadow: '-1px 2px 20px 2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        "&:hover": {
            backgroundColor: "blue !important",
            transform: 'translate(0,-3px)'
          }
    },
    bottomHeader:{
        position: 'absolute',
        top: '86%',
        width: '100%',
        height: '100%',
        backgroundColor: 'white'
    },
    topimage:{
        height: '86%',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0%)'
    },
    stats:{
        position: 'absolute',
        right: '5%',
        top: '81%',
        zIndex: '30'
    },
    gradientOverlay:{
        height: '40%',
        width: '100%',
        top: '46%',
        display: 'flex',
        position: 'absolute',
        background: 'linear-gradient(rgb(16 31 58 / 0%),#000000d1)',
        backgroundColor: 'transparent',
        zIndex:2,
        padding: 17,
        paddingBottom: 8
    },
    noImageDiv:{
        zIndex:2,
        padding: 21,
        paddingBottom: 8,
    },
    tagHolderImage:{
        position:'absolute',
        float: 'right',
        top: '5%',
        right: '5%'
    },
    tagHolderRegular:{
        position:'absolute',
        float: 'left',
        top: '70%',
        left: '5%'
    }
    
}
const MAX_LENGTH = 300
const checkIsYoutube = (url) =>{
    return url.includes("youtube.com")
}

const getYoutubeId = (url) =>{
    return url.split('?v=')[1].split('&')[0]
}

class Post extends Component {
    state={
        open: this.props.openDialog ? true : false,
        color: ''
    }
    componentDidMount()
    {
        console.log('state is '+this.state.open)
    }

    openFunction = () =>{
        this.setState({open: true})
        console.log('opened')
    }
    closeFunction = () => {
        this.setState({open: false})
    }
    randomColor=()=>{
        let colors = ['#FFC312','#C4E538','#12CBC4','#FDA7DF', '#ED4C67','#EE5A24', '#009432', '#0652DD', '#9980FA', '#833471', '#EA2027', '#006266']
        return colors[Math.floor(Math.random() * colors.length)]
    }
    getDominantColor=(color)=>{
        return 'linear-gradient(rgb(16 31 58 / 0%),'+color+')'    
    }
    getThumbnailImage = (url) =>{
        if(url.indexOf('/userImages/')===-1)
        {
            return url;
        }
        let t = url.split('/userImages/')
        // console.log('the regular image is '+url)
        // console.log('the thumbnail image is '+t[0] + '/userImages/'+ t[1].split('.')[0] + '-1.'+t[1].split('.')[1])
        return t[0] + '/userImages/'+ t[1].split('.')[0] + '-1.'+t[1].split('.')[1]
    }
    
    render() {
        dayjs.extend(relativeTime);
        const {classes, post : { header, createdAt,userName, userImage, userHandle, postId, likeCount, attribution, commentCount, media, tags}, user: {authenticated, credentials:{handle}}} = this.props;
        //const color = 'linear-gradient(rgb(16 31 58 / 0%),'+this.randomColor()+')'

        let imageUrl = ''
        if(media)
        {
            if(checkIsYoutube(media))
            {
                imageUrl = 'http://img.youtube.com/vi/'+getYoutubeId(media)+'/0.jpg'
            }
            else{
                imageUrl = media
            }    
        }

        let shortenedBody = this.props.post.body
        let shortenedHeader = this.props.post.header
        let totalLength = 0
        if(shortenedHeader) {totalLength += shortenedHeader.length}
        if(shortenedBody) {totalLength += shortenedBody.length}

        if (totalLength > MAX_LENGTH) {
            let shortened = MAX_LENGTH < shortenedHeader.length
            shortenedHeader = shortenedHeader ? (shortenedHeader.substring(0, Math.min(MAX_LENGTH, shortenedHeader.length)) + (shortened ? '...':'')) : null
            shortenedBody = shortenedBody ? (shortenedBody.substring(0, MAX_LENGTH - Math.min(MAX_LENGTH, shortenedHeader.length))+'...') : null
        }

        const deleteButton = authenticated && userHandle === handle ? (
            <DeletePost postId={postId}/>
        ) : null
        const tagList = tags.map((tag)=>
            // <Button as={Link} to={"/topics/"+tag} style={{borderRadius: '200px', marginTop:'6px'}}>
            //     {tag.length > 30 ? (tag.substring(0,28)+'...'):(tag)}
            // </Button>
            <div/>
        )

        const topHeader = (wordColor) =>{
            return(
            <Fragment>
                <Grid container wrap="nowrap" spacing={2} style={{padding: '3px 16px'}}>
                    <Grid item>
                        <img src={this.getThumbnailImage(userImage)} title="Profile image" className={classes.image} onError={e=>{e.target.src = noImg}}/>
                    </Grid>
                    <Grid item xs={12} style={{width: '100%', paddingTop: '16px'}}>
                        <Typography to={'/users/'+userHandle}>
                            <Box fontSize="15" fontWeight="600" color={wordColor}>
                            {userName}
                            </Box>
                        </Typography>
                    </Grid>
                    
                </Grid>
                
            </Fragment>)
        }

        const fullCard = (
                <CardContent className={classes.content}>

                    <div className={classes.bodyContainer} onClick={this.openFunction}>
                        {media ? (
                            checkIsYoutube(media) ? (
                                <div className={classes.topimage} style={{height: '120%', transform: 'translate(-50%, -13%)'}}>
                                <Image src={imageUrl} height='100%'/>
                                </div>
                            ):(
                                <div className={classes.topimage}>
                                <Image src={imageUrl} height='100%'/>
                                </div>
                            )     
                        ): null}
                        
                        {media ? (
                            
                                <Fragment>
                                    <div className={classes.gradientOverlay} style={{background: this.getDominantColor('#242424')}}>
                                        <Typography style={{alignSelf: 'flex-end', width: '100%'}}>
                                            <Box fontSize={20} fontWeight={510} color="white">
                                                {shortenedHeader}
                                            </Box>
                                            
                                            <Box fontSize={14} fontWeight={300} color="#ffffffa1">
                                                {  dayjs(createdAt).fromNow()}
                                            </Box>
                                        </Typography>
                                    </div>


                                    <div className={classes.tagHolderImage}>
                                        {tagList}
                                    </div>

                                    
                                </Fragment>

                            // If there is no picture
                            ):(
                                <Fragment>
                                    <div className={classes.noImageDiv}>
                                        <Typography>
                                            <Text fontSize={14} fontWeight={300} color="#636363" fontStyle='italic'>
                                                {(attribution && attribution!=='') && (<span>Attributed to: {attribution}</span>)}
                                                </Text>

                                            <Box fontSize={18} fontWeight={500} color="#242424">
                                                {shortenedHeader}
                                            </Box>
                                            
                                            <Box fontSize={14} fontWeight={300} color="#636363">
                                                    {  dayjs(createdAt).fromNow()}
                                            </Box>
                                            
                                            {this.props.post.body ? (
                                                <Box fontSize={14} fontWeight={400} color="#3b3b3b" style={{marginTop:'10px'}}>
                                                {shortenedBody}
                                                </Box>
                                            ):null}
                                        </Typography>
                                        
                                        
                                        
                                    </div>

                                    {tags.length>2 ? (
                                        <div className={classes.tagHolderRegular} style={{top:'62%'}}>
                                            {tagList}
                                        </div>

                                    ):(
                                        <div className={classes.tagHolderRegular}>
                                            {tagList}
                                        </div>

                                    )}
                                </Fragment>
                                
                            )}
                        
                        <hr style={{position:'absolute', top:'86%'}}/>
                        
                        <div className={classes.bottomHeader}>
                            <Link to={'/users/'+userHandle}>
                                <hr style={{width: '100%', margin: 0, marginBottom: 3}}/>
                                    {topHeader('#2a6af5')}
                                    
                            </Link>
                            </div>
                                    

                        <div className={classes.stats}>
                            {likeCount > 0 &&(
                                <IconButton className={classes.circleButton} style={{outline:'none'}} component="span">
                                <span className="h5 font-weight-bold mb-0" style={{fontFamily:'Arial', right: '-15%', position:'absolute', top: '70%', float:'left', color: '#282d61'}}>{likeCount}</span>
                                    <Favorite style={{color: 'white', width: 17, height: 17}} size="medium"/>
                                </IconButton>
                            )}
                            
                            {commentCount > 0 && (
                                <IconButton className={classes.circleButton2} style={{outline:'none'}} component="span">
                                <span className="h5 font-weight-bold mb-0" style={{fontFamily:'Arial', right: '-15%', position:'absolute', top: '70%', float:'left', color: '#282d61'}}>{commentCount}</span>
                                    <ChatIcon style={{color: 'white', width: 17, height: 17}} size="medium"/>
                                </IconButton>
                            )}
                        </div>
                                   
                        <PostDialog key={this.state.open} postId={postId} userHandle={userHandle} openDialog={this.state.open} closeFunction={this.closeFunction}/>
                    </div>
                    
                    
                </CardContent>
        )

        return (
            <Fragment>

                    {this.props.width ? (
                        <Card className={classes.wideCard}>
                            {fullCard}
                        </Card>
                    ):(
                        <Card className={classes.card} variant="outlined">
                            {fullCard}
                        </Card>
                    )}
                
            </Fragment>
            
            
        )
    }
}

Post.propTypes = {
    user: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    openDialog: PropTypes.bool
}
const mapStateToProps =(state)=>({
    user: state.user
})

export default connect(mapStateToProps)(withStyles(styles)(Post));
