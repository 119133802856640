import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import {Grid, Row, Col} from '../components/layout/Flexbox'
import GridM from '@material-ui/core/Grid';
import noImg from '../images/no-img.png'
import './shimmer.css'

const styles = theme =>({
    ...theme.spreadThis,
    handle:{
        height:20,
        backgroundColor: theme.palette.primary.main,
        width:60,
        margin:'0 auto 7px auto'
    },
    fullLine:{
        height: 20,
        backgroundColor:'rgba(0,0,0,.12)',
        width: '100%',
        marginBottom: 10
    },
    halfLine:{
        height: 20,
        backgroundColor:'rgba(0,0,0,.12)',
        width: '50%',
        marginBottom: 10
    },
    image:{
        width:220,
        height:220,
        margin: '5px 0 0 5px',
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
    },
    centerImage:{
        width:230,
        height:230,
        marginTop:-170,
        borderRadius: '50%',
        objectFit: 'cover',
        overflow: "hidden",
        backgroundColor: "rgb(255, 255, 255)"
    },
    card:{
        width: 340,
        marginTop:-200,
        marginLeft:'10px',
        float: 'right',
        borderRadius:'1px'
    },
    cardImage:{
        height: '390px',
        width: '100%',
        objectFit: 'cover',
        backgroundColor:'white'
    },
    card2:{
        width:'100%',
        margin:'20px',
        marginTop:'20px',
        height:'70px',
        padding:'10px',
        borderRadius:'1px',
        marginBottom: '5px',
        backgroundColor:'white',
        borderRadius:'5px'
    },
    card3:{
        width:'100%',
        backgroundColor:'white'
    },
})

const ProfileSkeleton = (props) =>{
    const { classes } = props

    let notMobile = (
        <GridM container>
            <div class="wrapper">
                <div class="animate" style={{marginTop:'-80px', width: '100%', zIndex:'-9', backgroundColor:'grey', height: 350}}/>
            </div>
            

            <Grid>
                <Row>
                    <Col size={3}>
                        <div className={classes.card}>
                        {/* <div className={classes.cardImage}/> */}
                        <img src={noImg} className={classes.cardImage}/>
                        
                        <div style={{backgroundColor:'white', padding:'10px', marginTop:'5px'}}>
                            
                            <div className={classes.halfLine} style={{backgroundColor:'rgba(0,0,0,.32)', height:'26px'}}/>
                            <div className={classes.fullLine}/>
                            <div className={classes.fullLine}/>
                            <div className={classes.halfLine}/>
                            <hr style={{marginBottom:'10px'}}/>
                            <div className={classes.fullLine}/>
                        </div>
                        </div>
             
                    </Col>
                    <Col size={6}>

                        <GridM container justify="space-between">
                            
                            <div style={{marginTop: '-100px', marginLeft:'40px', float:'left', width:'50%', height:'60px'}}>
                                <div style={{height:'40px', backgroundColor:'white', width:'70%'}}/>
                                <div style={{height:'30px', backgroundColor:'white', width:'70%', marginTop:'10px'}}/>

                            </div>

                            <div className={classes.card2}/>

                        </GridM>
                    </Col>
                    <Col size={1} collapse="md"/>
                </Row>
            </Grid>
                
            

        </GridM>
    )

    let mobile=(
        <Fragment>
            
            <GridM container>
            <div class="wrapper">
                <div class="animate" style={{marginTop:'-80px', width: '100%', zIndex:'-9', backgroundColor:'grey', height: 350}}/>
            </div>
                    
                    <GridM item xs={12}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor:'white',
                        position:'relative'
                    }}>
                        <div className={classes.centerImage}>
                            <img src={noImg} className={classes.image}/>     
                        </div> 
                    </div>
                    </GridM>
                    <div className={classes.card3}>
                        <div style={{position:'relative'}}>
                            <div style={{textAlign:'center'}}>

                            <div style={{marginTop:'8px',width:'100%', paddingLeft:'35%', paddingRight:'35%'}}>
                                <div className={classes.fullLine} style={{height:'28px', backgroundColor:'rgba(0,0,0,.32)'}}/>
                                
                            </div>

                            <div style={{width:'100%', paddingLeft:'30%', paddingRight:'30%', marginTop:'10px'}}>
                                <div className={classes.halfLine} style={{width:'100%'}}/>
                                <div className={classes.halfLine} style={{width:'80%'}}/>
                            </div> 
                            <hr/>
                            <div>
                            <div style={{width:'100%', paddingLeft:'10%', paddingRight:'10%', marginTop:'10px'}}>
                                <div className={classes.halfLine} style={{ width:'100%'}}/>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                     
            </GridM>

        </Fragment>
    )

    return (
        <Fragment>
                <div className="d-block d-lg-none" color="primary">
                    <div className="d-flex flex-row justify-content-around w-100">
                        {mobile}
                    </div>
                </div>
                    <div className="d-none d-lg-block">
                        {notMobile}
                    </div>
            </Fragment>
    )
}

ProfileSkeleton.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProfileSkeleton)