import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120px"
      version="1.1"
      viewBox="0 0 165 104"
    >
      <g fillOpacity="1" stroke="none">
        <path
          fill="#fff"
          strokeDasharray="0.44101, 0.44101"
          strokeWidth="0.882"
          d="M56.797 100.124c-.128-.346-1.862-7.273-4.111-16.414a8922.296 8922.296 0 00-5.199-21.02c-.871-3.5-1.065-4.031-1.588-4.34-13.08-8.055-27.498-15.083-40.646-23.692 52.846-9.125 107.125-20.568 155.34-29.967-18.836 30.161-35.588 61.81-51.8 89.729-8.936-4.846-18.326-10.157-27.28-15.556-.472-.44-1.224-.148-2.358.911-6.943 6.523-14.703 13.553-20.788 19.284-.98.93-1.492 1.279-1.57 1.065z"
        ></path>
        <path
          fill="#e7e4e8"
          strokeDasharray="0.220504, 0.220504"
          strokeWidth="0.044"
          d="M56.634 99.564c-.065-.163-.336-1.24-.603-2.392a213.56 213.56 0 00-1.076-4.369c-.325-1.251-2.25-8.965-4.276-17.141-2.027-8.177-3.816-15.294-3.976-15.816-.16-.521-.41-1.078-.557-1.236l-.267-.288 56.48-26.36C133.423 17.464 158.865 5.63 158.897 5.66c.033.033-20.208 14.431-44.978 31.999C89.15 55.226 68.766 69.72 68.622 69.868c-.196.201-8.101 20.17-11.776 29.744-.093.242-.098.24-.211-.05z"
        ></path>
        <path
          fill="#ccc"
          strokeDasharray="0.311842, 0.311842"
          strokeWidth="0.062"
          d="M56.694 99.932c-.049-.129-.033-.26.033-.288.073-.024.845-1.91 1.722-4.18.877-2.27 3.38-8.638 5.563-14.151l3.969-10.024.677.404c.372.222 3.221 1.868 6.33 3.658 3.11 1.79 5.655 3.306 5.656 3.368.001.065-.149.16-.336.22-.186.057-1.953 1.607-3.927 3.44a1935.396 1935.396 0 01-6.131 5.667c-1.399 1.285-4.91 4.538-7.803 7.23-2.893 2.691-5.352 4.893-5.463 4.894-.112 0-.244-.107-.294-.237z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
