import {SET_USER, SET_ERRORS, CLEAR_ERRORS, LOADING_UI, SET_UNAUTHENTICATED, LOADING_USER, MARK_NOTIFICATIONS_READ, TOGGLE_EXPANDED} from '../types';
import axios from 'axios';
import { getTopicData } from './dataActions';

export const signupUser = (userData, history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios.post('/signup', userData)
            .then(res=>{
                setAuthorizationHeader(res.data.token)
                dispatch(getUserData());
                dispatch({ type: CLEAR_ERRORS});
                history.push('/') //redirects to home page
            })
            .catch(err=>{
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
            })
}

export const signupUserGoogle = (userData, history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios.post('/signupGoogle', userData)
            .then(res=>{
                setAuthorizationHeader(res.data.token)
                dispatch(getUserData());
                dispatch({ type: CLEAR_ERRORS});
                history.push('/') //redirects to home page
            })
            .catch(err=>{
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
            })
}

export const logoutUser = ()=>(dispatch) =>{
    localStorage.removeItem('FBIdToken');
    delete axios.defaults.headers.common['Authorization'];
    dispatch({type: SET_UNAUTHENTICATED})
}

export const loginUser = (userData, history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios.post('/login', userData)
            .then(res=>{
                setAuthorizationHeader(res.data.token)
                dispatch(getUserData());
                dispatch({ type: CLEAR_ERRORS});
                history.push('/') //redirects to home page
            })
            .catch(err=>{
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
            })
}

export const loginGoogleUser = (userData, history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    axios.post('/loginGoogle', userData)
            .then(res=>{
                setAuthorizationHeader(res.data.token)
                dispatch(getUserData());
                dispatch({ type: CLEAR_ERRORS});
                history.push('/') //redirects to home page
            })
            .catch(err=>{
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
            })
}

export const uploadUserImage = (formData)=>(dispatch)=>{
    dispatch({type:LOADING_USER})
    console.log("dispatching")
    axios.post('/user/image', formData, {
        'content-type': 'multipart/form-data'
    })
        .then(res=>{
            console.log(res.data)
            dispatch(getUserData())
            window.location.reload()
        })
        .catch(err=> {console.log(err)
            window.location.reload();
        })
}

export const uploadBannerImage = (formData)=>(dispatch)=>{
    dispatch({type:LOADING_USER})
    axios.post('/user/bannerImage', formData, {
        'content-type': 'multipart/form-data'
    })
        .then(res=>{
            console.log(res.data)
            dispatch(getUserData())
            window.location.reload();
        })
        .catch(err=> {console.log(err)
            window.location.reload();
        })
}

export const uploadTopicImage = (formData, topicName)=>(dispatch)=>{
    dispatch({type:LOADING_UI})
    console.log("dispatching")
    axios.post('/topic/'+topicName+'/image', formData, {
        'content-type': 'multipart/form-data'
    })
        .then(res=>{
            console.log(res.data)
            dispatch(getTopicData())
            window.location.reload()
        })
        .catch(err=> {console.log(err)
            window.location.reload();
        })
}

export const uploadTopicBannerImage = (formData,topicName)=>(dispatch)=>{
    dispatch({type:LOADING_UI})
    axios.post('/topic/'+topicName+'/bannerImage', formData, {
        'content-type': 'multipart/form-data'
    })
        .then(res=>{
            console.log(res.data)
            dispatch(getTopicData())
            window.location.reload();
        })
        .catch(err=> {console.log(err)
            window.location.reload();
        })
}

//this gets the authenticated user details
export const getUserData = () => (dispatch) =>{
    dispatch({type: LOADING_USER});
    console.log("just got the user: ")
    
    axios.get('/user').then(res =>{
        console.log(res.data)
        dispatch({
            type: SET_USER,
            payload: res.data
        })
    })
    .catch(err=> console.log(err))
}

const setAuthorizationHeader = (token) =>{
    const FBIdToken = 'Bearer '+token
    axios.defaults.headers.common['Authorization'] = FBIdToken
    localStorage.setItem('FBIdToken', FBIdToken);
}

export const editUserDetails = (userDetails) =>(dispatch)=>{
    dispatch({type: LOADING_USER})
    console.log("sending the details: ")
    console.log(userDetails)
    axios.post('/user', userDetails)
        .then(()=>{
            dispatch(getUserData())
            window.location.reload()
        })
        .catch(err=>console.log(err))
}

export const markNotificationsRead = (notificationIds) => dispatch =>{
    axios.post('/notifications', notificationIds)
        .then(res=>{
            dispatch({
                type: MARK_NOTIFICATIONS_READ
            })
        })
        .catch(err=> console.log(err))
}

export const toggleSidebar = () => dispatch =>{
    dispatch({type: TOGGLE_EXPANDED})
}